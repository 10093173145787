import { useCallback, useEffect, useState } from 'react';
import { IntegrationSetupDisplayDto } from '@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Dto.gen';
import useApi from '~/wm/packages/api/hook/useApi';
import { integrationSetupGet, integrationSetupUpsert } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import {
  Request as IntegrationUpsertRequest,
  Response as IntegrationSetupUpsertResponse,
} from '@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Controller/IntegrationSetupUpsertControllerNested.gen';
import {
  Request as IntegrationSetupGetRequest,
  Response as IntegrationSetupGetResponse,
} from '@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Controller/IntegrationSetupGetControllerNested.gen';
import invariant from 'tiny-invariant';
import { Discriminant } from '@AssetManagementClient/Primitives/Results/IntegrationSetupUpsertControllerNested/Response_/IntegrationSetupError_/ResultNested.gen';
import { Result as IntegrationSetupUpsertResult } from '@AssetManagementClient/Primitives/Results/IntegrationSetupUpsertControllerNested/Response_/IntegrationSetupError_.gen';
import { ApiServerErrorResponseDto } from '~/wm/packages/api/packages/api-error/model/ApiServerErrorResponseDto';

export type UseIntegrationSetupAndDefinitionsPayload = {
  integrationId: string | undefined;
  integrationSetupId: string | undefined;
};

export type IntegrationSetupSubmitFunction = (integrationUpsertRequest: IntegrationUpsertRequest) => Promise<IntegrationSetupUpsertResult>;

/**
 * State of the API call submission to help indicate what displays to show
 */
export enum IntegrationSetupSubmitState {
  'Initializing',
  'Ready', // Ready to submit integration setup for the first time
  'Submitting',
  'LastSubmitFailed',
  'LastSubmitSuccess',
}

export const useIntegrationSetupAndDefinitions = ({ integrationId, integrationSetupId }: UseIntegrationSetupAndDefinitionsPayload) => {
  const [integrationSetupDisplayDto, setIntegrationSetupDisplayDto] = useState<IntegrationSetupDisplayDto | undefined>(undefined);
  const [integrationSetupSubmitState, setIntegrationSetupSubmitState] = useState<IntegrationSetupSubmitState>(
    IntegrationSetupSubmitState.Initializing,
  );

  const { callApi } = useApi();

  /**
   * Handlers for the API calls
   */
  const integrationSetupGetHandler = useCallback(async (): Promise<IntegrationSetupGetResponse> => {
    const integrationSetupGetPayload: IntegrationSetupGetRequest = integrationSetupId
      ? {
          integrationId: undefined,
          integrationSetupId,
        }
      : {
          integrationId,
          integrationSetupId: undefined,
        };
    const response = await callApi(() => integrationSetupGet(integrationSetupGetPayload));

    invariant(response, 'No integration setup response was given.');

    return response;
  }, [callApi, integrationId, integrationSetupId]);

  const integrationSetupUpsertHandler = useCallback(
    async (integrationUpsertRequest: IntegrationUpsertRequest) => {
      const response: IntegrationSetupUpsertResult = (await callApi(() =>
        integrationSetupUpsert(integrationUpsertRequest),
      )) as IntegrationSetupUpsertResult;
      if (!response) {
        setIntegrationSetupSubmitState(IntegrationSetupSubmitState.LastSubmitFailed);
        throw Error('There was a problem updating the integration. No response was returned.');
      } else if (response.case === Discriminant.Error) {
        setIntegrationSetupSubmitState(IntegrationSetupSubmitState.LastSubmitFailed);
        const error: ApiServerErrorResponseDto = {
          GlobalMessage: response.value.messagePublic,
          Errors: response.value.fieldErrors.reduce<{
            [index: string]: string[];
          }>(
            (errors, fieldError) => ({
              ...errors,
              [fieldError.fieldKey]: [fieldError.messagePublic],
            }),
            {},
          ),
          // The abstraction is not layered properly that's why
          // we have to specify 400 here - WM-3861
          Status: 400,
        };
        throw error;
      }

      return response;
    },
    [callApi],
  );

  const updateIntegrationSetupDisplay = useCallback(
    (integrationSetupUpsertResponse?: IntegrationSetupUpsertResponse) => {
      if (integrationSetupUpsertResponse) {
        setIntegrationSetupDisplayDto(integrationSetupUpsertResponse.integrationSetupDisplay);
        return;
      }

      integrationSetupGetHandler().then((integrationSetupGetResponse: IntegrationSetupGetResponse) => {
        setIntegrationSetupDisplayDto(integrationSetupGetResponse.integrationSetupDisplayDto);
      });
    },
    [integrationSetupGetHandler],
  );

  const submitIntegrationSetup = useCallback(
    async (integrationUpsertRequest: IntegrationUpsertRequest): Promise<IntegrationSetupUpsertResult> => {
      setIntegrationSetupSubmitState(IntegrationSetupSubmitState.Submitting);

      const integrationSetupUpsertResult: IntegrationSetupUpsertResult = await integrationSetupUpsertHandler(
        integrationUpsertRequest,
      ).catch(e => {
        throw e;
      });

      updateIntegrationSetupDisplay(integrationSetupUpsertResult.value);
      if (integrationSetupUpsertResult.value.integrationSetupDisplay) {
        setIntegrationSetupSubmitState(IntegrationSetupSubmitState.LastSubmitSuccess);
      } else {
        setIntegrationSetupSubmitState(IntegrationSetupSubmitState.Ready);
      }
      return integrationSetupUpsertResult;
    },
    [integrationSetupUpsertHandler, updateIntegrationSetupDisplay],
  );

  /**
   * Initialize the IntegrationSetup data
   */
  useEffect(() => {
    if (integrationSetupDisplayDto !== undefined) {
      return;
    }
    updateIntegrationSetupDisplay();
    if (integrationSetupId) {
      // A valid integration setup ID means this integration has been saved before
      setIntegrationSetupSubmitState(IntegrationSetupSubmitState.LastSubmitSuccess);
    } else {
      // No integration setup ID means a setup has not been created yet
      setIntegrationSetupSubmitState(IntegrationSetupSubmitState.Ready);
    }
  }, [integrationSetupDisplayDto, integrationSetupId, updateIntegrationSetupDisplay]);

  return {
    integrationSetupDisplayDto,
    submitIntegrationSetup,
    integrationSetupSubmitState,
  };
};
export default useIntegrationSetupAndDefinitions;
