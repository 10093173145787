import { css } from '@emotion/react';
import * as React from 'react';
import { Fragment } from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import Size from '~/neo-ui/model/Size';
import Icon from '~/neo-ui/packages/icon/Icon';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import AnchorLocation from '~/neo-ui/packages/anchor/types/AnchorLocation';
import buttonInternalStyles from './buttonInternalStyles';
import Color from '~/neo-ui/packages/color/Color.gen';
import Spinner from '~/neo-ui/spinner/Spinner';
import { Link } from 'react-router-dom';
import { isIframe } from '~/extensions/packages/iframe/isIframe';

export type ButtonInternalOnClick = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => void;

export type ButtonInternalProps = {
  /**
   * Optional id for tracking
   */
  id?: string;
  /**
   * Size of the button, xl is unsupported
   *
   * Default is `md`
   */
  size?: Exclude<Size, 'xl'>;
  /**
   * Link to another url
   *
   * Providing this prop will switch the `button` tag to an `a` tag
   */
  anchor?: AnchorLocation;
  /**
   * Icon displayed on the left of the children
   */
  iconLeft?: IconType;
  /**
   * Icon displayed on the right of the children
   */
  iconRight?: IconType;
  /**
   * WARNING: Look and feel of an icon will likely break if you use this prop.
   * Override the default theme icon color
   */
  iconColorOverride?: { colorRest: Color; colorActive: Color } | Color;
  /**
   * WARNING: Using an inappropriately sized icon may break the button.
   * Override the icon size
   */
  iconSizeOverride?: Exclude<Size, 'xl'>;
  /**
   * Disabled state of the button
   */
  disabled?: boolean;
  /**
   * Loading state of the button
   */
  loading?: boolean;
  /**
   * This is useful in cases where you don't want
   * the button press to trigger any upstream onClick event.
   *
   * Common example: You have a button nested within a clickable element.
   */
  preventOnClickPropagation?: boolean;
  /**
   * @param e Event fired from mouse event
   */
  onClick?: ButtonInternalOnClick;

  /**
   * Object of color variables for determining colors of different css pseudo classes
   */
  colorMap: ButtonInternalColorMap;

  invertColorMap?: boolean;

  children?: React.ReactNode;
} & Styleable;

/**
 * Ref type that is exposed to parent through forwardRef
 */
export type ButtonInternalRef = HTMLAnchorElement & HTMLButtonElement;

/**
 * Map css pseudo classes to appropriate colors
 */
export type ButtonInternalColorMap = {
  backgroundColorRest: Color;
  backgroundColorHover: Color;
  backgroundColorActive: Color;
  backgroundColorFocus: Color;

  textColorRest: Color;
  textColorHover: Color;
  textColorActive: Color;
  textColorFocus: Color;

  borderColorRest: Color;
  borderColorHover: Color;
  borderColorActive: Color;
  borderColorFocus: Color;
};

const getInvertedColorMap = (colorMap: ButtonInternalColorMap): ButtonInternalColorMap => ({
  backgroundColorRest: colorMap.textColorRest,
  backgroundColorHover: colorMap.textColorHover,
  backgroundColorActive: colorMap.textColorActive,
  backgroundColorFocus: colorMap.textColorFocus,

  textColorRest: colorMap.backgroundColorRest,
  textColorHover: colorMap.backgroundColorHover,
  textColorActive: colorMap.backgroundColorActive,
  textColorFocus: colorMap.backgroundColorFocus,

  borderColorRest: colorMap.borderColorRest,
  borderColorHover: colorMap.borderColorHover,
  borderColorActive: colorMap.borderColorActive,
  borderColorFocus: colorMap.borderColorFocus,
});

/**
 * Css props that depend on the size of a button
 */
export type ButtonInternalDisplayDetails = {
  heightRem: number;
  fontSizeRem: number;
  borderRadiusRem: number;
  paddingRem?: number;
  iconSizePx: number;
  iconMarginVerticalRem: number;
};

/**
 * Get appropriate css props for a given button size
 *
 * `xl` is unsupported
 * @param buttonSize Size of the button
 * @param iconSize Size of the icon
 */
export const buttonSizeToButtonDisplayDetails = (
  buttonSize: Exclude<Size, 'xl'>,
  iconSize?: Exclude<Size, 'xl'>,
): ButtonInternalDisplayDetails => {
  switch (buttonSize) {
    case 'xs':
      return {
        heightRem: 1.5,
        fontSizeRem: 0.75,
        borderRadiusRem: 0.375,
        paddingRem: undefined,
        iconSizePx: buttonSizeToButtonIconPx(iconSize ?? buttonSize),
        iconMarginVerticalRem: 0.25,
      };
    case 'sm':
      return {
        heightRem: 1.875,
        fontSizeRem: 0.875,
        borderRadiusRem: 0.375,
        paddingRem: 0.25,
        iconSizePx: buttonSizeToButtonIconPx(iconSize ?? buttonSize),
        iconMarginVerticalRem: 0.1875,
      };
    case 'md':
      return {
        heightRem: 2.25,
        fontSizeRem: 0.875,
        borderRadiusRem: 0.375,
        paddingRem: 0.4375,
        iconSizePx: buttonSizeToButtonIconPx(iconSize ?? buttonSize),
        iconMarginVerticalRem: 0.1875,
      };
    case 'lg':
      return {
        heightRem: 3,
        fontSizeRem: 1,
        borderRadiusRem: 0.5,
        paddingRem: 0.625,
        iconSizePx: buttonSizeToButtonIconPx(iconSize ?? buttonSize),
        iconMarginVerticalRem: 0.25,
      };
  }
};

/**
 * Get appropriate icon values for a given size
 *
 * `xl` is unsupported
 * @param size Size of the icon
 */
export const buttonSizeToButtonIconPx = (size: Exclude<Size, 'xl'>): number => {
  switch (size) {
    case 'xs':
      return 12;
    case 'sm':
    case 'md':
      return 16;
    case 'lg':
      return 20;
  }
};

/**
 * **Only for internal ui, do not use in a domain component**
 *
 * Base button component that all buttons are created on
 */
const ButtonInternal = React.forwardRef<ButtonInternalRef, ButtonInternalProps>(
  (
    {
      id,
      size = 'md',
      anchor,
      iconLeft,
      iconRight,
      iconColorOverride,
      iconSizeOverride,
      disabled = false,
      loading = false,
      preventOnClickPropagation = false,
      onClick,
      colorMap,
      invertColorMap = false,
      className,
      children,
    }: ButtonInternalProps,
    ref,
  ) => {
    const [isActive, setIsActive] = React.useState(false);

    const iconCount = 0 + (iconLeft ? 1 : 0) + (iconRight ? 1 : 0);

    const buttonColorMap = invertColorMap ? getInvertedColorMap(colorMap) : colorMap;

    const iconColorRest =
      typeof iconColorOverride === 'undefined'
        ? buttonColorMap.textColorRest
        : typeof iconColorOverride === 'object'
        ? iconColorOverride.colorRest
        : iconColorOverride;

    const iconColorActive =
      typeof iconColorOverride === 'undefined'
        ? buttonColorMap.textColorActive
        : typeof iconColorOverride === 'object'
        ? iconColorOverride.colorActive
        : iconColorOverride;

    const iconColor = isActive ? iconColorActive : iconColorRest;

    const displayDetails = buttonSizeToButtonDisplayDetails(size, iconSizeOverride);
    const style = buttonInternalStyles({
      iconCount,
      displayDetails,
      colorMap: buttonColorMap,
    });

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const SwitchTag: React.ElementType = disabled || typeof anchor === 'undefined' ? 'button' : 'a';

    const content = (
      <Fragment>
        {iconLeft && (
          <Icon
            icon={iconLeft}
            sizePx={displayDetails.iconSizePx}
            color={iconColor}
            css={css`
              margin-bottom: ${displayDetails.iconMarginVerticalRem}rem;
              margin-top: ${displayDetails.iconMarginVerticalRem}rem;
            `}
          />
        )}
        {loading ? (
          <Spinner
            color={iconColor}
            css={css`
              height: ${displayDetails.iconSizePx}px;
              width: ${displayDetails.iconSizePx}px;
            `}
          />
        ) : (
          children && (
            <div className={'button-children'}>
              {children}
              {
                // Prevents string children from accidentally ellipsizing on firefox, ie. 'Unlink' became 'Unl...'
                typeof children === 'string' && (
                  <span
                    css={css`
                      margin-left: 0.1875rem;
                    `}
                  >
                    &nbsp;
                  </span>
                )
              }
            </div>
          )
        )}
        {iconRight && (
          <Icon
            icon={iconRight}
            sizePx={displayDetails.iconSizePx}
            color={iconColor}
            css={css`
              margin-bottom: ${displayDetails.iconMarginVerticalRem}rem;
              margin-top: ${displayDetails.iconMarginVerticalRem}rem;
            `}
          />
        )}
      </Fragment>
    );

    return isIframe() ||
      typeof WM.account === 'undefined' ||
      !WM.account.isEnceladusAllowed ||
      disabled ||
      typeof anchor === 'undefined' ||
      typeof anchor.download !== 'undefined' ? (
      <SwitchTag
        id={id}
        type={'button'}
        className={className}
        href={anchor?.href}
        download={anchor?.download}
        target={anchor?.openInNewTab ? '_blank' : undefined}
        css={[style]}
        onClick={e => {
          if (preventOnClickPropagation) {
            e.stopPropagation();
          }
          if (onClick) {
            onClick(e);
          }
        }}
        onMouseDown={() => buttonColorMap.textColorActive !== buttonColorMap.textColorRest && setIsActive(true)}
        onMouseUp={() => buttonColorMap.textColorActive !== buttonColorMap.textColorRest && setIsActive(false)}
        onMouseOut={() => buttonColorMap.textColorActive !== buttonColorMap.textColorRest && setIsActive(false)}
        disabled={disabled}
        ref={ref}
      >
        {content}
      </SwitchTag>
    ) : (
      <Link
        id={id}
        type={'button'}
        className={className}
        to={anchor.href}
        download={anchor?.download}
        target={anchor?.openInNewTab ? '_blank' : undefined}
        css={[style]}
        onClick={e => {
          if (preventOnClickPropagation) {
            e.stopPropagation();
          }
          if (onClick) {
            onClick(e);
          }
        }}
        onMouseDown={() => buttonColorMap.textColorActive !== buttonColorMap.textColorRest && setIsActive(true)}
        onMouseUp={() => buttonColorMap.textColorActive !== buttonColorMap.textColorRest && setIsActive(false)}
        onMouseOut={() => buttonColorMap.textColorActive !== buttonColorMap.textColorRest && setIsActive(false)}
        ref={ref}
      >
        {content}
      </Link>
    );
  },
);

export default ButtonInternal;
