import * as React from 'react';
import FeatureAccessBanner from '~/wm/packages/feature/packages/feature-access-banner/FeatureAccessBanner';
import useSubscriptionDetailsPlan from '~/wm/packages/subscription/packages/subscription-details/hooks/useSubscriptionDetailsPlan';
import useScorecardUrlContext from '~/wm/packages/strategy/packages/scorecard/context/hooks/useScorecardUrlContext';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import useFeatureAccessOrganizationUnlockClientFocusScorecardRoadmap from '~/wm/packages/feature/packages/feature-access-banner/hooks/useFeatureAccessOrganizationUnlockClientFocusScorecardRoadmap';

const FeatureAccessBannerScorecard = () => {
  const featureKey = 'scorecard-roadmap';
  const imageSrc = '/i/marketing/scorecards/image.png';
  const { upgradeUrl, growUrl } = useScorecardUrlContext();
  const { detailsPlan } = useSubscriptionDetailsPlan();
  const { organizationName } = useOrganizationContext();
  const { organizationUnlock, isUnlocking } = useFeatureAccessOrganizationUnlockClientFocusScorecardRoadmap({ reloadOnSuccess: true });

  // Don't show the a banner if we don't have enough information
  if (typeof detailsPlan === 'undefined') {
    return null;
  }

  const unlockBannerDetails = {
    title: `Want to give ${organizationName} a better experience?`,
    description: 'Unlock new features for simplified and strategic client engagement. Try it free for one client.',
  };

  if (detailsPlan.planEdition === 'Free') {
    return (
      <FeatureAccessBanner
        featureKey={featureKey}
        unlockBannerDetails={unlockBannerDetails}
        upgradeBannerDetails={{
          title: 'Help all your clients understand IT risks and wins.',
          description:
            'Upgrading to Pro plan gives you full access to Scorecards to generate color-coded, easy-to-understand content for all your clients.',
        }}
        imageSrc={imageSrc}
        upgradeUrl={upgradeUrl}
        isUnlocking={isUnlocking}
        onOrganizationUnlock={(_, organizationId) => organizationUnlock({ organizationId })}
      />
    );
  }

  if (
    (detailsPlan.generation === 1 || detailsPlan.generation === 2 || detailsPlan.generation === 3) &&
    typeof detailsPlan.term === 'undefined'
  ) {
    return (
      <FeatureAccessBanner
        featureKey={featureKey}
        unlockBannerDetails={unlockBannerDetails}
        upgradeBannerDetails={{
          title: 'Help all your clients understand IT risks and wins.',
          description:
            'Upgrading to the latest version gives you full access to Scorecards to generate color-coded, easy-to-understand content for all your clients.',
        }}
        imageSrc={imageSrc}
        upgradeUrl={growUrl}
        isUnlocking={isUnlocking}
        onOrganizationUnlock={(_, organizationId) => organizationUnlock({ organizationId })}
      />
    );
  }

  return null;
};

export default FeatureAccessBannerScorecard;
