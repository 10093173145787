import * as React from 'react';
import useThemeInputTitle from '~/neo-ui/packages/form/packages/form-input/packages/form-title-input/hooks/useThemeInputTitle';
import useStylesInputTitle from '~/neo-ui/packages/form/packages/form-input/packages/form-title-input/hooks/useStylesInputTitle';
import useFormInputBuilder from '~/neo-ui/packages/form/packages/form-input/hooks/useFormInputBuilder';
import { InputTitleSizes, inputTitleSizeToInputTitleDisplayDetails } from '~/neo-ui/packages/input/packages/input-title/InputTitle';
import { css } from '@emotion/react';
import FormTextareaInputInternal, {
  FormTextareaInputInternalProps,
} from '~/neo-ui/packages/form/packages/form-input/packages/form-textarea-input/FormTextareaInputInternal';

export type FormTextareaInputProps<T> = {
  size?: InputTitleSizes;
} & FormTextareaInputInternalProps<T>;

/**
 * Text area element to be placed inside a Form component
 */
const FormTextareaInput = <T,>({
  fieldKey,
  placeholder,
  disabled,
  optimizePerformance,
  theme,
  size = 'md',
  fixedErrorPosition,
  textAreaCss,
  showFormError,
  hasDefaultFocus,
  hasDefaultSelect,
  maxLength,
  onChange,
  className,
}: FormTextareaInputProps<T>) => {
  const { error, touched } = useFormInputBuilder<T>(fieldKey);

  // Invalid state uses negative styles
  const isInvalid = typeof error !== 'undefined' && touched;
  const { themeMap } = useThemeInputTitle(isInvalid && showFormError ? 'negative' : theme);
  const displayDetails = inputTitleSizeToInputTitleDisplayDetails(size);
  const { stylesInputTitle } = useStylesInputTitle(themeMap, displayDetails);

  return (
    <FormTextareaInputInternal
      fieldKey={fieldKey}
      optimizePerformance={optimizePerformance}
      fixedErrorPosition={fixedErrorPosition}
      className={className}
      placeholder={placeholder}
      disabled={disabled}
      theme={theme}
      onChange={onChange}
      textAreaCss={css([textAreaCss, stylesInputTitle])}
      showFormError={showFormError}
      hasDefaultSelect={hasDefaultSelect}
      hasDefaultFocus={hasDefaultFocus}
      maxLength={maxLength}
    />
  );
};

export default FormTextareaInput;
