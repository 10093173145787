import { css } from '@emotion/react';
import React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import Theme from '~/neo-ui/packages/color/Theme';
import BorderRadius, { borderRadiusToCode } from '~/neo-ui/packages/style/BorderRadius';
import BoxShadow, { boxShadowToCode } from '~/neo-ui/packages/style/BoxShadow';
import useTheme, { ThemeMapOption } from '../color/hooks/useTheme';
import Padding, { paddingToCode } from '../style/Padding';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { motion } from 'framer-motion';

export type BoxProps = {
  borderRadius?: BorderRadius;
  boxShadow?: BoxShadow;
  theme?: Theme | 'none';
  background?: ThemeMapOption;
  padding?: Padding;
  onClick?: () => void;
  children?: React.ReactNode;
} & Styleable;

type BoxRef = HTMLDivElement;

/**
 * General container to be used for rectangular components in the app
 *
 * Supports theme to apply color variations that are passed down inner components
 */
const Box = React.forwardRef<BoxRef, BoxProps>(
  ({ className, borderRadius, boxShadow, theme = 'primary', background = 'theme', padding, onClick, children }: BoxProps, ref) => {
    const { themeMap } = useTheme(theme === 'none' ? undefined : theme);
    return (
      <motion.div
        ref={ref}
        className={className ? `${className}` : ''}
        onClick={onClick}
        css={css`
          border-radius: ${borderRadiusToCode(borderRadius)};
          box-shadow: ${boxShadowToCode(boxShadow)};
          background-color: ${colorToCode(themeMap[background])};
          padding: ${paddingToCode(padding ?? 'padding0')};
        `}
      >
        {children}
      </motion.div>
    );
  },
);
export default Box;
