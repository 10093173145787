import * as React from 'react';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { ContractBillingCurrencyDto } from '@AssetManagementClient/BeastClient/Beast/Contract/Packages/Billing/Dto/Model.gen';
import { getCurrencyCharacterByCurrencyCode } from '~/extensions/packages/currency/formatCurrency';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { css } from '@emotion/react';
import { isNaN } from 'formik';
import { ContractCreateBillingCostCalculationFormData } from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-create-button/packages/contract-create-form/ContractCreateForm';
import { ContractEditBillingCostCalculationFormData } from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-list-table/packages/contract-edit-vendor-table-cell/packages/contract-edit-window-form-wrapper/packages/contract-edit-form/ContractEditForm';
import InputTitle from '~/neo-ui/packages/input/packages/input-title/InputTitle';
import useFormInputBuilder from '~/neo-ui/packages/form/packages/form-input/hooks/useFormInputBuilder';
import { FormErrorMessageLabel } from '~/neo-ui/packages/form/packages/form-display/packages/form-error-message/FormErrorMessage';

export type ContractBillingCostCalculationFormData =
  | ContractEditBillingCostCalculationFormData
  | ContractCreateBillingCostCalculationFormData;

export type ContractUpsertBillingCostingInputProps<T> = {
  contractBillingCurrency: ContractBillingCurrencyDto;
  costFieldKey: FieldKeyExpression<T>;
  perSeatCostFieldKey: FieldKeyExpression<T>;
  numberOfSeatsFieldKey: FieldKeyExpression<T>;
  costVariablesFieldKey: FieldKeyExpression<T>;
  isSyncedFromPsa: boolean;
  onUpdate: (costVariables: ContractBillingCostCalculationFormData) => void;
};

type NumberInputType = 'cost' | 'integer';

const validateNumberInput = (number: string | number | undefined) =>
  typeof number !== 'undefined' && number !== '' && !isNaN(number === '' ? undefined : Number(number));

const mapNumberInput = (numberString: string, numberType: NumberInputType): string => {
  // Allows pasting complete currency-type numbers into the input
  // e.g., if I write $12,123.20 => 12123.20
  const mappedString = numberString.replace(/[^\d.]/g, '');

  switch (numberType) {
    case 'cost':
      // Case: When number is valid + there is more than 2 decimal places => truncate after 2 decimal places
      const isValid = validateNumberInput(mappedString);
      if (isValid && /[.][\d]{3,}$/.test(mappedString)) {
        return Number(mappedString.slice(0, -1)).toFixed(2);
      }
      break;

    case 'integer':
      // Case: When number is valid and not 0 => truncate after 0 decimal places
      if (validateNumberInput(mappedString) && Number(mappedString) !== 0) {
        return Number(mappedString).toFixed(0);
      }
      break;
  }

  return mappedString;
};

const ContractUpsertBillingCostingInputs = <T,>({
  contractBillingCurrency,
  costVariablesFieldKey,
  costFieldKey,
  perSeatCostFieldKey,
  numberOfSeatsFieldKey,
  isSyncedFromPsa,
  onUpdate,
}: ContractUpsertBillingCostingInputProps<T>) => {
  const { getFormInput } = useFormContext<T>();

  const formFieldCss = css`
    display: flex;
    gap: 1rem;
    align-items: center;
    width: calc(100% / 3);
  `;

  // Used as cost symbol for cost fields
  const currencyIcon = getCurrencyCharacterByCurrencyCode(contractBillingCurrency.codeAlpha);

  const costVariables = getFormInput<ContractBillingCostCalculationFormData>(costVariablesFieldKey).value;

  // Field Value States
  const [costSubunits, setCostSubunits] = React.useState(costVariables.costSubunits.toString());
  const [perSeatCostSubunits, setPerSeatCostSubunits] = React.useState((costVariables.perSeatCostSubunits ?? '').toString());
  const [numberOfSeats, setNumberOfSeats] = React.useState((costVariables.numberOfSeats ?? '').toString());

  // Field Errors and Touched states
  const { error: costFieldError } = useFormInputBuilder<T>(costFieldKey);
  const { error: perSeatCostFieldError } = useFormInputBuilder<T>(perSeatCostFieldKey);
  const { error: numberOfSeatsFieldError } = useFormInputBuilder<T>(numberOfSeatsFieldKey);

  // Field input validation states
  const isNumberOfSeatInputValid = validateNumberInput(numberOfSeats);
  const isCostInputValid = validateNumberInput(costSubunits);
  const isPerSeatCostInputValid = validateNumberInput(perSeatCostSubunits);

  return (
    <div
      css={css`
        display: flex;
        gap: 2rem;
        align-items: center;
        margin-top: 1.5rem;
      `}
    >
      <div css={formFieldCss}>
        <Label
          size={'md'}
          bold={true}
        >
          Total Cost
        </Label>
        <div
          css={css`
            flex-grow: 1;
            position: relative;
          `}
        >
          <InputTitle
            size={'md'}
            prependCharacter={currencyIcon}
            placeholder={'Enter cost…'}
            disabled={isSyncedFromPsa}
            isInvalid={!isCostInputValid || typeof costFieldError !== 'undefined'}
            value={mapNumberInput(costSubunits.toString(), 'cost')}
            onChange={costString => {
              const mappedString = costString.replace(/[^\d.]/g, '');

              // Format the cost && update state
              const newCostSubunits = mapNumberInput(mappedString, 'cost');
              setCostSubunits(newCostSubunits);

              // CASE: they remove a decimal in the string, or remove a 0 from the cents => do nothing
              if (Number(newCostSubunits) === costVariables.costSubunits) {
                return;
              }

              // CASE: if input is valid & number of seats is valid => update the per seat cost
              if (isNumberOfSeatInputValid && typeof numberOfSeatsFieldError === 'undefined' && validateNumberInput(mappedString)) {
                const newPerSeatCostSubunits = (Number(mappedString) / Number(costVariables.numberOfSeats)).toFixed(2);
                setPerSeatCostSubunits(newPerSeatCostSubunits);
                onUpdate({
                  numberOfSeats: costVariables.numberOfSeats,
                  costSubunits: Number(newCostSubunits),
                  perSeatCostSubunits: Number(newPerSeatCostSubunits),
                });

                return;
              }

              // CASE: allow the form error to show if the input is invalid
              onUpdate({
                numberOfSeats: costVariables.numberOfSeats,
                costSubunits: Number(newCostSubunits),
                perSeatCostSubunits: costVariables.perSeatCostSubunits,
              });
            }}
            onClear={() =>
              onUpdate({
                costSubunits: 0,
                perSeatCostSubunits: Number(perSeatCostSubunits),
                numberOfSeats: Number(numberOfSeats),
              })
            }
          />
          {typeof costFieldError !== 'undefined' && (
            <FormErrorMessageLabel
              errorMessage={costFieldError}
              fixedPosition={true}
            />
          )}
        </div>
      </div>

      <div css={formFieldCss}>
        <Label
          size={'md'}
          bold={true}
          css={css`
            width: fit-content;
          `}
        >
          Cost per seat
        </Label>
        <div
          css={css`
            flex-grow: 1;
            position: relative;
          `}
        >
          <InputTitle
            size={'md'}
            placeholder={'Enter per seat cost…'}
            prependCharacter={currencyIcon}
            disabled={isSyncedFromPsa}
            isInvalid={!isPerSeatCostInputValid || typeof perSeatCostFieldError !== 'undefined'}
            value={mapNumberInput(perSeatCostSubunits, 'cost')}
            onChange={perSeatCostString => {
              const mappedString = perSeatCostString.toString().replace(/[^\d.]/g, '');

              // Format the per seat cost && update state (only if not empty string and valid number)
              const isNumberValid = validateNumberInput(mappedString);
              const newPerSeatCostSubunits = isNumberValid ? mapNumberInput(mappedString, 'cost') : mappedString;
              setPerSeatCostSubunits(newPerSeatCostSubunits);

              // If number is invalid, do not proceed to any other calculations
              if (!isNumberValid) {
                return;
              }

              // CASE(1): if is synced from psa or & cost is valid
              // CASE(2): if not synced to psa & number of seats is not defined & cost is valid
              // OPERATION => update the number of seats
              if (
                (isSyncedFromPsa || (!isSyncedFromPsa && typeof costVariables.numberOfSeats === 'undefined')) &&
                isCostInputValid &&
                typeof costFieldError === 'undefined' &&
                costVariables.costSubunits % Number(mappedString) === 0
              ) {
                const newNumberOfSeats = (costVariables.costSubunits / Number(mappedString)).toFixed(0);

                setNumberOfSeats(newNumberOfSeats);
                onUpdate({
                  numberOfSeats: Number(newNumberOfSeats),
                  costSubunits: costVariables.costSubunits,
                  perSeatCostSubunits: Number(newPerSeatCostSubunits),
                });

                return;
              }

              // CASE: if not synced to psa & number of seats is valid => update the cost
              if (isNumberOfSeatInputValid && typeof numberOfSeatsFieldError === 'undefined') {
                const newCostSubunits = Number(Number(mappedString) * Number(costVariables.numberOfSeats)).toFixed(2);

                setCostSubunits(newCostSubunits);
                onUpdate({
                  numberOfSeats: costVariables.numberOfSeats,
                  costSubunits: Number(newCostSubunits),
                  perSeatCostSubunits: Number(newPerSeatCostSubunits),
                });

                return;
              }

              // CASE: allow the form error to show if the input is invalid
              onUpdate({
                numberOfSeats: costVariables.numberOfSeats,
                costSubunits: costVariables.costSubunits,
                perSeatCostSubunits: Number(newPerSeatCostSubunits),
              });
            }}
            onClear={() =>
              onUpdate({
                costSubunits: Number(costSubunits),
                perSeatCostSubunits: 0,
                numberOfSeats: Number(numberOfSeats),
              })
            }
          />
          {typeof perSeatCostFieldError !== 'undefined' && (
            <FormErrorMessageLabel
              errorMessage={perSeatCostFieldError}
              fixedPosition={true}
            />
          )}
        </div>
      </div>

      <div css={formFieldCss}>
        <Label
          size={'md'}
          bold={true}
        >
          Number of seats
        </Label>
        <div
          css={css`
            flex-grow: 1;
            position: relative;
          `}
        >
          <InputTitle
            size={'md'}
            placeholder={'Enter number of seats…'}
            disabled={isSyncedFromPsa}
            isInvalid={!isNumberOfSeatInputValid || typeof numberOfSeatsFieldError !== 'undefined'}
            value={mapNumberInput(numberOfSeats, 'integer')}
            onChange={numberOfSeatsString => {
              const mappedString = numberOfSeatsString.toString().replace(/[^\d.]/g, '');

              // CASE: if number of seats is empty string, the user is still trying to finish their input
              if (numberOfSeatsString === '') {
                setNumberOfSeats(mappedString);
                return;
              }

              // CASE: if the input is invalid => do nothing
              if (!validateNumberInput(mappedString) || Number(mappedString) < 1) {
                return;
              }

              // Format the number of seats && update state
              const newNumberOfSeats = mapNumberInput(mappedString, 'integer');
              setNumberOfSeats(newNumberOfSeats);

              // CASE: if cost is valid => update the per seat cost
              if (isCostInputValid && typeof costFieldError === 'undefined') {
                const newPerSeatCostSubunits = (Number(costVariables.costSubunits) / Number(mappedString)).toFixed(2);

                setPerSeatCostSubunits(newPerSeatCostSubunits);
                onUpdate({
                  numberOfSeats: Number(newNumberOfSeats),
                  costSubunits: costVariables.costSubunits,
                  perSeatCostSubunits: Number(newPerSeatCostSubunits),
                });

                return;
              }

              // CASE: if per seat cost is valid => update the cost
              if (isPerSeatCostInputValid && typeof perSeatCostFieldError === 'undefined') {
                const newCostSubunits = Number(Number(costVariables.perSeatCostSubunits) * Number(mappedString)).toFixed(2);

                setCostSubunits(newCostSubunits);
                onUpdate({
                  numberOfSeats: Number(newNumberOfSeats),
                  costSubunits: Number(newCostSubunits),
                  perSeatCostSubunits: costVariables.perSeatCostSubunits,
                });

                return;
              }

              // CASE: allow the form error to show if the input is invalid
              onUpdate({
                numberOfSeats: Number(newNumberOfSeats),
                costSubunits: costVariables.costSubunits,
                perSeatCostSubunits: costVariables.perSeatCostSubunits,
              });
            }}
            onClear={() =>
              onUpdate({
                costSubunits: Number(costSubunits),
                perSeatCostSubunits: Number(perSeatCostSubunits),
                numberOfSeats: 0,
              })
            }
          />
          {typeof numberOfSeatsFieldError !== 'undefined' && (
            <FormErrorMessageLabel
              errorMessage={numberOfSeatsFieldError}
              fixedPosition={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ContractUpsertBillingCostingInputs;
