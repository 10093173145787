import * as React from 'react';
import { DataTableColumn } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import { DisposalOrderAssetDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalOrder/Packages/DisposalOrderAsset/Dto/Model.gen';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import { DisposalAssetTypeAvailabilityDto } from '@AssetManagementClient/BeastClient/Beast/Disposal/Packages/DisposalAsset/Packages/DisposalAssetType/Dto/Model.gen';
import Icon from '~/neo-ui/packages/icon/Icon';
import IconType from '~/neo-ui/packages/icon/IconType.gen';

const getAssetTypeIcon = (assetType: string | undefined, assetTypeAvailabilities: DisposalAssetTypeAvailabilityDto[]): IconType =>
  (assetTypeAvailabilities.find(availability => availability.label === assetType)?.icon ?? 'Workstation') as IconType;

const getDisposalOrderAssetTableColumns = (
  disposalAssetTypeAvailabilities: DisposalAssetTypeAvailabilityDto[],
): DataTableColumn<DisposalOrderAssetDto>[] => [
  {
    fieldKey: disposalOrderAsset => disposalOrderAsset.assetType,
    Header: 'Type',
    renderCell: disposalOrderAsset => <Icon icon={getAssetTypeIcon(disposalOrderAsset.assetType, disposalAssetTypeAvailabilities)} />,
  },
  {
    fieldKey: disposalOrderAsset => disposalOrderAsset.displayName,
    Header: 'Name',
    renderCell: disposalOrderAsset => disposalOrderAsset.displayName ?? '-',
  },
  {
    fieldKey: disposalOrderAsset => disposalOrderAsset.manufacturerDisplay,
    Header: 'Manufacturer & Model',
    renderCell: disposalOrderAsset => (
      <React.Fragment>
        {typeof disposalOrderAsset.modelDisplay === 'undefined' && typeof disposalOrderAsset.manufacturerDisplay === 'undefined' && '-'}
        {typeof disposalOrderAsset.manufacturerDisplay !== 'undefined' && (
          <Label size={'md'}>{disposalOrderAsset.manufacturerDisplay}</Label>
        )}
        {typeof disposalOrderAsset.modelDisplay !== 'undefined' && <Label size={'sm'}>{disposalOrderAsset.modelDisplay}</Label>}
      </React.Fragment>
    ),
  },
  {
    fieldKey: disposalOrderAsset => disposalOrderAsset.serialNumber,
    Header: 'Serial',
    renderCell: disposalOrderAsset => <Label size={'md'}>{disposalOrderAsset.serialNumber}</Label>,
  },
];

export default getDisposalOrderAssetTableColumns;
