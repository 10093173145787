import * as Yup from 'yup';
import { ContractAssetDto } from '@AssetManagementClient/BeastClient/Beast/Contract/Packages/Asset/Dto/Model.gen';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const contractEditFormValidationSchema: Yup.ObjectSchema<any> = Yup.object().shape({
  basic: Yup.object().shape({
    vendor: Yup.string()
      .required('Required')
      // Duped in backend d93fcd70-7528-43ed-8cbc-21f8470c2bc5
      .max(50, 'This vendor is too long!'),
    account: Yup.string()
      // Duped in backend 441b25c5-3f1f-41ab-9616-8bc96806b3cb
      .max(40, 'This account is too long!'),
    status: Yup.string().required('Required'),
    impact: Yup.string().required('Required'),
    description: Yup.string()
      // Duped in backend 2bccc762-a7cf-4c78-bc23-16ee000b7255
      .max(75, 'This description is too long!'),
    location: Yup.string()
      // Duped in backend b8393d57-ba80-4815-af8f-33169b5408a8
      .max(40, 'This location is too long!'),
    isThirdParty: Yup.boolean().required('Required'),
  }),
  billing: Yup.object().shape({
    cycle: Yup.string().required('Required'),
    costVariables: Yup.object().shape({
      costSubunits: Yup.number()
        .typeError('Cost must be number')
        .required('Required')
        .min(0, 'Cost must be positive')
        .max(20000000, 'Cost is too large'),
      perSeatCostSubunits: Yup.number().notRequired().min(0, 'Per seat cost must be positive').max(20000000, 'Per seat cost is too large'),
      numberOfSeats: Yup.number().notRequired().min(1, 'Number of seats must be positive').max(20000000, 'Number of seats is too large'),
      budgetPastEndDate: Yup.boolean(),
    }),
    costType: Yup.string().required('Required'),
    nextDue: Yup.string().required('Required'),
    endDate: Yup.string(),
    isAutoRenew: Yup.boolean().required('Required'),
  }),
  alert: Yup.object().shape({
    noticeDays: Yup.number().required('Required').min(0),
  }),
  asset: Yup.object().shape({
    assets: Yup.array<ContractAssetDto>().required('Required').min(0),
  }),
});

export default contractEditFormValidationSchema;
