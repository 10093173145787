import * as React from 'react';
import useContract from '~/wm/packages/strategy/packages/contract/packages/contract-list/hooks/useContract';
import Spinner from '~/neo-ui/spinner/Spinner';
import Window from '~/neo-ui/packages/window/Window';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { ContractEditFormData } from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-list-table/packages/contract-edit-vendor-table-cell/packages/contract-edit-window-form-wrapper/packages/contract-edit-form/ContractEditForm';
import useContractListContext from '~/wm/packages/strategy/packages/contract/packages/contract-list/context/hooks/useContractListContext';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import { css } from '@emotion/react';
import Button from '~/neo-ui/packages/button/Button';
import ContractDeleteButton from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-list-table/packages/contract-delete-button/ContractDeleteButton';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';
import ContractUpsertBillingSection from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-upsert-billing-section/ContractUpsertBillingSection';
import ContractUpsertBasicInfoSection from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-upsert-basic-info-section/ContractUpsertBasicInfoSection';
import buildContractAssetInfoFromContractAssetDto from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-link-assets-button/builder/buildContractAssetInfoFromContractAssetDto';
import ContractUpsertRenewSection from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-upsert-renew-section/ContractUpsertRenewSection';

type ContractEditWindowProps = {
  organizationId: string;
  contractId: string;
  isOpen: boolean;
  onDismiss: () => void;
};

const vendorFieldKey: FieldKeyExpression<ContractEditFormData> = values => values.basic.vendor;
const accountFieldKey: FieldKeyExpression<ContractEditFormData> = values => values.basic.account;
const locationFieldKey: FieldKeyExpression<ContractEditFormData> = values => values.basic.location;
const descriptionFieldKey: FieldKeyExpression<ContractEditFormData> = values => values.basic.description;
const thirdPartyFieldKey: FieldKeyExpression<ContractEditFormData> = values => values.basic.isThirdParty;
const nextDueFieldKey: FieldKeyExpression<ContractEditFormData> = values => values.billing.nextDue;
const endDateFieldKey: FieldKeyExpression<ContractEditFormData> = values => values.billing.endDate;
const autoRenewFieldKey: FieldKeyExpression<ContractEditFormData> = values => values.billing.isAutoRenew;
const noticeDaysFieldKey: FieldKeyExpression<ContractEditFormData> = values => values.alert.noticeDays;
const statusFieldKey: FieldKeyExpression<ContractEditFormData> = values => values.basic.status;
const impactFieldKey: FieldKeyExpression<ContractEditFormData> = values => values.basic.impact;
const costTypeFieldKey: FieldKeyExpression<ContractEditFormData> = values => values.billing.costType;
const cycleFieldKey: FieldKeyExpression<ContractEditFormData> = values => values.billing.cycle;
const assetsFieldKey: FieldKeyExpression<ContractEditFormData> = values => values.asset.assets;
const costVariablesFieldKey: FieldKeyExpression<ContractEditFormData> = values => values.billing.costVariables;
const costFieldKey: FieldKeyExpression<ContractEditFormData> = values => values.billing.costVariables.costSubunits;
const perSeatCostTypeFieldKey: FieldKeyExpression<ContractEditFormData> = values => values.billing.costVariables.perSeatCostSubunits;
const numberOfSeatsFieldKey: FieldKeyExpression<ContractEditFormData> = values => values.billing.costVariables.numberOfSeats;

const budgetPastEndDateFieldKey: FieldKeyExpression<ContractEditFormData> = values => values.billing.shouldBudgetPastEndDate;

const ContractEditWindow: React.FunctionComponent<ContractEditWindowProps> = ({ organizationId, contractId, isOpen, onDismiss }) => {
  const { trackEvent: trackEventProcessor } = useEventTracking(TrackingServices.EventProcessor);

  const { isLoadingContract, contract } = useContract(organizationId, contractId);

  const { contractAvailabilities, contractBillingCurrency } = useContractListContext();
  const { submitForm, isSubmitting } = useFormContext<ContractEditFormData>();

  // Return a loading window until quick view loads to reduce perceived lag
  if (!contract || isLoadingContract) {
    return (
      <Window
        titleIcon={'Edit'}
        title={'Edit Contract'}
        isOpen={isOpen}
        onDismiss={onDismiss}
      >
        <Spinner />
      </Window>
    );
  }

  const isSyncedFromPsa = typeof contract.basicSummary.agreementId !== 'undefined';

  return (
    <Window
      maxWidthRem={77.625}
      titleIcon={'Edit'}
      title={'Edit Contract'}
      isOpen={isOpen}
      onDismiss={onDismiss}
      headerProps={{
        rightControls: [
          {
            expanded: (
              <ContractDeleteButton
                contractId={contract.basicSummary.contractId}
                organizationId={contract.basicSummary.organizationInfo.organizationId}
                disabled={isSubmitting}
              />
            ),
          },
        ],
      }}
      footerProps={{
        rightControls: [
          {
            expanded: (
              <Button
                theme={'primary'}
                iconRight={'Edit'}
                loading={isSubmitting}
                onClick={() => {
                  submitForm();
                  trackEventProcessor('contract_update_save', {});
                }}
              >
                Save
              </Button>
            ),
          },
        ],
      }}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: 100%;
        `}
      >
        <ContractUpsertBasicInfoSection
          initialSelectedAssets={buildContractAssetInfoFromContractAssetDto(contract.assetsSummary)}
          organizationId={organizationId}
          contractAvailabilities={contractAvailabilities}
          isSyncedFromPsa={isSyncedFromPsa}
          nameFieldKey={vendorFieldKey}
          accountFieldType={accountFieldKey}
          statusFieldKey={statusFieldKey}
          locationFieldKey={locationFieldKey}
          impactFieldKey={impactFieldKey}
          descriptionFieldKey={descriptionFieldKey}
          thirdPartyFieldKey={thirdPartyFieldKey}
          assetsFieldKey={assetsFieldKey}
        />

        <hr
          css={css`
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
            width: 100%;
          `}
        />
        <ContractUpsertBillingSection
          contractBillingCurrency={contractBillingCurrency}
          contractAvailabilities={contractAvailabilities}
          billingCycleFieldKey={cycleFieldKey}
          costTypeFieldKey={costTypeFieldKey}
          costVariablesFieldKey={costVariablesFieldKey}
          costFieldKey={costFieldKey}
          perSeatCostFieldKey={perSeatCostTypeFieldKey}
          numberOfSeatsFieldKey={numberOfSeatsFieldKey}
          isSyncedFromPsa={isSyncedFromPsa}
        />

        <hr
          css={css`
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
            width: 100%;
          `}
        />

        <ContractUpsertRenewSection
          nextDueFieldKey={nextDueFieldKey}
          endDateFieldKey={endDateFieldKey}
          autoRenewFieldKey={autoRenewFieldKey}
          noticeDaysFieldKey={noticeDaysFieldKey}
          budgetPastEndDateFieldKey={budgetPastEndDateFieldKey}
        />
      </div>
    </Window>
  );
};

export default ContractEditWindow;
