import { parseISO } from 'date-fns';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import useApi from '~/wm/packages/api/hook/useApi';
import Notification from '~/wm/packages/notification/model/Notification';
import { notificationInAppList } from '@ActivityClient/ActivityClientPublic.gen';
import { NotificationInAppDto } from '@ActivityClient/BeastClient/Beast/Notification/Packages/Module/Packages/Notification/Dto.gen';
import { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

export type NotificationListModel = {
  count: number;
  countUnread: number;
  items: Notification[];
};

export type UseNotificationList = {
  notifications: NotificationListModel;

  /**
   * Used for optimistic mutations for the sake of UX.
   * It's a superficial update which will be overridden by any reload of notifications
   */
  mutateNotifications: (action: SetStateAction<Notification[]>) => void;

  reload: () => void;
};

const useNotificationList = (): UseNotificationList => {
  const { callApi } = useApi();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [errorCount, setErrorCount] = useState(0);

  const reload = useCallback(async () => {
    if (errorCount >= 3) {
      return;
    }

    const response = await callApi(
      () => notificationInAppList({}),
      undefined,
      true,
      () => setErrorCount(prev => prev + 1),
    );
    if (!response) {
      return;
    }
    const notes = response.notifications.map(
      (note: NotificationInAppDto): Notification => ({
        notificationUniqueId: note.notificationUniqueId,
        content: {
          title: note.content.title,
          description: note.content.description,
          icon: note.content.icon as IconType,
          callToAction: {
            label: note.content.callToAction.label,
            url: note.content.callToAction.url,
          },
        },
        isRead: !!note.readAt,
        sentAt: parseISO(note.sentAt),
      }),
    );

    setNotifications(notes);
  }, [callApi, errorCount]);

  useEffect(() => {
    reload();
  }, [reload]);

  return {
    notifications: useMemo(
      () => ({
        items: notifications,
        count: notifications.length,
        countUnread: notifications.filter(notification => !notification.isRead).length,
      }),
      [notifications],
    ),
    mutateNotifications: setNotifications,
    reload,
  };
};

export default useNotificationList;
