import useEngagementActionListMutation, {
  UseEngagementActionListMutationOptions,
} from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/hooks/useEngagementActionListMutation';
import { initiativeEngagementActionUnlink } from '@AssetManagementClient/AssetManagementClientMsp.gen';

export type UseEngagementActionInitiativeUnlink = {
  unlinkInitiative: (engagementActionId: string) => void;
  isUnlinkingInitiative: boolean;
};

const useEngagementActionInitiativeUnlink = (options?: UseEngagementActionListMutationOptions): UseEngagementActionInitiativeUnlink => {
  const [unlinkInitiative, isUnlinkingInitiative] = useEngagementActionListMutation(
    (engagementActionId: string) =>
      initiativeEngagementActionUnlink({
        engagementActionId,
      }),
    options,
  );

  return {
    unlinkInitiative,
    isUnlinkingInitiative,
  };
};

export default useEngagementActionInitiativeUnlink;
