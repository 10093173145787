import { InsightStateDto } from '@AssetManagementClient/AssetManagement/Packages/Strategy/Packages/Insights/Model.gen';
import { Enum as RiskLevel } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Rules/Logic/RiskLevelFactoryNested.gen';
import { css } from '@emotion/react';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import Icon from '~/neo-ui/packages/icon/Icon';
import Testable from '~/neo-ui/packages/testable/Testable';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import StrategyInsightDashboardFooter from '~/wm/packages/strategy/packages/strategy-dashboard/packages/insight-dashboard/insight-dashboard-item/StrategyInsightDashboardFooter';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';
import { Enum as AssetManagementClientScopingModelScopeNestedEnum } from '@AssetManagementClient/Scoping/Model/ScopeNested.gen';

export type StrategyInsightDashboardInsightProps = {
  insightTitle: string;
  insightDescription: string;
  insightCount: number;
  insightUrl: string;
  insightState: InsightStateDto;
  insightRiskLevel: RiskLevel;
  insightTrend?: number;
  insightForecast?: number;
  canFullyAccessInsights: boolean;
  frontendScope: FrontendScope;
} & Styleable;

const StrategyInsightDashboardInsight: React.FunctionComponent<StrategyInsightDashboardInsightProps> = ({
  insightTitle,
  insightDescription,
  insightCount,
  className,
  insightUrl,
  insightState,
  insightRiskLevel,
  insightTrend,
  insightForecast,
  canFullyAccessInsights,
  frontendScope,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const { trackEvent: trackEventProcessor } = useEventTracking(TrackingServices.EventProcessor);

  const insight = (
    <div
      css={css`
        width: 12.5rem;
        height: 12.5rem;
        position: relative;
        background-color: ${isHovered ? colorToCode(insightState.backgroundColorHover as Color) : colorToCode('light-000')};
        padding: 10px;
      `}
      className={className}
    >
      <Header
        size={1}
        css={css`
          margin-bottom: 0.5rem;
        `}
        color={isHovered ? 'light-000' : (insightState.foregroundColor as Color)}
      >
        {insightCount.toLocaleString()}
      </Header>
      <Testable testId={'insight-item-title'}>
        <Header
          size={5}
          color={isHovered ? 'light-000' : (insightState.foregroundColor as Color)}
          css={css`
            margin-bottom: 0.313rem;
          `}
        >
          {insightTitle}
        </Header>
      </Testable>
      <p
        css={css`
          font-size: 0.625rem;
          line-height: 0.875rem;
          color: ${isHovered ? colorToCode('light-000-80') : colorToCode('dark-900-32')};
        `}
      >
        {insightDescription}
      </p>
      {isHovered && (
        <div
          css={css`
            padding: 0.313rem;
            position: absolute;
            top: 1.15rem;
            right: 0.625rem;
            font-size: 0.75rem;
          `}
        >
          <Icon
            icon={'GoRight'}
            color={'light-000'}
          />
        </div>
      )}
    </div>
  );

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        const hasOrganizationScope = frontendScope.type === AssetManagementClientScopingModelScopeNestedEnum.Organization;
        const orgId = hasOrganizationScope ? frontendScope.organizationId : null;

        trackEventProcessor('insight_clicked', {
          obfuscatedClientId: orgId,
          insightPageUrl: insightUrl,
          insightName: insightTitle,
          scope: frontendScope.type,
        });
        window.location.href = insightUrl;
      }}
      className={'shadow-sm'}
      css={css`
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        ${isHovered &&
        `transition: all 200ms ease;
            &:hover {
              cursor: pointer;
              box-shadow: 0 0.0625rem 0.625rem -0.3125rem rgba(0, 0, 0, 0.3),
                0 0.4375rem 1.5625rem -0.625rem rgba(0, 0, 0, 0.15);
            }`}
      `}
    >
      {insight}
      {canFullyAccessInsights && (
        <StrategyInsightDashboardFooter
          insightTrend={insightTrend}
          insightForecast={insightForecast}
          insightState={insightState}
          insightRiskLevel={insightRiskLevel}
          isHovered={isHovered}
        />
      )}
    </div>
  );
};
export default StrategyInsightDashboardInsight;
