import { css } from '@emotion/react';
import * as React from 'react';
import Button from '~/neo-ui/packages/button/Button';
import Theme from '~/neo-ui/packages/color/Theme';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Testable from '~/neo-ui/packages/testable/Testable';
import AnchorLocation from '~/neo-ui/packages/anchor/types/AnchorLocation';

export type ModalConfirmationFooterProps = {
  confirmationButtonAlignment?: 'left' | 'right';
  dismissButton: ModalConfirmationFooterButtonProps;
  confirmButton?: ModalConfirmationFooterButtonProps | ModalConfirmationFooterButtonProps[];
  theme: Theme;
};

export type ModalConfirmationFooterButtonProps = {
  label: string;
  icon?: IconType;
  disabled?: boolean;
  loading?: boolean;
  theme?: Theme;
  onClick?: () => void;
  anchor?: AnchorLocation;
};

const ModalConfirmationFooter: React.FunctionComponent<ModalConfirmationFooterProps> = ({
  confirmationButtonAlignment = 'right',
  dismissButton,
  confirmButton,
  theme,
}) => {
  const footerDirection = () => {
    switch (confirmationButtonAlignment) {
      case 'left':
        return 'row-reverse';
      case 'right':
        return 'row';
    }
  };

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        flex-flow: ${footerDirection()};
      `}
    >
      {dismissButton && (
        <Button
          iconRight={confirmationButtonAlignment === 'left' ? dismissButton.icon : undefined}
          iconLeft={confirmationButtonAlignment === 'right' ? dismissButton.icon : undefined}
          onClick={dismissButton.onClick}
          disabled={dismissButton.disabled}
          loading={dismissButton.loading}
          anchor={dismissButton.anchor}
        >
          {dismissButton.label}
        </Button>
      )}
      {confirmButton &&
        (Array.isArray(confirmButton) ? (
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 0.5rem;
            `}
          >
            {confirmButton.map((button, index) => (
              <Testable
                key={index}
                testId={`modal-footer-confirm-${index}`}
              >
                <Button
                  iconLeft={confirmationButtonAlignment === 'left' ? button.icon : undefined}
                  iconRight={confirmationButtonAlignment === 'right' ? button.icon : undefined}
                  theme={button.theme ?? theme}
                  onClick={button.onClick}
                  disabled={button.disabled}
                  loading={button.loading}
                  anchor={button.anchor}
                >
                  {button.label}
                </Button>
              </Testable>
            ))}
          </div>
        ) : (
          <Testable testId={'modal-footer-confirm'}>
            <Button
              iconLeft={confirmationButtonAlignment === 'left' ? confirmButton.icon : undefined}
              iconRight={confirmationButtonAlignment === 'right' ? confirmButton.icon : undefined}
              theme={confirmButton.theme ?? theme}
              onClick={confirmButton.onClick}
              disabled={confirmButton.disabled}
              loading={confirmButton.loading}
              anchor={confirmButton.anchor}
            >
              {confirmButton.label}
            </Button>
          </Testable>
        ))}
    </div>
  );
};

export default ModalConfirmationFooter;
