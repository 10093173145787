import Window from '~/neo-ui/packages/window/Window';
import useContractSync from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-sync/hooks/useContractSync';
import { useMemo, useState } from 'react';
import useContractAgreementList from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-sync/hooks/useContractAgreementList';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import ToggleItem from '~/neo-ui/packages/toggle/packages/toggle-item/ToggleItem';
import { css } from '@emotion/react';
import { DataTableColumn } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import Button from '~/neo-ui/packages/button/Button';
import ContractSyncTable from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-sync/packages/contract-sync-table/ContractSyncTable';
import useContractAgreementSelect from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-sync/hooks/useContractAgreementSelect';
import ToggleSwitch from '~/neo-ui/packages/toggle/packages/toggle-switch/ToggleSwitch';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import Icon from '~/neo-ui/packages/icon/Icon';
import useContractSyncToggle from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-sync/hooks/useContractSyncToggle';
import { formatDate, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import { ContractSyncStatusDto } from '@AssetManagementClient/BeastClient/Beast/Contract/Packages/Sync/Dto/Model.gen';
import useContractSyncSchedule from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-sync/hooks/useContractSyncSchedule';

export type ContractAgreementRow = {
  /**
   * Agreement id for selecting
   */
  agreementId: string;
  /**
   * Rows are not selectable if it is an agreement item
   */
  isSelectable?: boolean;
  agreementItemId: string | undefined;
  name: string;
  updatedAt: string;
  status: string;
  description: string;
  billingCycle: string;
  billingCost: number;
  nextDue: string;
  endDate: string;
  assetCount: number | undefined;
};

export type ContractSyncWindowProps = {
  organizationId: string;
  isOpen: boolean;
  onClose: () => void;
  contractSyncStatus: ContractSyncStatusDto | undefined;
};

const ContractSyncWindow = ({ organizationId, isOpen, onClose, contractSyncStatus }: ContractSyncWindowProps) => {
  const { syncContracts } = useContractSync();
  const { toggleSync, isTogglingSync } = useContractSyncToggle();
  const { scheduleSync, isScheduling } = useContractSyncSchedule();
  const { contractAgreementsNew, contractAgreementsUpdated } = useContractAgreementList(organizationId);
  const [includeAgreementItems, setIncludeAgreementItems] = useState(contractSyncStatus?.includeAgreementItems ?? false);
  const [isAutoSyncingPsa, setIsAutoSyncingPsa] = useState(typeof contractSyncStatus !== 'undefined');

  const { agreementIds, updateAgreementIdsByTableType } = useContractAgreementSelect();

  const contractAgreementColumns: DataTableColumn<ContractAgreementRow>[] = useMemo(
    () => [
      {
        fieldKey: agreement => agreement.name,
        Header: 'Name',
        renderCell: agreement => agreement.name,
      },
      {
        fieldKey: agreement => agreement.updatedAt,
        Header: 'Updated Date',
        renderCell: agreement => agreement.updatedAt,
      },
      {
        fieldKey: agreement => agreement.status,
        Header: 'Status',
        renderCell: agreement => agreement.status,
      },
      {
        fieldKey: agreement => agreement.description,
        Header: 'Description',
        renderCell: agreement => agreement.description,
      },
      {
        fieldKey: agreement => agreement.billingCycle,
        Header: 'Billing Cycle',
        renderCell: agreement => agreement.billingCycle,
      },
      {
        fieldKey: agreement => agreement.billingCost,
        Header: 'Cost',
        renderCell: agreement => agreement.billingCost,
      },
      {
        fieldKey: agreement => agreement.nextDue,
        Header: 'Next Due',
        renderCell: agreement => agreement.nextDue,
      },
      {
        fieldKey: agreement => agreement.endDate,
        Header: 'End Date',
        renderCell: agreement => agreement.endDate,
      },
      {
        fieldKey: agreement => agreement.assetCount,
        Header: '# Assets',
        renderCell: agreement => agreement.assetCount,
      },
    ],
    [],
  );

  return (
    <Window
      title={'Import Agreements from Connectwise Manage'}
      titleIcon={'ConnectWisePsa'}
      isOpen={isOpen}
      onDismiss={onClose}
      maxWidthRem={80}
      headerProps={{
        leftControls: [
          ...(isAutoSyncingPsa
            ? [
                {
                  expanded: (
                    <Button
                      iconLeft={'DownloadDocument'}
                      theme={'primary'}
                      onClick={() => {
                        scheduleSync();
                        onClose();
                      }}
                      loading={isScheduling}
                    >
                      Sync Now
                    </Button>
                  ),
                },
              ]
            : [
                {
                  expanded: (
                    <Button
                      iconLeft={'DownloadDocument'}
                      theme={'primary'}
                      onClick={() => {
                        syncContracts({
                          organizationId,
                          agreementIds,
                          includeAgreementItems,
                        });
                        onClose();
                      }}
                      disabled={agreementIds.length === 0}
                    >
                      Sync {agreementIds.length} Selected Contracts
                    </Button>
                  ),
                },
              ]),
          {
            expanded: (
              <div
                css={css`
                  display: flex;
                  gap: 0.5rem;
                  align-items: center;
                `}
              >
                <Label size={'md'}>Auto-sync from PSA</Label>
                <ToggleSwitch
                  onChange={value => {
                    setIsAutoSyncingPsa(value);
                    toggleSync({
                      organizationId,
                      enableAutoSync: value,
                      includeAgreementItems,
                    });
                  }}
                  checked={isAutoSyncingPsa}
                  disabled={isTogglingSync}
                  theme={'positive'}
                />
                <Tooltip
                  backgroundColor={'light-000'}
                  content={
                    <div>
                      <Label size={'sm'}>Auto-sync will scan new contracts and contract updates on a daily base.</Label>
                      <br />
                      <Label size={'sm'}>next scheduled auto sync is on date</Label>
                      <br />
                      <Label size={'sm'}>For more information, search Auto-sync in Help Centre</Label>
                    </div>
                  }
                  placement={'right'}
                  css={css`
                    position: fixed;
                  `}
                >
                  <Icon
                    css={css`
                      // To center the icon with the toggle switch
                      margin-top: 0.5rem;
                    `}
                    icon={'Info'}
                    sizePx={16}
                  />
                </Tooltip>
              </div>
            ),
          },
        ],
      }}
    >
      <ToggleItem
        css={css`
          margin-left: auto;
        `}
        checked={includeAgreementItems}
        disabled={isTogglingSync}
        onToggle={() => {
          setIncludeAgreementItems(value => !value);
          toggleSync({
            organizationId,
            enableAutoSync: isAutoSyncingPsa,
            includeAgreementItems: !includeAgreementItems,
          });
        }}
      >
        <Label
          css={css`
            margin-left: 0.5rem;
          `}
          size={'md'}
        >
          Show Additions under Agreement?
        </Label>
      </ToggleItem>

      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
          margin-top: 0.625rem;
        `}
      >
        {!isAutoSyncingPsa && (
          <ContractSyncTable
            title={`New in PSA (${contractAgreementsNew.length})`}
            agreements={contractAgreementsNew}
            includeAgreementItems={includeAgreementItems}
            columns={contractAgreementColumns}
            tableHeaderColor={'positive-800'}
            updateAgreementIds={agreementIds => updateAgreementIdsByTableType(agreementIds, 'new')}
          />
        )}
        {!isAutoSyncingPsa && (
          <ContractSyncTable
            title={`Updated in PSA (${contractAgreementsUpdated.length})`}
            agreements={contractAgreementsUpdated}
            includeAgreementItems={includeAgreementItems}
            columns={contractAgreementColumns}
            tableHeaderColor={'primary-700'}
            updateAgreementIds={agreementIds => updateAgreementIdsByTableType(agreementIds, 'updated')}
          />
        )}
        {typeof contractSyncStatus !== 'undefined' && isAutoSyncingPsa && (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 2.5rem;
            `}
          >
            <Icon
              icon={'Backup'}
              sizePx={118}
              color={'secondary-600'}
            />
            <div
              css={css`
                text-align: center;
              `}
            >
              <Label
                size={'md'}
                bold={true}
              >
                Last synced:{' '}
                {formatDate(new Date(contractSyncStatus.lastSyncedAt), {
                  format: 'H:MM',
                  timezone: TimezoneFormat.Local,
                })}{' '}
                ,{' '}
                {formatDate(new Date(contractSyncStatus.lastSyncedAt), {
                  format: 'MM-dd-yyyy',
                  timezone: TimezoneFormat.Local,
                })}
              </Label>
              <br />
              <Label
                size={'md'}
                bold={true}
              >
                Use the 'Sync Now' button to sync instantly anytime you need.
              </Label>
            </div>
          </div>
        )}
      </div>
    </Window>
  );
};

export default ContractSyncWindow;
