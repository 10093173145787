import { Fragment, useEffect, useState } from 'react';
import { EngagementActionDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementAction/Dto/Model.gen';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Button from '~/neo-ui/packages/button/Button';
import { css } from '@emotion/react';
import EngagementActionEditForm from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-form/packages/engagement-action-edit-form/EngagementActionEditForm';
import useEngagementActionMarkCompleted from '~/wm/packages/organization/packages/engagement/packages/engagement-action/hooks/useEngagementActionMarkCompleted';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import { formatDate, getDateFriendlyExtendedDefinition, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import { parseISO } from 'date-fns';
import EngagementActionEditWindow from '~/wm/packages/organization/packages/engagement/packages/engagement-list-page/packages/engagement-action-list-panel-tab/packages/engagement-action-edit-window/EngagementActionEditWindow';
import Box from '~/neo-ui/packages/box/Box';
import useEngagementActionMarkIncomplete from '~/wm/packages/organization/packages/engagement/packages/engagement-action/hooks/useEngagementActionMarkIncomplete';
import useEngagementActionPin from '~/wm/packages/organization/packages/engagement/packages/engagement-action/hooks/useEngagementActionPin';
import useEngagementActionUnpin from '~/wm/packages/organization/packages/engagement/packages/engagement-action/hooks/useEngagementActionUnpin';
import EngagementActionTicketCreateButton from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-ticket/packages/engagement-action-ticket-create-button/EngagementActionTicketCreateButton';
import { useAnimate } from 'framer-motion';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { boxShadowToCode } from '~/neo-ui/packages/style/BoxShadow';
import calculateUserTheme from '~/wm/packages/account/packages/user/hooks/calculateUserTheme';
import Badge from '~/neo-ui/packages/badge/Badge';
import formatUserNameDisplay from '~/wm/packages/account/packages/user/packages/active-users-search/formatUserNameDisplay';
import Tooltip from '~/neo-ui/packages/tooltip/Tooltip';
import tooltipStyles from '~/neo-ui/packages/tooltip/styles/tooltipStyles';
import useTicketFieldAvailabilitiesContext from '~/wm/packages/integration/packages/ticket/context/hooks/useTicketFieldAvailabilitiesContext';
import EngagementActionTicketButton from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-ticket/packages/engagement-action-ticket-button/EngagementActionTicketButton';
import useEngagementActionListContext from '~/wm/packages/organization/packages/engagement/packages/engagement-action/context/hooks/useEngagementActionListContext';
import EngagementActionInitiativeButton from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-initiative/packages/engagement-action-initiative-button/EngagementActionInitiativeButton';
import EngagementActionInitiativeLinkButton from '~/wm/packages/organization/packages/engagement/packages/engagement-action/packages/engagement-action-initiative/packages/engagement-action-initiative-link-button/EngagementActionInitiativeLinkButton';
import { InitiativeCreateSource } from '~/wm/packages/strategy/model/strategyDataCollectionSources';
import useFeatureFlagProvider from '~/router/feature-flag-provider/hooks/useFeatureFlagProvider';
import { Styleable } from '~/neo-ui/model/capacity';

export type EngagementActionCardProps = {
  isInitiative?: boolean;
  action: EngagementActionDto;
  initiativeCreatedFrom: InitiativeCreateSource;
} & Styleable;

const EngagementActionDashboardCard = ({ isInitiative = false, action, initiativeCreatedFrom, className }: EngagementActionCardProps) => {
  const { 'lm-action-item-link-initiative': enabledInitiativeActionItem } = useFeatureFlagProvider();
  const { actionCreateAnimationCache, completeCreateAnimation } = useEngagementActionListContext();
  const { isOrganizationRegisteredForTickets, hasTicketIntegration, ticketFieldOptions } = useTicketFieldAvailabilitiesContext();
  const [scope, animate] = useAnimate();
  const { pin, isPinning } = useEngagementActionPin();
  const { unpin, isUnpinning } = useEngagementActionUnpin();
  const { markCompleted, isMarkingCompleted } = useEngagementActionMarkCompleted();
  const { markIncomplete, isMarkingIncomplete } = useEngagementActionMarkIncomplete();

  const [isEditing, setEditing] = useState(false);

  // Transition time for the action item to leave in milliseconds
  const transitionDuration = 0.75;
  const [isTransitioning, setIsTransitioning] = useState(false);

  const boxClassname = 'action-item-box';
  const getDueDateLabel = (dueDate: Date) => {
    const dueDateDefinition = getDateFriendlyExtendedDefinition(dueDate, action.isCompleted);

    return (
      <Label
        css={css`
          white-space: nowrap;
        `}
        color={dueDateDefinition.color}
      >
        {dueDateDefinition.display}
      </Label>
    );
  };

  // Complete/incomplete animation
  useEffect(() => {
    (async () => {
      if (isTransitioning && !action.isCompleted) {
        await animate(
          scope.current,
          {
            x: '100%',
            opacity: 0,
            backgroundColor: colorToCode('positive-200'),
          },
          {
            type: 'tween',
            duration: transitionDuration,
          },
        );
        markCompleted(action.engagementActionId);
      }

      if (isTransitioning && action.isCompleted) {
        await animate(
          scope.current,
          {
            x: '-100%',
            opacity: 0,
            backgroundColor: colorToCode('primary-200'),
          },
          {
            type: 'tween',
            duration: transitionDuration,
          },
        );
        markIncomplete(action.engagementActionId);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTransitioning]);

  // Create animation
  useEffect(() => {
    (async () => {
      const createAnimation = actionCreateAnimationCache.find(createAnimation => createAnimation.actionId === action.engagementActionId);
      if (typeof createAnimation !== 'undefined' && !createAnimation.hasAnimated) {
        const animationBorderColorInitial = colorToCode('primary-400');
        const animationBorderColorEnd = colorToCode('light-000');
        const animationBackgroundColorInitial = colorToCode('primary-050');
        const animationBackgroundColorEnd = colorToCode('light-000');

        await animate(
          scope.current,
          {
            y: ['-100%', '0%', '0%', '0%'],
            border: `1px solid`,
            borderColor: [animationBorderColorInitial, animationBorderColorInitial, animationBorderColorEnd, animationBorderColorEnd],
            backgroundColor: [
              animationBackgroundColorInitial,
              animationBackgroundColorInitial,
              animationBackgroundColorEnd,
              animationBackgroundColorEnd,
            ],
          },
          {
            type: 'tween',
            duration: transitionDuration,
          },
        );
        completeCreateAnimation(action.engagementActionId);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Box
        ref={scope}
        boxShadow={'shadow100'}
        padding={'padding100'}
        borderRadius={'radius400'}
        onClick={() => {
          setEditing(true);
        }}
        className={`${className} ${boxClassname}`}
        css={css`
          display: grid;
          grid-template-rows: 0 1fr 0;

          transition: grid-template-rows 200ms ease-out;

          // 6f2e61e2-7079-4094-96a6-dd5d9f635f25
          cursor: pointer;

          &:hover {
            box-shadow: ${boxShadowToCode('shadow200')};
            // Setting row heights allow rows to animate height on hover
            grid-template-rows: 1.25rem 1fr ${action.assignedUsers.length === 0 ? '0' : '2rem'};
          }
        `}
      >
        <div
          css={css`
            overflow: hidden;

            display: flex;
            align-items: center;
          `}
        >
          <Header
            css={css`
              margin-left: auto;
            `}
            size={7}
            weight={'bold'}
            color={'light-900'}
          >
            CREATED ON:{' '}
            {formatDate(new Date(parseISO(action.createdAt)), {
              format: 'dd/MM/yyyy',
              timezone: TimezoneFormat.Local,
            })}
          </Header>
        </div>
        <div
          css={css`
            display: flex;
            gap: 1rem;
            align-items: center;
          `}
        >
          <Tooltip content={action.isCompleted ? 'Mark as incomplete' : 'Mark as complete'}>
            <Button
              size={'xs'}
              iconLeft={'Done'}
              theme={action.isCompleted ? 'positive' : undefined}
              preventOnClickPropagation={true}
              disabled={isMarkingCompleted || isMarkingIncomplete || isPinning || isUnpinning}
              loading={isMarkingCompleted || isMarkingIncomplete}
              onClick={() => {
                setIsTransitioning(true);
              }}
            />
          </Tooltip>
          <Label
            css={css`
              .${boxClassname}:hover & {
                -webkit-line-clamp: 4;
              }

              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            `}
            color={'dark-050'}
          >
            {action.description}
          </Label>
          <div
            css={css`
              display: flex;
              gap: 0.5rem;
              align-items: center;
              margin-left: auto;
            `}
          >
            {typeof action.dueAt !== 'undefined' && getDueDateLabel(new Date(action.dueAt))}
            <Button
              size={'xs'}
              iconLeft={'Pin'}
              theme={action.isPinned ? 'primary' : undefined}
              preventOnClickPropagation={true}
              disabled={isMarkingCompleted || isMarkingIncomplete || isPinning || isUnpinning}
              loading={isPinning || isUnpinning}
              onClick={() => (action.isPinned ? unpin(action.engagementActionId) : pin(action.engagementActionId))}
              css={[
                tooltipStyles(action.isPinned ? 'Unpin action' : 'Pin action'),
                css`
                  .${boxClassname}:hover & {
                    display: unset;
                  }

                  display: ${action.isPinned ? 'unset' : 'none'};
                `,
              ]}
            />
            {typeof action.ticketLinkState === 'undefined' ? (
              <EngagementActionTicketCreateButton
                size={'xs'}
                hasTicketIntegration={hasTicketIntegration}
                isOrganizationRegisteredForTickets={isOrganizationRegisteredForTickets}
                action={action}
                fieldOptions={ticketFieldOptions}
                css={css`
                  .${boxClassname}:hover & {
                    display: unset;
                  }

                  display: none;
                `}
              />
            ) : (
              <EngagementActionTicketButton
                size={'xs'}
                ticketLinkState={action.ticketLinkState}
              />
            )}
            {enabledInitiativeActionItem &&
              !isInitiative &&
              (typeof action.initiativeId === 'undefined' ? (
                <EngagementActionInitiativeLinkButton
                  size={'xs'}
                  action={action}
                  initiativeCreatedFrom={initiativeCreatedFrom}
                  css={css`
                    .${boxClassname}:hover & {
                      display: unset;
                    }

                    display: none;
                  `}
                />
              ) : (
                <EngagementActionInitiativeButton
                  size={'xs'}
                  initiativeId={action.initiativeId}
                />
              ))}
          </div>
        </div>
        <div
          css={css`
            overflow: hidden;

            display: flex;
            align-items: center;
            gap: 0.5rem;
            flex-wrap: wrap;
          `}
        >
          {action.assignedUsers.slice(0, 3).map(user => {
            const userTheme = calculateUserTheme(user.id);

            return (
              <Badge
                key={user.id}
                bgColor={userTheme.backgroundColorRest}
                textColor={userTheme.textColorRest}
                fontWeight={'400'}
                borderRadius={'radius425'}
              >
                {formatUserNameDisplay(user)}
              </Badge>
            );
          })}
          {action.assignedUsers.length > 3 && <Label color={'dark-700'}>+ {action.assignedUsers.length - 3}</Label>}
        </div>
      </Box>
      {isEditing && (
        <EngagementActionEditForm action={action}>
          <EngagementActionEditWindow
            action={action}
            ticketFieldOptions={ticketFieldOptions}
            isOpen={isEditing}
            isOrganizationRegisteredForTickets={isOrganizationRegisteredForTickets}
            hasTicketIntegration={hasTicketIntegration}
            onDismiss={() => {
              setEditing(false);
            }}
            initiativeCreatedFrom={initiativeCreatedFrom}
          />
        </EngagementActionEditForm>
      )}
    </Fragment>
  );
};

export default EngagementActionDashboardCard;
