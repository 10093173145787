import { useState, useEffect, useCallback } from 'react';
import useNotificationList, { NotificationListModel } from '~/wm/packages/notification/hooks/useNotificationList';
import usePolling from '~/extensions/packages/polling/hooks/usePolling';
import useApi from '~/wm/packages/api/hook/useApi';
import { notificationInAppMarkAsRead } from '@ActivityClient/ActivityClientMsp.gen';
import { produce } from 'immer';
import useToastContext from '~/neo-ui/packages/toast/context/hooks/useToastContext';

export type UseNotificationCenter = {
  notifications: NotificationListModel;
  markAsRead: (notificationId: string) => void;
};

const useNotificationCenter = (): UseNotificationCenter => {
  const { addToast, removeToast } = useToastContext();
  const { notifications, mutateNotifications, reload } = useNotificationList();

  const { callApi } = useApi();

  // latest sentAt time received
  const [lastUpdatedAt, setLastUpdatedAt] = useState<Date>(new Date());

  // Check if new notifications have been received, and if so add them as toasts
  useEffect(() => {
    if (!notifications.count) {
      return;
    }

    for (const notification of [...notifications.items].reverse()) {
      if (lastUpdatedAt < notification.sentAt) {
        addToast({
          toastUniqueId: notification.notificationUniqueId,
          toastType: 'notification',
          toastContent: {
            title: notification.content.title,
            description: notification.content.description,
            icon: notification.content.icon,
            buttonLeft: {
              children: notification.content.callToAction.label,
              anchor: { href: notification.content.callToAction.url },
              iconRight: 'GoRight',
            },
            buttonRight: {
              children: 'Mark read',
              iconRight: 'Success',
              onClick: () => {
                markAsRead(notification.notificationUniqueId);
                removeToast(notification.notificationUniqueId);
              },
            },
          },
        });
      }
    }

    setLastUpdatedAt(notifications.items[0].sentAt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  // Poll for latest notifications
  usePolling(reload, 5000);

  const markAsRead = useCallback(
    (notificationId: string) => {
      const setIsRead = (isRead: boolean) =>
        mutateNotifications(notifications =>
          produce(notifications, draft => {
            const targetNotification = draft.find(notification => notification.notificationUniqueId === notificationId);
            if (!targetNotification) {
              // Doesn't exist anymore, do nothing
              return;
            }
            targetNotification.isRead = isRead;
          }),
        );

      // Optimistically update the state, marking the given notification as read
      setIsRead(true);

      // Make the API call to mark as read
      (async () => {
        const response = await callApi(() => notificationInAppMarkAsRead({ notificationId }));
        if (!response) {
          // Call failed - rollback the update
          setIsRead(false);
          return;
        }
      })();
    },
    [callApi, mutateNotifications],
  );

  return {
    notifications,
    markAsRead,
  };
};

export default useNotificationCenter;
