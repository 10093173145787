import * as React from 'react';
import { InitiativeOverviewDashboardDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Dto/Model.gen';
import useApi from '~/wm/packages/api/hook/useApi';
import { initiativeOverviewDashboardListGet } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import {
  InitiativeStatusCountDto,
  InitiativeStatusDto,
} from '@AssetManagementClient/BeastClient/Beast/Initiative/Packages/InitiativeStatus/Dto/Model.gen';
import InitiativeDashboardContext from '~/wm/packages/strategy/packages/strategy-dashboard/packages/initiative-dashboard/context/InitiativeDashboardContext';
import InitiativeDashboardMutationContext from '~/wm/packages/strategy/packages/strategy-dashboard/packages/initiative-dashboard/context/InitiativeDashboardMutationContext';
import usePolling from '~/extensions/packages/polling/hooks/usePolling';
import { useState } from 'react';

export type InitiativeDashboardProvider = {
  organizationId: string;
};

const InitiativeDashboardProvider: React.FunctionComponent<React.PropsWithChildren<InitiativeDashboardProvider>> = ({
  organizationId,
  children,
}) => {
  const [initiatives, setInitiatives] = React.useState<InitiativeOverviewDashboardDto[]>();
  const [statusAvailabilities, setStatusAvailabilities] = React.useState<InitiativeStatusDto[]>();
  const [statusCounts, setStatusCount] = React.useState<InitiativeStatusCountDto[]>();
  const [selectedFilterStatus, setFilterStatus] = React.useState<InitiativeStatusDto>();
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [hasNextPage, setHasNextPage] = React.useState<boolean>(false);
  const [hasPreviousPage, setHasPreviousPage] = React.useState<boolean>(false);
  const [shouldPoll, setShouldPoll] = useState(false);

  const { callApi } = useApi();

  const reload = React.useCallback(async () => {
    await (async () => {
      const response = await callApi(() =>
        initiativeOverviewDashboardListGet({
          organizationId,
          paginationPayload: {
            pageNumber: currentPage,
            filterStatus: selectedFilterStatus?.id,
          },
        }),
      );
      if (!response) {
        return;
      }

      setInitiatives(response.initiatives);
      setStatusAvailabilities(response.statusAvailabilities);
      setStatusCount(response.statusCounts);
      setHasNextPage(response.hasNextPage);
      setShouldPoll(response.hasPendingTicketCreation);

      /// TODO: LM-1188: Remove this undefined check once the new beast backend is deployed to prod
      if (typeof response.hasPreviousPage !== 'undefined') {
        setHasPreviousPage(response.hasPreviousPage);
      }
    })();
  }, [callApi, currentPage, organizationId, selectedFilterStatus]);

  React.useEffect(() => {
    reload();
  }, [reload]);

  usePolling(() => reload(), 3000, shouldPoll);

  const onFilterStatusUpdate = React.useCallback(
    async (filterStatus: string | undefined) => {
      setFilterStatus(statusAvailabilities?.find(status => status.id === filterStatus));

      setCurrentPage(1);
    },
    [statusAvailabilities],
  );

  const onPageUpdate = React.useCallback(async (pageNumber: number) => {
    setCurrentPage(pageNumber);
  }, []);

  if (typeof initiatives === 'undefined' || typeof statusAvailabilities === 'undefined' || typeof statusCounts === 'undefined') {
    return null;
  }

  return (
    <InitiativeDashboardMutationContext.Provider
      value={{
        triggerInitiativeDashboardReload: () => reload(),
      }}
    >
      <InitiativeDashboardContext.Provider
        value={{
          initiatives,
          statusAvailabilities,
          statusCounts,
          selectedFilterStatus,
          currentPage,
          onFilterStatusUpdate,
          onPageUpdate,
          hasNextPage,
          hasPreviousPage,
        }}
      >
        {children}
      </InitiativeDashboardContext.Provider>
    </InitiativeDashboardMutationContext.Provider>
  );
};

export default InitiativeDashboardProvider;
