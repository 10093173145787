import { ActiveUser } from '@SsoClient/BeastClient/Beast/Identity/Model/User.gen';
import { EngagementActionAssignedUserDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Engagement/Packages/EngagementAction/Packages/EngagementActionUserAssignment/Dto/Model.gen';
import { ActiveUserSearchOption } from '~/wm/packages/account/packages/user/packages/active-users-search/ActiveUsersSearch';
import { OrganizationAccountTeamMemberDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/OrganizationAccountTeamMember/Dto/Model.gen';

type UserType = EngagementActionAssignedUserDto | ActiveUser | ActiveUserSearchOption | OrganizationAccountTeamMemberDto;

export type FormatUserNameDisplay = (assignedUser: UserType) => string;

const isUndefinedOrWhitespace = (value: string | undefined) => typeof value === 'undefined' || typeof value.match(/^ *$/) !== 'undefined';
const isMissingName = (user: UserType) => isUndefinedOrWhitespace(user.firstName) || isUndefinedOrWhitespace(user.lastName);

const formatUserNameDisplay: FormatUserNameDisplay = assignedUser =>
  isMissingName(assignedUser)
    ? `${typeof assignedUser.email !== 'undefined' ? assignedUser.email.split('@')[0] : ''}`
    : `${assignedUser.firstName} ${assignedUser.lastName}`;

export default formatUserNameDisplay;
