// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.


export enum SyncStatus
{
  Syncing = "Syncing",
  Disabled = "Disabled",
  Failed = "Failed",
  Succeeded = "Succeeded",
}


export declare namespace SyncStatusDtoNested {


  export interface Syncing
  {

    status: SyncStatus.Syncing;

    icon: string;
    syncLastCompleted: string | undefined;
  }

  export interface Disabled
  {

    status: SyncStatus.Disabled;

    icon: string;
    syncLastFailed: string;
    authErrorCount: number;
  }

  export interface Failed
  {

    status: SyncStatus.Failed;

    icon: string;
    syncLastFailed: string;
  }

  export interface Succeeded
  {

    status: SyncStatus.Succeeded;

    icon: string;
    syncLastCompleted: string;
  }
}


export type SyncStatusDto =
    SyncStatusDtoNested.Syncing
  | SyncStatusDtoNested.Disabled
  | SyncStatusDtoNested.Failed
  | SyncStatusDtoNested.Succeeded;

