import DataTable, { DataTableColumn, DataTableProps } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import useItemSelection from '~/wm/packages/strategy/packages/initiative/packages/initiative-asset/hooks/useItemSelection';
import { useEffect, useMemo } from 'react';
import ToggleItem from '~/neo-ui/packages/toggle/packages/toggle-item/ToggleItem';
import SelectableCheckboxHeader from '~/neo-ui/packages/table/packages/table-selectable/packages/selectable-checkbox-header/SelectableCheckboxHeader';
import SelectableCheckbox from '~/neo-ui/packages/table/packages/table-selectable/packages/selectable-checkbox/SelectableCheckbox';
import { Styleable } from '~/neo-ui/model/capacity';

export type TableSelectableRowProps = {
  selectId: string;
  isSelectable?: boolean;
};

/**
 * Maps an id from T to a TableSelectableRow's selectId prop
 * @param row
 * @param idKey
 */
const mapIdToTableSelectableRow = <TData, K extends keyof TData>(row: TData, idKey: K): TData & TableSelectableRowProps => ({
  ...row,
  selectId: row[idKey] as string, // typescript cannot infer the type
});

export type TableSelectableProps<TData extends object, K extends keyof TData, TSelectableData extends TableSelectableRowProps> = {
  /**
   * Props for the underlying data table, data and columns are provided separately
   */
  dataTableProps: Omit<DataTableProps<TSelectableData>, 'data' | 'columns'>;
  /**
   * Data to be displayed in the table
   */
  data: TData[];
  /**
   * Key to be used to select the id from the data
   */
  dataKey: K;
  /**
   * Columns to be displayed in the table
   */
  columns: DataTableColumn<TData>[];

  /**
   * Ids that are selected in the parent
   *
   * Only to be defined if a global clear is implemented
   */
  selectedIds?: Set<string>;
  /**
   * Update selected ids (all or nothing update)
   * @param selectedIds Override selected ids with new set
   */
  updateSelectedIds: (selectedIds: Set<string>) => void;
} & Styleable;

/**
 * A data table that allows for selecting rows
 *
 * Maintains a local set of selected ids and pushes its updates to the parent
 */
const TableSelectable = <TData extends object, K extends keyof TData, TSelectableData extends TableSelectableRowProps>({
  dataTableProps,
  data,
  dataKey,
  columns,
  selectedIds,
  updateSelectedIds,
  className,
}: TableSelectableProps<TData, K, TSelectableData>) => {
  const selectableData = useMemo(
    () => data.map(item => mapIdToTableSelectableRow(item, dataKey)),
    [data, dataKey],
  ) as unknown as TSelectableData[];

  const { selectedItems, lastItem, selectItem, deselectItem, selectItems, deselectItems, clearSelectedItems, isItemSelected } =
    useItemSelection<string>(selectedIds);

  // Preserve existing selected ids on pagination
  useEffect(() => {
    selectItems(Array.from(selectedItems), undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update global ids when selected ids for the component changes
  useEffect(
    () => updateSelectedIds(selectedItems),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedItems],
  );

  // Trigger clear selected ids when global ids are cleared
  useEffect(() => {
    if (typeof selectedIds !== 'undefined' && selectedIds.size === 0 && selectedItems.size > 0) {
      clearSelectedItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIds?.size]);

  const idsFromData = useMemo(() => selectableData.map(row => row.selectId), [selectableData]);

  const selectableColumns: DataTableColumn<TSelectableData>[] = useMemo(
    () => [
      {
        fieldKey: row => row.selectId,
        Header:
          data.length === 0 ? (
            <ToggleItem
              checked={false}
              disabled={true}
            />
          ) : (
            <SelectableCheckboxHeader
              ids={idsFromData}
              selectedIds={selectedItems}
              onSelectIds={selectItems}
              onDeselectIds={deselectItems}
            />
          ),
        renderCell: row => (
          <SelectableCheckbox
            key={`row-${row.selectId}`}
            id={row.selectId}
            ids={idsFromData}
            lastId={lastItem}
            onDeselectIds={deselectItems}
            onSelectId={selectItem}
            onSelectIds={selectItems}
            onDeselectId={deselectItem}
            isSelected={isItemSelected(row.selectId)}
            disabled={row.isSelectable === false}
          />
        ),
      },
      ...(columns as unknown as DataTableColumn<TSelectableData>[]),
    ],
    [columns, data.length, deselectItem, deselectItems, idsFromData, isItemSelected, lastItem, selectItem, selectItems, selectedItems],
  );

  return (
    <DataTable
      {...dataTableProps}
      className={className}
      columns={selectableColumns}
      data={selectableData}
    />
  );
};

export default TableSelectable;
