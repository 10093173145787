import { activityAlerts, activityUserInteract } from '@ActivityClient/ActivityClientMsp.gen';
import { EmptyRequest } from '@ActivityClient/ApiServer/Packages/AspNetCore/Controller/Request.gen';
import { ActivityUserInteractionEnum } from '@ActivityClient/BeastClient/Beast/Activity/Client/Packages/User/Interaction/Model.gen';
import { ActivitySearchResult } from '@ActivityClient/BeastClient/Activity/Search/Model/Query.gen';
import { useState, useEffect } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';

export const useActivitySummary = () => {
  const [alertEntries, setAlertEntries] = useState<ActivitySearchResult[] | undefined>(undefined);
  const { callApi } = useApi();

  const dismissSummaryItem = async (id: string) => {
    setAlertEntries(alertEntries => alertEntries?.filter(i => i.id !== id));
    await callApi(() =>
      activityUserInteract({
        activityEntryId: { value: id },
        interaction: ActivityUserInteractionEnum.Dismissed,
      }),
    );
  };

  useEffect(() => {
    (async () => {
      const query: EmptyRequest = {};
      const response = await callApi(() => activityAlerts(query));
      if (!response) {
        return;
      }
      setAlertEntries(response.alerts);
    })();
  }, [callApi]);

  return { alertEntries, dismissSummaryItem };
};
