import { css } from '@emotion/react';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import Card from '~/legacy-ui/packages/card/Card';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import { prerequisiteToneToDisplayDefinition } from '~/wm/packages/strategy/packages/strategy-dashboard/packages/insight-dashboard/prerequisiteDisplay';
import { InsightStateDtoNested } from '@AssetManagementClient/AssetManagement/Packages/Strategy/Packages/Insights/Model.gen';
import Icon from '~/neo-ui/packages/icon/Icon';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type StrategyInsightDashboardPrerequisiteProps = {
  insightTitle: string;
  insightDescription: string;
  prerequisiteState: InsightStateDtoNested.Prerequisite;
} & Styleable;

const StrategyInsightDashboardPrerequisite: React.FunctionComponent<StrategyInsightDashboardPrerequisiteProps> = ({
  insightTitle,
  insightDescription,
  className,
  prerequisiteState,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <Card
      shadow="sm"
      className={className}
      padding={'sm'}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      backgroundColor={isHovered ? (prerequisiteState.backgroundColorHover as Color) : 'light-100'}
      css={css`
        width: 12.5rem;
        height: 12.5rem;
      `}
      onClick={() => {
        window.location.href = prerequisiteState.reason.actionUrl;
      }}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
        `}
      >
        <div
          css={css`
            height: 100%;
          `}
        >
          {/* Insight title  */}
          <Header
            size={5}
            color={prerequisiteState.foregroundColor as Color}
            css={css`
              margin-top: 0.3125rem;
              margin-bottom: 0.625rem;
            `}
          >
            {insightTitle}
          </Header>

          {/* Prerequisite reason title */}
          {
            <Label
              size={'md'}
              color={prerequisiteToneToDisplayDefinition(prerequisiteState.reason.prerequisiteTone)}
              css={css`
                  line-height: 0.875rem; !important;
                  margin-bottom: 0.313rem;
                `}
            >
              {prerequisiteState.reason.title}
            </Label>
          }

          {/* Body text */}
          <Label
            size={'sm'}
            css={css`
              line-height: 0.875rem;
              color: ${colorToCode('dark-900-32')};
            `}
          >
            {isHovered ? insightDescription : prerequisiteState.reason.description}
          </Label>

          {/* Action text */}
          <div
            css={css`
              padding: 0.313rem;
              position: absolute;
              bottom: 0.625rem;
              visibility: ${isHovered ? 'visible' : 'hidden'};
              display: flex;
              align-items: center;
            `}
          >
            <Label
              size={'md'}
              color={'primary-400'}
            >
              {prerequisiteState.reason.actionDescription}
            </Label>
            <Icon
              icon={'GoRight'}
              color="primary-400"
              css={css`
                margin-left: 0.313rem;
              `}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default StrategyInsightDashboardPrerequisite;
