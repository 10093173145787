import EnceladusTitlebar from '~/neo-ui/packages/layout/packages/layout-enceladus/packages/enceladus-titlebar/EnceladusTitlebar';
import { css } from '@emotion/react';
import EnceladusNavigationVertical from '~/neo-ui/packages/layout/packages/layout-enceladus/packages/enceladus-navigation/packages/enceladus-navigation-vertical/EnceladusNavigationVertical';
import EnceladusIframeRoute from '~/neo-ui/packages/layout/packages/layout-enceladus/packages/enceladus-iframe-route/EnceladusIframeRoute';
import AlertHook from '~/legacy-ui/packages/alert/state/AlertHook';
import RootState from '~/wm/state/RootState';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import useRouteDefinitionGroups, { RouteDefinitionGroupsType } from '~/router/hooks/useRouteDefinitionGroups';
import ModalHook from '~/legacy-ui/packages/modal/packages/alert-modal/state/ModalHook';

export type EnceladusBodyProps = {
  isIframeRoute?: boolean;
  children: React.ReactNode;
  showTitlebar?: boolean;
  routeGroupType?: RouteDefinitionGroupsType;
};

export const enceladusMainBodyPaddingRem = 1.875;

const EnceladusBody = ({ isIframeRoute = false, showTitlebar = true, routeGroupType, children }: EnceladusBodyProps) => {
  const routeGroups = useRouteDefinitionGroups(routeGroupType);

  return (
    <div
      css={css`
        // When scrolling a long page a stray scrollbar appears. This hides it without overflow wonkiness
        contain: paint;

        height: calc(100vh - var(--app-bar-height, 0) - 2.875rem);
        background-color: ${colorToCode('light-000')};
      `}
    >
      {showTitlebar && <EnceladusTitlebar />}
      <div
        css={css`
          height: calc(100vh - var(--app-bar-height, 0) - 2.875rem - ${showTitlebar ? '3.625rem' : '0rem'});
          display: flex;
        `}
      >
        {routeGroups && <EnceladusNavigationVertical routeGroups={routeGroups} />}
        <main
          id={'enceladus-main-body'}
          css={css`
            flex-grow: 1;
            overflow-x: hidden;
            display: flex;
            flex-direction: column;
            background-color: ${colorToCode('light-200')};

            padding: ${enceladusMainBodyPaddingRem}rem;

            border-width: 0.0625rem 0 0 0.0625rem;
            border-style: solid;
            border-color: ${colorToCode('dark-900-24')};
            border-start-start-radius: 0.625rem;
          `}
        >
          {AlertHook({
            alertStateSelector: (state: RootState) => state.ui.alert,
          })}
          {ModalHook({ modalStateSelector: (state: RootState) => state.ui.modal })}
          {isIframeRoute ? <EnceladusIframeRoute /> : children}
        </main>
      </div>
    </div>
  );
};

export default EnceladusBody;
