import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import { Enum } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Insights/Model/Dashboard/PresentationalInsightStateNested.gen';
import { InsightStateDto } from '@AssetManagementClient/AssetManagement/Packages/Strategy/Packages/Insights/Model.gen';
import StrategyInsightDashboardInsightSmall from '~/wm/packages/strategy/packages/strategy-dashboard/packages/insight-dashboard/insight-dashboard-item/small-tile/insight/StrategyInsightDashboardInsightSmall';
import StrategyInsightDashboardPrerequisiteSmall from '~/wm/packages/strategy/packages/strategy-dashboard/packages/insight-dashboard/insight-dashboard-item/small-tile/prerequisite/StrategyInsightDashboardPrerequisiteSmall';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';

export type StrategyInsightDashboardItemSmallProps = {
  insightTitle: string;
  insightDescription?: string;
  insightCount: number;
  insightUrl: string;
  insightState: InsightStateDto;
  insightTrend?: number;
  insightForecast?: number;
  canFullyAccessInsights: boolean;
  frontendScope: FrontendScope;
} & Styleable;

const StrategyInsightDashboardItemSmall: React.FunctionComponent<StrategyInsightDashboardItemSmallProps> = ({
  insightTitle,
  insightDescription,
  insightCount,
  insightUrl,
  insightState,
  insightTrend,
  insightForecast,
  canFullyAccessInsights,
  frontendScope,
}) =>
  insightState.type !== Enum.Prerequisite ? (
    <StrategyInsightDashboardInsightSmall
      insightTitle={insightTitle}
      insightDescription={insightDescription}
      insightCount={insightCount}
      insightUrl={insightUrl}
      insightState={insightState}
      insightTrend={insightTrend}
      insightForecast={insightForecast}
      canFullyAccessInsights={canFullyAccessInsights}
      frontendScope={frontendScope}
    />
  ) : (
    <StrategyInsightDashboardPrerequisiteSmall
      insightTitle={insightTitle}
      insightDescription={insightDescription}
      prerequisiteState={insightState}
    />
  );
export default StrategyInsightDashboardItemSmall;
