// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.
import { FqdnValidationDetails as AssetManagementClientBeastClientSyncGodSharedApiHandlerCredentialInputTypeNestedValidationDetailsNestedFqdnValidationDetails } from "@AssetManagementClient/BeastClient/SyncGod/Shared/ApiHandler/Credential/InputTypeNested/ValidationDetailsNested.gen"


export enum Enum
{
  None = "None",
  Toggle = "Toggle",
  Text = "Text",
  TextSecure = "TextSecure",
  Select = "Select",
  InteractiveOAuth = "InteractiveOAuth",
}



export interface SanitationDetails
{
  /**
   * Whether to perform fqdn sanitation such as trimming http(s) etc
   */
  useFqdnSanitation: boolean;
  /**
   * Whether to strip whitespace from the input
   */
  useStripWhitespace: boolean;
  /**
   * Whether to strip symbols from the input
   */
  useStripSymbols: boolean;
}


/**
 * Details required for generic validation on an input type
 */
export interface ValidationDetails
{
  isRequired: boolean;
  /**
   * Specify if this should have FQDN validation checks
   */
  fqdnValidation: AssetManagementClientBeastClientSyncGodSharedApiHandlerCredentialInputTypeNestedValidationDetailsNestedFqdnValidationDetails | undefined;
  /**
   * Minimum length of value (inclusive)
   */
  minLength: number | undefined;
  /**
   * Maximum length of value (exclusive)
   */
  maxLength: number | undefined;
}

