export type CurrencyCode = string;

export type Currency = {
  code: CurrencyCode;

  /**
   * How many subunits exist for one unit
   *
   * e.g., 100 million satoshis per bitcoin
   * e.g., 100 cents per US dollar
   */
  subunitsPerUnit: number;
};

const currencies: Currency[] = [{ code: 'USD', subunitsPerUnit: 100 }];

const currenciesByCode = new Map<CurrencyCode, Currency>(currencies.map(currency => [currency.code, currency]));

/**
 * Only supporting USD for now.
 * @param amountInSubunits How many subunits comprise this amount. E.g., for USD, 500 subunits = 500 cents = $5
 * @param currencyCode
 * @param fractionDigits Number of decimal places to show.
 */
const formatCurrency = (amountInSubunits: number, currencyCode: CurrencyCode = 'USD', fractionDigits?: number): string => {
  // If this is updated, please add corresponding tests.
  const currency = currenciesByCode.get(currencyCode)!;

  return formatCurrencyByCurrencyInfo(
    amountInSubunits,
    {
      code: currencyCode,
      subunitsPerUnit: currency.subunitsPerUnit,
    },
    fractionDigits,
  );
};

export const formatCurrencyByCurrencyInfo = (amountInSubunits: number, currency: Currency, fractionDigits?: number): string => {
  if (currency.subunitsPerUnit === 0) {
    throw new Error('Currency subunits per unit cannot be 0');
  }

  const amountInUnits = amountInSubunits / currency.subunitsPerUnit;
  const minimumFractionDigits =
    fractionDigits ??
    // If it's a fractional amount, always show 2 decimals
    amountInUnits % 1 !== 0
      ? 2
      : 0;

  return new Intl.NumberFormat('en-US', createCurrencyNumberFormatOptions(currency.code, 'narrowSymbol', minimumFractionDigits)).format(
    amountInUnits,
  );
};

/**
 * Get the currency character of a currency code (eg. USD to $)
 */
export const getCurrencyCharacterByCurrencyCode = (currencyCode: CurrencyCode): string =>
  new Intl.NumberFormat('en-US', createCurrencyNumberFormatOptions(currencyCode, 'narrowSymbol', 0))
    .formatToParts(0)
    .filter(part => part.type === 'currency')
    .map(part => part.value)
    .join('');

const createCurrencyNumberFormatOptions: (
  currency: string,
  currencyDisplay?: 'narrowSymbol' | 'symbol' | 'code' | 'name',
  minimumFractionDigits?: number,
) => Intl.NumberFormatOptions = (currency, currencyDisplay = 'narrowSymbol', minimumFractionDigits) => ({
  style: 'currency',
  ...{
    currency,
    currencyDisplay,
    minimumFractionDigits,
  },
});

export default formatCurrency;
