import { ActivitySearchResult } from '@ActivityClient/BeastClient/Activity/Search/Model/Query.gen';
import { css } from '@emotion/react';
import * as React from 'react';
import SummaryModal from '~/legacy-ui/packages/modal/packages/summary-modal/SummaryModal';
import Icon from '~/neo-ui/packages/icon/Icon';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import LayoutSection from '~/neo-ui/packages/text/packages/layout-section/LayoutSection';
import Preformatted from '~/neo-ui/packages/text/packages/preformatted/Preformatted';
import ActivityLogEntryHeader from '~/wm/packages/activity/packages/activity-log/packages/activity-log-entry/packages/activity-log-entry-header/ActivityLogEntryHeader';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type ActivityLogEntryModalProps = {
  entry: ActivitySearchResult;
  isOpen: boolean;
  onDismiss: () => void;
  disableCallToAction?: boolean;
};

const ActivityLogEntryModal: React.FunctionComponent<ActivityLogEntryModalProps> = ({
  entry,
  isOpen,
  onDismiss,
  disableCallToAction = false,
}) => (
  <SummaryModal
    header={<ActivityLogEntryHeader entry={entry} />}
    isOpen={isOpen}
    onDismiss={onDismiss}
    callToAction={
      disableCallToAction
        ? undefined
        : {
            label: entry.contentInfo.callToAction.label,
            url: entry.contentInfo.callToAction.url,
            openInNewTab: false,
          }
    }
  >
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <Icon
        icon={entry.metaInfo.icon as IconType}
        color={'secondary-400'}
        sizePx={70}
        css={css`
          margin-bottom: 1.67rem;
        `}
      />
      <Header
        size={2}
        color={'secondary-400'}
        css={css`
          text-align: center;
        `}
      >
        {entry.contentInfo.title}
      </Header>
    </div>
    <div
      css={css`
        margin-top: 1.875rem;
      `}
    >
      <LayoutSection
        title={entry.contentInfo.details}
        titleSize={4}
      >
        <Label
          size={'md'}
          css={css`
            color: ${colorToCode('dark-300')};
            line-height: 1.5625rem;
          `}
        >
          <Preformatted text={entry.contentInfo.longDescription} />
        </Label>
      </LayoutSection>
    </div>
  </SummaryModal>
);

export default ActivityLogEntryModal;
