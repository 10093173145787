import { useContext } from 'react';
import UserContext from '~/wm/context/UserContext';

const useUserContext = () => {
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new Error('Tried to use UserContext outside of an UserProvider');
  }

  return userContext;
};

export default useUserContext;
