import * as React from 'react';
import FeatureAccessInfoContext from './FeatureAccessInfoContext';
import useFeatureAccessInfoGet, {
  FeatureAccessAvailabilities,
} from '~/wm/packages/feature/packages/feature-access-banner/hooks/useFeatureAccessInfoGet';
import { CaseEnum as FeatureAccessTypeEnum } from '@AssetManagementClient/BeastClient/Beast/Feature/Packages/Access/Models/Dto/FeatureAccessDtoNested.gen';

export type FeatureKey = 'scorecard-roadmap' | 'client-focus' | 'enceladus';

/**
 * Check if the feature is available across the entire account
 */
export const hasFeatureAccessAccount = (
  featureKey: FeatureKey,
  featureAccessAvailabilities: FeatureAccessAvailabilities | undefined,
): boolean => {
  const featureAccess = typeof featureAccessAvailabilities !== 'undefined' ? featureAccessAvailabilities[featureKey] : undefined;

  // Available across account
  return typeof featureAccess !== 'undefined' && featureAccess.isFeatureReleased && featureAccess.enum === FeatureAccessTypeEnum.FullAccess;
};

/**
 * Check if the feature is available to the organization or the entire account
 * @param featureKey
 * @param organizationId
 * @param featureAccessAvailabilities
 */
export const hasFeatureAccessOrganization = (
  featureKey: FeatureKey,
  organizationId: string,
  featureAccessAvailabilities: FeatureAccessAvailabilities | undefined,
): boolean => {
  if (typeof featureAccessAvailabilities === 'undefined') {
    return false;
  }

  if (hasFeatureAccessAccount(featureKey, featureAccessAvailabilities)) {
    return true;
  }

  const featureAccess = featureAccessAvailabilities[featureKey];

  // Available to a specific organization
  return (
    typeof featureAccess !== 'undefined' &&
    featureAccess.isFeatureReleased &&
    featureAccess.enum === FeatureAccessTypeEnum.LimitedAccess &&
    featureAccess.organizations.some(organization => organization.organizationId === organizationId)
  );
};

const FeatureAccessInfoProvider: React.FunctionComponent<React.PropsWithChildren> = ({ children }) => {
  const { featureAccessAvailabilities } = useFeatureAccessInfoGet();

  return (
    <FeatureAccessInfoContext.Provider
      value={{
        featureAccessAvailabilities,
      }}
    >
      {children}
    </FeatureAccessInfoContext.Provider>
  );
};

export default FeatureAccessInfoProvider;
