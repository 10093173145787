import { HardwareDetails } from '@AssetManagementClient/AssetManagement/Packages/Asset/Hardware/Packages/Details/Model.gen';
import { Enum as HardwareStatusEnum } from '@AssetManagementClient/BeastClient/Beast/Asset/Model/Device/HardwareStatusState/HardwareStatusStateFactoryNested.gen';
import { css } from '@emotion/react';
import * as React from 'react';
import ListItem from '~/legacy-ui/packages/list/packages/list-item/ListItem';
import StackedBarChart from '~/neo-ui/packages/chart/packages/bar/packages/stacked/StackedBarChart';
import { neoColorToCode } from '~/neo-ui/packages/color/NeoColor';
import HardwareInfoCard from '~/wm/packages/hardware/packages/hardware-info-card/HardwareInfoCard';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type HardwareConfigurationInfoProps = {
  resultantHardwareAsset: HardwareDetails;
};

const HardwareConfigurationInfo: React.FunctionComponent<HardwareConfigurationInfoProps> = ({ resultantHardwareAsset }) => (
  <HardwareInfoCard
    padding={'lg'}
    header={{ title: 'Configuration' }}
  >
    <div
      css={css`
        > *:not(:last-child) {
          margin-bottom: 1.25rem;
        }
      `}
    >
      <ListItem
        title="Processor"
        description={resultantHardwareAsset.cpu ? resultantHardwareAsset.cpu.name : 'Unknown'}
        descriptionMuted={!resultantHardwareAsset.cpu}
        icon={'Processor'}
      />
      <ListItem
        title="Memory"
        theme={hardwareStatusToDisplayDetails(resultantHardwareAsset.memoryStatus).theme}
        description={resultantHardwareAsset.ramFormatted ? resultantHardwareAsset.ramFormatted : 'Unknown'}
        descriptionMuted={!resultantHardwareAsset.ramFormatted}
        icon={'Memory'}
      />
      <ListItem
        title="Storage"
        theme={hardwareStatusToDisplayDetails(resultantHardwareAsset.storageStatus).theme}
        description={resultantHardwareAsset.storage ? resultantHardwareAsset.storage.totalFormatted : 'Unknown'}
        descriptionMuted={!resultantHardwareAsset.storage}
        icon={'Storage'}
      >
        <div
          css={css`
            margin-left: 2.25rem;
          `}
        >
          <div
            css={css`
              margin-top: 0.3125rem;
              margin-bottom: 0.625rem;
              display: flex;
              justify-content: space-between;
            `}
          >
            {resultantHardwareAsset.storage && resultantHardwareAsset.storage.usedFormatted && (
              <span
                className={'small'}
                css={css`
                  color: ${colorToCode('secondary-400')};
                `}
              >
                {resultantHardwareAsset.storage.usedFormatted} used
              </span>
            )}
            <span
              className={'small'}
              css={css`
                color: ${resultantHardwareAsset.storage && resultantHardwareAsset.storage.availableFormatted
                  ? colorToCode('dark-200')
                  : colorToCode('light-700')};
              `}
            >
              {resultantHardwareAsset.storage && resultantHardwareAsset.storage.availableFormatted
                ? `${resultantHardwareAsset.storage.availableFormatted} available`
                : 'Usage unknown'}
            </span>
          </div>
          <StackedBarChart
            height={'0.325rem'}
            bars={[
              {
                value:
                  resultantHardwareAsset.storage && resultantHardwareAsset.storage.usedBytes ? resultantHardwareAsset.storage.usedBytes : 0,
                color: colorToCode('secondary-400'),
              },
              {
                value:
                  resultantHardwareAsset.storage && resultantHardwareAsset.storage.availableBytes
                    ? resultantHardwareAsset.storage.availableBytes
                    : 1,
                color: neoColorToCode('art-shadow'),
              },
            ]}
          />
        </div>
      </ListItem>
    </div>
  </HardwareInfoCard>
);

type HardwareStatusDisplayDefinition = {
  theme: Color | undefined;
};

const hardwareStatusToDisplayDetails = (status: HardwareStatusEnum): HardwareStatusDisplayDefinition => {
  switch (status) {
    case HardwareStatusEnum.In:
      return {
        theme: undefined,
      };
    case HardwareStatusEnum.Soon:
      return {
        theme: 'warning-400',
      };
    case HardwareStatusEnum.Out:
      return {
        theme: 'negative-400',
      };
    case HardwareStatusEnum.Unknown:
      return {
        theme: undefined,
      };
  }
};

export default HardwareConfigurationInfo;
