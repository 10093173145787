import { SubscriptionSummary } from '@SubscriptionClient/BeastClient/Beast/Msp/Billing/PaymentSummary/Dto.gen';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import SubscriptionSummaryCardItemSection from '~/wm/packages/subscription/packages/product-subscription-page/packages/subscription-summary-card/SubscriptionSummaryCardItemSection';
import SubscriptionSummaryCardInfoSection from '~/wm/packages/subscription/packages/product-subscription-page/packages/subscription-summary-card/SubscriptionSummaryCardInfoSection';
import { tabletBreakpointRem } from '~/neo-ui/packages/layout/types/breakpoints';
import SubscriptionSummaryCardCtaSection from '~/wm/packages/subscription/packages/product-subscription-page/packages/subscription-summary-card/SubscriptionSummaryCardCtaSection';

export type SubscriptionSummaryCardProps = {
  subscriptionSummary: SubscriptionSummary;
  allowSelfServeSubscriptionUpdate: boolean;
};

const SubscriptionSummaryCard = ({ subscriptionSummary, allowSelfServeSubscriptionUpdate }: SubscriptionSummaryCardProps) => {
  return (
    <div
      css={{
        borderRadius: '8px',
        boxShadow: `0px 1px 4px 0px ${colorToCode('dark-000')}`,
        padding: '1.25rem 1rem',
        backgroundColor: colorToCode('light-000'),
        marginBottom: '1.5rem',
        minWidth: 'min-content',
      }}
    >
      <img
        src={subscriptionSummary.productLogo}
        css={{ height: '1.5rem' }}
      />
      <div
        css={{
          display: 'grid',
          gridTemplateColumns: '30% auto min-content',
          gridGap: '2rem',
          marginTop: '1rem',
          [`@media (max-width: ${tabletBreakpointRem}rem)`]: {
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <SubscriptionSummaryCardItemSection
          summaryItems={subscriptionSummary.summaryItems}
          billingFrequency={subscriptionSummary.billingFrequency}
        />
        <SubscriptionSummaryCardInfoSection
          contractTerm={subscriptionSummary.contractTerm}
          billingFrequency={subscriptionSummary.billingFrequency}
          nextPaymentAmount={subscriptionSummary.nextPaymentAmount}
          nextBillingAt={subscriptionSummary.nextBillingAt}
        />
        {allowSelfServeSubscriptionUpdate && (
          <SubscriptionSummaryCardCtaSection
            isFreeSubscription={subscriptionSummary.isFreeSubscription}
            manageSubscriptionUrl={subscriptionSummary.manageSubscriptionUrl}
          />
        )}
      </div>
    </div>
  );
};

export default SubscriptionSummaryCard;
