// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.

import axios, { AxiosRequestConfig, Method, AxiosError } from 'axios';
import { sleep } from '~/extensions/packages/sleep/sleep';
import { clientConfig } from '~/client/clientConfig';

const streamSaverPromise = typeof document !== 'undefined'
  // Importing the streamSaver library uses window which crashes in server-side rendering contexts
  ? import('streamsaver')
  : undefined;


//#region ApiCalls


//#region CaseConverters

export function formatRequest(input: any)
{
  const output : any = {};
  let key;
  let updatedKey;
  let value;
  if (input instanceof Array)
  {
    return input.map(function(value)
    {
      if (typeof value === 'object')
      {
        value = formatRequest(value);
      }
      return value;
    });
  }
  else
  {
    for (key in input)
    {
      if (input.hasOwnProperty(key))
      {
        value = input[key];
        if (value instanceof Array || (value !== null && typeof value !== 'undefined' && value.constructor === Object))
        {
          value = formatRequest(value);
        }

        updatedKey = key.includes('-')
          // This is likely not a property but a custom dictionary key (workaround for WM-1555)
          ? key
          : camelToPascal(key);
        output[updatedKey] = value;
      }
    }
  }
  return output;
}

const camelToPascal = (key: string) => (key.charAt(0).toUpperCase() + key.slice(1) || key).toString();
const pascalToCamel = (key: string) => (key.charAt(0).toLowerCase() + key.slice(1) || key).toString();

const formatKey = (key: string) => key.includes('-')
  // This is likely not a property but a custom dictionary key (workaround for WM-1555)
  ? key
  : pascalToCamel(key);

type ValueTransformer = (value: any) => any;

/**
 * It is our convention to use undefined throughout the codebase to reduce
 * confusion and ambiguity between undefined and null.
 *
 * Therefore, we map nulls from the API to undefined for use in Typescript.
 */
const transformNullToUndefined: ValueTransformer = (value) =>
    value === null ? undefined : value;

/**
 * Transformations applied to all json values before passing to the consumer
 */
const valueTransformations: ValueTransformer[]  = [
  transformNullToUndefined
];

const formatValue = <T>(value: T) =>
    // Apply all transformations
    valueTransformations.reduce((value, transform) => transform(value), value);

export function formatResponse(input: any): any
{
  const output: any = {};
  if (input instanceof Array)
  {
    return input.map((value) =>
        typeof value === 'object' && value !== null
            // It's an object or array, recurse
            ? formatResponse(value)
            : formatValue(value));
  }
  else
  {
    for (const key in input)
    {
      if (input.hasOwnProperty(key))
      {
        const value = input[key];

        // Format the key
        const formattedKey = formatKey(key);

        // Format the value
        const formattedValue = typeof value === 'object' && value !== null
          // It's an object or array, recurse
          ? formatResponse(value)
          : formatValue(value);

        // Build output object
        output[formattedKey] = formattedValue;
      }
    }
  }
  return output;
}

//#endregion CaseConverters


const prefix = clientConfig.origin + "/api/AssetManagement"

async function callApi<TRequest, TResponse>(method: Method, path: string, request: TRequest, attemptCount = 1): Promise<TResponse>
{
  try {
    const axiosRequest = createAxiosRequest(method, path, formatRequest(request), false);
    const response = await axios(axiosRequest);
    return formatResponse(response.data);
  } catch(error) {
    const err = error as AxiosError;
    const csrfToken = err.response?.headers['X-XSRF-TOKEN'.toLowerCase()];
    if (typeof csrfToken !== 'undefined' && attemptCount < 6)
    {
      localStorage.setItem('XSRF-TOKEN', csrfToken);

      // Sleep for exponential back-off in case the service is down
      await sleep(Math.random() * 10 * Math.pow(3, attemptCount - 1) + 5);

      return callApi(method, path, request, attemptCount + 1);
    }
    throw error;
  }
}

async function callStreamApi<TRequest>(method: Method, path: string, request: TRequest, attemptCount = 1): Promise<void>
{
  const csrfToken = localStorage.getItem('XSRF-TOKEN');
  const fetchResponse = await fetch(prefix + path, {
    method: method,
    headers: {
      ...((csrfToken !== null) && { 'X-XSRF-TOKEN': csrfToken }),
      'Content-Type': 'application/json',
    },
    // Fetch requires this to be a string
    body: JSON.stringify(formatRequest(request)),
  });

  if (!fetchResponse.ok) {
    const csrfToken = fetchResponse.headers.get('X-XSRF-TOKEN'.toLowerCase());
    if (typeof csrfToken !== 'undefined' && attemptCount < 6) {
      // Sleep for exponential back-off in case the service is down
      await sleep(Math.random() * 10 * Math.pow(3, attemptCount - 1) + 5);
      return callStreamApi(method, path, request, attemptCount + 1);
    }
  }

  // Download stream
  const readableStream = fetchResponse.body;
  // Regex to get the filename from the Content-Disposition header
  const fileNameRegex = `(?<=filename=").*?(?=")`;
  const fileName = fetchResponse.headers.get('Content-Disposition')?.match(fileNameRegex)![0];
  const decodedFilename = decodeURIComponent(fileName!);
  if (window.WritableStream && readableStream?.pipeTo) {
    // We can assert StreamSaver exists now as this is a client-side context
    const streamSaver = (await streamSaverPromise!).default;
    // Override mitm.html location from jimmywarting.github.io to self-hosted file in php
    streamSaver.mitm = '/stream-saver/mitm.html';
    const fileStream = streamSaver.createWriteStream(decodedFilename);
    return readableStream.pipeTo(fileStream);
  }
}

function createAxiosRequest<TRequest>(callMethod: Method, path: string, request: TRequest, isStreamRequest: boolean): AxiosRequestConfig
{
  const csrfToken = localStorage.getItem('XSRF-TOKEN');
  const requestConfig: AxiosRequestConfig =
  {
    method: callMethod,
    url: prefix + path,
    data: request,
    headers: {
      ...((csrfToken !== null) && {'X-XSRF-TOKEN': csrfToken})
    }
  };
  if (isStreamRequest)
  {
    requestConfig.responseType = 'stream';
    requestConfig.timeout = 99000; // milliseconds
  }
  return requestConfig;
}

//#endregion ApiCalls


//#region Imports

import { Request as AssetManagementClientAssetManagementPackagesAccountExecutivePackagesListControllerAccountExecutiveListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAccountExecutivePackagesListControllerAccountExecutiveListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/AccountExecutive/Packages/List/Controller/AccountExecutiveListControllerNested.gen"
import { EmptyRequest as AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest } from "@AssetManagementClient/ApiServer/Packages/AspNetCore/Controller/Request.gen"
import { Response as AssetManagementClientAssetManagementPackagesAssessmentControllerAssessmentAvailabilitiesGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Assessment/Controller/AssessmentAvailabilitiesGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentCriterionControllerAssessmentCriterionCreateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Assessment/Packages/AssessmentCriterion/Controller/AssessmentCriterionCreateControllerNested.gen"
import { EmptyResponse as AssetManagementClientCommonControllerControllerEmptyResponse, EmptyRequest as AssetManagementClientCommonControllerControllerEmptyRequest } from "@AssetManagementClient/Common/Controller/Controller.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationCreateControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationCreateControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Assessment/Packages/AssessmentEvaluation/Controller/AssessmentEvaluationCreateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationCriterionSelectControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Assessment/Packages/AssessmentEvaluation/Controller/AssessmentEvaluationCriterionSelectControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationDeleteControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Assessment/Packages/AssessmentEvaluation/Controller/AssessmentEvaluationDeleteControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationDetailsUpdateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Assessment/Packages/AssessmentEvaluation/Controller/AssessmentEvaluationDetailsUpdateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentTemplateListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentTemplateListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Assessment/Packages/AssessmentEvaluation/Controller/AssessmentTemplateListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationListGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationListGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Assessment/Packages/AssessmentEvaluation/Controller/AssessmentEvaluationListGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationMarkCompleteControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Assessment/Packages/AssessmentEvaluation/Controller/AssessmentEvaluationMarkCompleteControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationMarkIncompleteControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Assessment/Packages/AssessmentEvaluation/Controller/AssessmentEvaluationMarkIncompleteControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateCreateControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateCreateControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Assessment/Packages/AssessmentTemplate/Controller/AssessmentTemplateCreateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateDeleteControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Assessment/Packages/AssessmentTemplate/Controller/AssessmentTemplateDeleteControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Assessment/Packages/AssessmentTemplate/Controller/AssessmentTemplateGetControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Assessment/Packages/AssessmentTemplate/Controller/AssessmentTemplateListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateUpdateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Assessment/Packages/AssessmentTemplate/Controller/AssessmentTemplateUpdateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssetConsoleControllerAssetConsoleControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAssetConsoleControllerAssetConsoleControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Asset/Console/Controller/AssetConsoleControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssetConsoleControllerAssetConsoleIdsControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAssetConsoleControllerAssetConsoleIdsControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Asset/Console/Controller/AssetConsoleIdsControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssetConsoleControllerAssetConsoleSpreadsheetControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Asset/Console/Controller/AssetConsoleSpreadsheetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssetHardwarePackagesDetailsControllerHardwareDetailsGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAssetHardwarePackagesDetailsControllerHardwareDetailsGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Asset/Hardware/Packages/Details/Controller/HardwareDetailsGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssetHardwarePackagesStatsControllerScopedHardwareTypeStatsGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAssetHardwarePackagesStatsControllerScopedHardwareTypeStatsGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Asset/Hardware/Packages/Stats/Controller/ScopedHardwareTypeStatsGetControllerNested.gen"
import { WidgetResponse as AssetManagementClientDashboardSdkModelSimpleCountComponentNestedSimpleCountDto_SimplePrerequisiteComponentNestedSimplePrerequisiteDto_WidgetResponse } from "@AssetManagementClient/Dashboard/Sdk/Model/SimpleCountComponentNested/SimpleCountDto_/SimplePrerequisiteComponentNested/SimplePrerequisiteDto_.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssetSoftwarePackagesQueryFilterControllerSoftwareQueryFilterGetAvailableControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAssetSoftwarePackagesQueryFilterControllerSoftwareQueryFilterGetAvailableControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Asset/Software/Packages/Query/Filter/Controller/SoftwareQueryFilterGetAvailableControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssetSoftwarePackagesQueryFilterControllerSoftwareSearchRowControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAssetSoftwarePackagesQueryFilterControllerSoftwareSearchRowControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Asset/Software/Packages/Query/Filter/Controller/SoftwareSearchRowControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssetSoftwarePackagesQueryFilterControllerSoftwareSearchSpreadsheetControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Asset/Software/Packages/Query/Filter/Controller/SoftwareSearchSpreadsheetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportCreateControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportCreateControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/AutoEmailReport/Controller/AutoEmailReportCreateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportDeleteControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/AutoEmailReport/Controller/AutoEmailReportDeleteControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportEmailTemplateGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportEmailTemplateGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/AutoEmailReport/Controller/AutoEmailReportEmailTemplateGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportEmailTemplateUpdateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/AutoEmailReport/Controller/AutoEmailReportEmailTemplateUpdateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/AutoEmailReport/Controller/AutoEmailReportListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportPdfPreviewControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/AutoEmailReport/Controller/AutoEmailReportPdfPreviewControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportPreviewSendControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/AutoEmailReport/Controller/AutoEmailReportPreviewSendControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAutoEmailReportPackagesRecipientControllerAutoEmailReportRecipientAddControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/AutoEmailReport/Packages/Recipient/Controller/AutoEmailReportRecipientAddControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAutoEmailReportPackagesRecipientControllerAutoEmailReportRecipientRemoveControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/AutoEmailReport/Packages/Recipient/Controller/AutoEmailReportRecipientRemoveControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportScheduleUpdateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/AutoEmailReport/Controller/AutoEmailReportScheduleUpdateControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportTemplateAvailabilityListGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/AutoEmailReport/Controller/AutoEmailReportTemplateAvailabilityListGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportViewUpdateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/AutoEmailReport/Controller/AutoEmailReportViewUpdateControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesStrategyPackagesBrandingControllerBrandingMspGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Strategy/Packages/Branding/Controller/BrandingMspGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesBudgetForecastControllerBudgetForecastAvailabilitiesGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesBudgetForecastControllerBudgetForecastAvailabilitiesGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/BudgetForecast/Controller/BudgetForecastAvailabilitiesGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesBudgetForecastControllerBudgetForecastDetailPdfControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/BudgetForecast/Controller/BudgetForecastDetailPdfControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesBudgetForecastControllerBudgetForecastGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesBudgetForecastControllerBudgetForecastGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/BudgetForecast/Controller/BudgetForecastGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesBudgetForecastControllerBudgetForecastPdfControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/BudgetForecast/Controller/BudgetForecastPdfControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesContractControllerContractAgreementListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesContractControllerContractAgreementListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Contract/Controller/ContractAgreementListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesContractControllerContractAvailabilitiesGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesContractControllerContractAvailabilitiesGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Contract/Controller/ContractAvailabilitiesGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesContractControllerContractCreateControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesContractControllerContractCreateControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Contract/Controller/ContractCreateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesContractControllerContractDeleteControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Contract/Controller/ContractDeleteControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesContractControllerContractGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesContractControllerContractGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Contract/Controller/ContractGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesContractControllerContractListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesContractControllerContractListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Contract/Controller/ContractListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesContractControllerContractListCsvControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Contract/Controller/ContractListCsvControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesContractControllerContractListPdfControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Contract/Controller/ContractListPdfControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesContractControllerContractListSpreadsheetControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Contract/Controller/ContractListSpreadsheetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesContractControllerContractSyncControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Contract/Controller/ContractSyncControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesContractPackagesSyncControllerContractSyncGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesContractPackagesSyncControllerContractSyncGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Contract/Packages/Sync/Controller/ContractSyncGetControllerNested.gen"
import { EmptyRequest as AssetManagementClientBeastClientCommonControllerControllerEmptyRequest, EmptyResponse as AssetManagementClientBeastClientCommonControllerControllerEmptyResponse } from "@AssetManagementClient/BeastClient/Common/Controller/Controller.gen"
import { Request as AssetManagementClientAssetManagementPackagesContractPackagesSyncControllerContractSyncToggleControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Contract/Packages/Sync/Controller/ContractSyncToggleControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesContractPackagesTemplateControllerContractTemplateAvailabilitiesGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Contract/Packages/Template/Controller/ContractTemplateAvailabilitiesGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesContractPackagesTemplateControllerContractTemplateCreateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Contract/Packages/Template/Controller/ContractTemplateCreateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesContractPackagesTemplateControllerContractTemplateGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesContractPackagesTemplateControllerContractTemplateGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Contract/Packages/Template/Controller/ContractTemplateGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesContractControllerContractUpdateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Contract/Controller/ContractUpdateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssetHardwarePackagesDeviceControllerHardwareLifecycleReportPdfControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Asset/Hardware/Packages/Device/Controller/HardwareLifecycleReportPdfControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssetHardwarePackagesDeviceControllerHardwareLifecycleReportSettingsGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAssetHardwarePackagesDeviceControllerHardwareLifecycleReportSettingsGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Asset/Hardware/Packages/Device/Controller/HardwareLifecycleReportSettingsGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalDisposalAddressValidateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/DisposalAddressValidateControllerNested.gen"
import { Result as AssetManagementClientPrimitivesResultsDisposalAddressValidateControllerNestedResponse_ApiError_Result } from "@AssetManagementClient/Primitives/Results/DisposalAddressValidateControllerNested/Response_/ApiError_.gen"
import { Response as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalAssetTypeControllerDisposalAssetTypeAvailabilitiesGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalAssetType/Controller/DisposalAssetTypeAvailabilitiesGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesCartDisposalCartUpsertControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Cart/DisposalCartUpsertControllerNested.gen"
import { Ok as AssetManagementClientPrimitivesResultsOk } from "@AssetManagementClient/Primitives/Results.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesCheckoutPackagesDisposalCheckoutBulkControllerDisposalCheckoutBulkControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Checkout/Packages/DisposalCheckoutBulk/Controller/DisposalCheckoutBulkControllerNested.gen"
import { Result as AssetManagementClientPrimitivesResultsDisposalCheckoutBulkControllerNestedResponse_ApiError_Result } from "@AssetManagementClient/Primitives/Results/DisposalCheckoutBulkControllerNested/Response_/ApiError_.gen"
import { Response as AssetManagementClientAssetManagementPackagesDisposalPackagesCheckoutControllerDisposalCheckoutDataGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Checkout/Controller/DisposalCheckoutDataGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesCheckoutPackagesDisposalCheckoutSelfControllerDisposalCheckoutSelfControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Checkout/Packages/DisposalCheckoutSelf/Controller/DisposalCheckoutSelfControllerNested.gen"
import { Result as AssetManagementClientPrimitivesResultsDisposalCheckoutSelfControllerNestedResponse_ApiError_Result } from "@AssetManagementClient/Primitives/Results/DisposalCheckoutSelfControllerNested/Response_/ApiError_.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesCouponDisposalCouponCodeValidateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Coupon/DisposalCouponCodeValidateControllerNested.gen"
import { Result as AssetManagementClientPrimitivesResultsDisposalCouponCodeValidateControllerNestedResponse_ErrorPayload_Result } from "@AssetManagementClient/Primitives/Results/DisposalCouponCodeValidateControllerNested/Response_/ErrorPayload_.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderAssetOrganizationAssignControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderAssetOrganizationAssignControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderAssetOrganizationUnassignedListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderAssetOrganizationUnassignedListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderAssetOrganizationUnassignedListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderInfoGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderInfoGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderInfoGetControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPackagePurchaseControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderPackagePurchaseControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPackagesLabelingProgressControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderPackagesLabelingProgressControllerNested.gen"
import { Result as AssetManagementClientPrimitivesResultsProgressResponse_ProgressError_Result } from "@AssetManagementClient/Primitives/Results/ProgressResponse_/ProgressError_.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPdfsControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderPdfsControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPickupBulkAvailabilityScheduleControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderPickupBulkAvailabilityScheduleControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPickupCancelControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderPickupCancelControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPickupImageListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPickupImageListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderPickupImageListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPickupImageUploadControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderPickupImageUploadControllerNested.gen"
import { Result as AssetManagementClientPrimitivesResultsDisposalOrderPickupImageUploadControllerNestedResponse_DisposalOrderPickupImageUploadError_Result } from "@AssetManagementClient/Primitives/Results/DisposalOrderPickupImageUploadControllerNested/Response_/DisposalOrderPickupImageUploadError_.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPickupScheduleControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderPickupScheduleControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderShippingLabelGetControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderShippingLabelGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderInternalSpreadsheetControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalOrder/Controller/DisposalOrderInternalSpreadsheetControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalProviderDisposalProviderOrderAssetOrphanCountControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalProvider/DisposalProviderOrderAssetOrphanCountControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalProviderDisposalProviderOrderAssetOrphanListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalProvider/DisposalProviderOrderAssetOrphanListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalProviderDisposalProviderOrderGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalProviderDisposalProviderOrderGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalProvider/DisposalProviderOrderGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalProviderDisposalProviderOrderListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalProviderDisposalProviderOrderListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalProvider/DisposalProviderOrderListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalProviderDisposalProviderOrderNotesAddControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalProvider/DisposalProviderOrderNotesAddControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalProviderDisposalProviderOrderPickupImageListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalProviderDisposalProviderOrderPickupImageListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalProvider/DisposalProviderOrderPickupImageListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalProviderDisposalProviderSchedulingGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalProviderDisposalProviderSchedulingGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalProvider/DisposalProviderSchedulingGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalProviderDisposalProviderSchedulingListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalProviderDisposalProviderSchedulingListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalProvider/DisposalProviderSchedulingListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalProviderDisposalProviderSchedulingPickupScheduleControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/DisposalProvider/DisposalProviderSchedulingPickupScheduleControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesDmiControllerApplicationDmiScoreControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Dmi/Controller/ApplicationDmiScoreControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDmiControllerScopeDmiScoreControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesDmiControllerScopeDmiScoreControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Dmi/Controller/ScopeDmiScoreControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDmiControllerDmiTrendGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesDmiControllerDmiTrendGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Dmi/Controller/DmiTrendGetControllerNested.gen"
import { WidgetResponse as AssetManagementClientDashboardSdkModelDmiScoreComponentNestedDmiScoreDto_SimplePrerequisiteComponentNestedSimplePrerequisiteDto_WidgetResponse } from "@AssetManagementClient/Dashboard/Sdk/Model/DmiScoreComponentNested/DmiScoreDto_/SimplePrerequisiteComponentNested/SimplePrerequisiteDto_.gen"
import { Response as AssetManagementClientAssetManagementPackagesFeatureControllerFeatureAccessInfoGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Feature/Controller/FeatureAccessInfoGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesFeatureControllerFeatureAccessOrganizationUnlockControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Feature/Controller/FeatureAccessOrganizationUnlockControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesFeatureControllerFeatureAccessOrganizationUnlockClientFocusScorecardRoadmapControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Feature/Controller/FeatureAccessOrganizationUnlockClientFocusScorecardRoadmapControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssetHardwarePackagesConsoleHardwareConsoleIdLookupControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAssetHardwarePackagesConsoleHardwareConsoleIdLookupControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Asset/Hardware/Packages/Console/HardwareConsoleIdLookupControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssetHardwarePackagesDeviceControllerHardwareConsoleOrganizationPdfControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Asset/Hardware/Packages/Device/Controller/HardwareConsoleOrganizationPdfControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssetHardwarePackagesStatsControllerHardwareStatsCountGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAssetHardwarePackagesStatsControllerHardwareStatsCountGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Asset/Hardware/Packages/Stats/Controller/HardwareStatsCountGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesAssetHardwarePackagesStatsControllerHardwareCountSummaryGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesAssetHardwarePackagesStatsControllerHardwareCountSummaryGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Asset/Hardware/Packages/Stats/Controller/HardwareCountSummaryGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesHardwareReplacementPackagesSettingsGetControllerHardwareReplacementReportAssetTypeSettingsAvailabilitiesGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesHardwareReplacementPackagesSettingsGetControllerHardwareReplacementReportAssetTypeSettingsAvailabilitiesGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/HardwareReplacement/Packages/Settings/Get/Controller/HardwareReplacementReportAssetTypeSettingsAvailabilitiesGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesHardwareReplacementPackagesSettingsGetControllerHardwareReplacementSettingsGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesHardwareReplacementPackagesSettingsGetControllerHardwareReplacementSettingsGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/HardwareReplacement/Packages/Settings/Get/Controller/HardwareReplacementSettingsGetControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesHardwareSettingsControllerHardwareSettingsAvailabilitiesGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/HardwareSettings/Controller/HardwareSettingsAvailabilitiesGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeAssetAddBulkControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeAssetAddBulkControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativePackagesInitiativeAssetControllerInitiativeAssetMoveBulkControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesInitiativePackagesInitiativeAssetControllerInitiativeAssetMoveBulkControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Packages/InitiativeAsset/Controller/InitiativeAssetMoveBulkControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeAvailabilitiesGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeAvailabilitiesGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeAvailabilitiesGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeCreateControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeCreateControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeCreateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeDeleteControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeDeleteControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeDraftCreateControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeDraftCreateControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeDraftCreateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeEditControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeEditControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeEditControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeEngagementActionUnlinkControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeEngagementActionUnlinkControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeEngagementActionLinkBulkControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeEngagementActionLinkBulkControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeOverviewDashboardListGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeOverviewDashboardListGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeOverviewDashboardListGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativePdfControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativePdfControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeScheduleUpdateControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeScheduleUpdateControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeScheduleUpdateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeStatusUpdateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeStatusUpdateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativePackagesInitiativeTemplateControllerInitiativeTemplateAvailabilityControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesInitiativePackagesInitiativeTemplateControllerInitiativeTemplateAvailabilityControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Packages/InitiativeTemplate/Controller/InitiativeTemplateAvailabilityControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeTemplateCreateControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeTemplateCreateControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeTemplateCreateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeTemplateDeleteControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeTemplateDeleteControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeTemplateUpdateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Initiative/Controller/InitiativeTemplateUpdateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInsightsControllerInsightCreateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Insights/Controller/InsightCreateControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesInsightsControllerInsightCreateFormMetadataControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Insights/Controller/InsightCreateFormMetadataControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInsightsControllerInsightCustomListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesInsightsControllerInsightCustomListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Insights/Controller/InsightCustomListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInsightsControllerInsightDeleteControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Insights/Controller/InsightDeleteControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesInsightsControllerInsightListGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesInsightsControllerInsightListGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Insights/Controller/InsightListGetControllerNested.gen"
import { WidgetResponse as AssetManagementClientDashboardSdkModelAreaChartComponentNestedAreaChartDto_SimplePrerequisiteComponentNestedSimplePrerequisiteDto_WidgetResponse } from "@AssetManagementClient/Dashboard/Sdk/Model/AreaChartComponentNested/AreaChartDto_/SimplePrerequisiteComponentNested/SimplePrerequisiteDto_.gen"
import { WidgetResponse as AssetManagementClientDashboardSdkModelSimpleTableComponentNestedSimpleTableDto_SimplePrerequisiteComponentNestedSimplePrerequisiteDto_WidgetResponse } from "@AssetManagementClient/Dashboard/Sdk/Model/SimpleTableComponentNested/SimpleTableDto_/SimplePrerequisiteComponentNested/SimplePrerequisiteDto_.gen"
import { Request as AssetManagementClientAssetManagementPackagesIntegrationPackagesDeleteControllerIntegrationDeleteNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Integration/Packages/Delete/Controller/IntegrationDeleteNested.gen"
import { Result as AssetManagementClientPrimitivesResultsEmptyResponse_IntegrationDeleteError_Result } from "@AssetManagementClient/Primitives/Results/EmptyResponse_/IntegrationDeleteError_.gen"
import { Request as AssetManagementClientAssetManagementPackagesIntegrationIntegrationPageControllerIntegrationSetupGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesIntegrationIntegrationPageControllerIntegrationSetupGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Controller/IntegrationSetupGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesIntegrationIntegrationPageControllerIntegrationSetupUpsertControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Controller/IntegrationSetupUpsertControllerNested.gen"
import { Result as AssetManagementClientPrimitivesResultsIntegrationSetupUpsertControllerNestedResponse_IntegrationSetupError_Result } from "@AssetManagementClient/Primitives/Results/IntegrationSetupUpsertControllerNested/Response_/IntegrationSetupError_.gen"
import { Request as AssetManagementClientAssetManagementPackagesIntegrationPackagesSetupControllerIntegrationSyncNotificationSubscriptionStatusControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesIntegrationPackagesSetupControllerIntegrationSyncNotificationSubscriptionStatusControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Integration/Packages/Setup/Controller/IntegrationSyncNotificationSubscriptionStatusControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesIntegrationPackagesNotificationControllerIntegrationSyncNotificationSubscriptionStatusControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesIntegrationPackagesNotificationControllerIntegrationSyncNotificationSubscriptionStatusControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Integration/Packages/Notification/Controller/IntegrationSyncNotificationSubscriptionStatusControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesIntegrationPackagesNotificationControllerIntegrationSyncNotificationSubscriptionUpdateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Integration/Packages/Notification/Controller/IntegrationSyncNotificationSubscriptionUpdateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesIntegrationIntegrationPageControllerIntegrationProductGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesIntegrationIntegrationPageControllerIntegrationProductGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Controller/IntegrationProductGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesIntegrationIntegrationPageControllerIntegrationPageControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Controller/IntegrationPageControllerNested.gen"
import { Response as AssetManagementClientBeastClientBeastIntegrationControllerIntegrationPageIntegrationVendorListNestedResponse } from "@AssetManagementClient/BeastClient/Beast/Integration/Controller/IntegrationPage/IntegrationVendorListNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesManufacturerManufacturerListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Manufacturer/ManufacturerListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationAccountTeamMemberOrganizationAccountTeamMemberAddControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/OrganizationAccountTeamMember/OrganizationAccountTeamMemberAddControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationAccountTeamMemberOrganizationAccountTeamMemberEditControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/OrganizationAccountTeamMember/OrganizationAccountTeamMemberEditControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationAccountTeamMemberOrganizationAccountTeamMemberListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationAccountTeamMemberOrganizationAccountTeamMemberListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/OrganizationAccountTeamMember/OrganizationAccountTeamMemberListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationAccountTeamMemberOrganizationAccountTeamMemberRemoveControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/OrganizationAccountTeamMember/OrganizationAccountTeamMemberRemoveControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationAccountTeamMemberPackagesOrganizationAccountTeamMemberRoleControllerOrganizationAccountTeamMemberRoleAddControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/OrganizationAccountTeamMember/Packages/OrganizationAccountTeamMemberRole/Controller/OrganizationAccountTeamMemberRoleAddControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationAccountTeamMemberPackagesOrganizationAccountTeamMemberRoleControllerOrganizationAccountTeamMemberRoleDeleteControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/OrganizationAccountTeamMember/Packages/OrganizationAccountTeamMemberRole/Controller/OrganizationAccountTeamMemberRoleDeleteControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesBasicInfoOrganizationBasicInfoGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesBasicInfoOrganizationBasicInfoGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/BasicInfo/OrganizationBasicInfoGetControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesBasicInfoOrganizationBasicInfoListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/BasicInfo/OrganizationBasicInfoListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationContactOrganizationContactListGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationContactOrganizationContactListGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/OrganizationContact/OrganizationContactListGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionCreateControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionCreateControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementAction/Controller/EngagementActionCreateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionDeleteControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementAction/Controller/EngagementActionDeleteControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionInitiativeLinkListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionInitiativeLinkListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementAction/Controller/EngagementActionInitiativeLinkListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionListDashboardGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionListDashboardGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementAction/Controller/EngagementActionListDashboardGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionListGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionListGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementAction/Controller/EngagementActionListGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionMarkCompletedControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionMarkCompletedControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementAction/Controller/EngagementActionMarkCompletedControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionMarkIncompleteControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionMarkIncompleteControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementAction/Controller/EngagementActionMarkIncompleteControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionPinControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionPinControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementAction/Controller/EngagementActionPinControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionTicketCreateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementAction/Controller/EngagementActionTicketCreateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionTicketUnlinkControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementAction/Controller/EngagementActionTicketUnlinkControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionUnpinControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionUnpinControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementAction/Controller/EngagementActionUnpinControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionUpdateControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionUpdateControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementAction/Controller/EngagementActionUpdateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteArchiveControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteArchiveControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementNote/Controller/EngagementNoteArchiveControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteCreateControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteCreateControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementNote/Controller/EngagementNoteCreateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteDashboardListGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteDashboardListGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementNote/Controller/EngagementNoteDashboardListGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteListGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteListGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementNote/Controller/EngagementNoteListGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNotePinControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNotePinControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementNote/Controller/EngagementNotePinControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteUnarchiveControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteUnarchiveControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementNote/Controller/EngagementNoteUnarchiveControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteUnpinControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteUnpinControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementNote/Controller/EngagementNoteUnpinControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementActionUpdateControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementActionUpdateControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Engagement/Packages/EngagementNote/Controller/EngagementActionUpdateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationKeyContactOrganizationKeyContactAddControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/OrganizationKeyContact/OrganizationKeyContactAddControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationKeyContactOrganizationKeyContactListGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationKeyContactOrganizationKeyContactListGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/OrganizationKeyContact/OrganizationKeyContactListGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationKeyContactOrganizationKeyContactRemoveControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/OrganizationKeyContact/OrganizationKeyContactRemoveControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesLinkControllerOrganizationLinkControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Link/Controller/OrganizationLinkControllerNested.gen"
import { Result as AssetManagementClientPrimitivesResultsOrganizationLinkControllerNestedResponse_OrganizationLinkError_Result } from "@AssetManagementClient/Primitives/Results/OrganizationLinkControllerNested/Response_/OrganizationLinkError_.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesLinkPackagesListControllerOrganizationLinkListControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Link/Packages/List/Controller/OrganizationLinkListControllerNested.gen"
import { Result as AssetManagementClientPrimitivesResultsOrganizationLinkListControllerNestedResponse_OrganizationLinkError_Result } from "@AssetManagementClient/Primitives/Results/OrganizationLinkListControllerNested/Response_/OrganizationLinkError_.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesLinkPackagesProgressControllerOrganizationLinkProgressGetControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Link/Packages/Progress/Controller/OrganizationLinkProgressGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesLinkPackagesSearchControllerOrganizationLinkAvailabilitySearchControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Link/Packages/Search/Controller/OrganizationLinkAvailabilitySearchControllerNested.gen"
import { Result as AssetManagementClientPrimitivesResultsOrganizationLinkAvailabilitySearchControllerNestedResponse_OrganizationLinkError_Result } from "@AssetManagementClient/Primitives/Results/OrganizationLinkAvailabilitySearchControllerNested/Response_/OrganizationLinkError_.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesRenameControllerOrganizationRenameControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Rename/Controller/OrganizationRenameControllerNested.gen"
import { Result as AssetManagementClientPrimitivesResultsOrganizationRenameControllerNestedResponse_OrganizationRenameError_Result } from "@AssetManagementClient/Primitives/Results/OrganizationRenameControllerNested/Response_/OrganizationRenameError_.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesUnlinkControllerOrganizationUnlinkControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/Unlink/Controller/OrganizationUnlinkControllerNested.gen"
import { Result as AssetManagementClientPrimitivesResultsOrganizationUnlinkControllerNestedResponse_OrganizationUnlinkError_Result } from "@AssetManagementClient/Primitives/Results/OrganizationUnlinkControllerNested/Response_/OrganizationUnlinkError_.gen"
import { Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationsSummaryOrganizationsSummaryFeatureUnlockedSearchControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/OrganizationsSummary/OrganizationsSummaryFeatureUnlockedSearchControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationsSummaryOrganizationsSummaryGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/OrganizationsSummary/OrganizationsSummaryGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationsSummaryOrganizationsSummarySearchControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationsSummaryOrganizationsSummarySearchControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/OrganizationsSummary/OrganizationsSummarySearchControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationsSummaryPackagesQueryOrganizationsSummarySearchAvailabilitiesNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Organization/Packages/OrganizationsSummary/Packages/Query/OrganizationsSummarySearchAvailabilitiesNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesRegionalSettingsControllerRegionalSettingsAvailabilitiesGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/RegionalSettings/Controller/RegionalSettingsAvailabilitiesGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesRegionalSettingsControllerRegionalSettingsInfoGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesRegionalSettingsControllerRegionalSettingsInfoGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/RegionalSettings/Controller/RegionalSettingsInfoGetControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesAssetReportAssetTypeControllerReportAssetTypeAvailabilitiesNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Asset/ReportAssetType/Controller/ReportAssetTypeAvailabilitiesNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesRoadmapControllerRoadmapAccountGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Roadmap/Controller/RoadmapAccountGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesRoadmapControllerRoadmapGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesRoadmapControllerRoadmapGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Roadmap/Controller/RoadmapGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesRoadmapControllerRoadmapPdfNewControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Roadmap/Controller/RoadmapPdfNewControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardCategoryControllerScorecardCategoryCreateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Packages/ScorecardCategory/Controller/ScorecardCategoryCreateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardCategoryControllerScorecardCategoryDeleteWithoutItemsControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Packages/ScorecardCategory/Controller/ScorecardCategoryDeleteWithoutItemsControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardCategoryControllerScorecardCategoryDeleteWithItemsControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Packages/ScorecardCategory/Controller/ScorecardCategoryDeleteWithItemsControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardCategoryControllerScorecardCategoryOrderUpdateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Packages/ScorecardCategory/Controller/ScorecardCategoryOrderUpdateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardCategoryControllerScorecardCategoryUpdateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Packages/ScorecardCategory/Controller/ScorecardCategoryUpdateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardControllerScorecardCreateControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesScorecardControllerScorecardCreateControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Controller/ScorecardCreateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardControllerScorecardDeleteControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Controller/ScorecardDeleteControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardControllerScorecardLatestGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesScorecardControllerScorecardLatestGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Controller/ScorecardLatestGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardControllerScorecardGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesScorecardControllerScorecardGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Controller/ScorecardGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardItemControllerScorecardItemAutomationRefreshControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Packages/ScorecardItem/Controller/ScorecardItemAutomationRefreshControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardControllerScorecardListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesScorecardControllerScorecardListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Controller/ScorecardListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardControllerScorecardPdfControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Controller/ScorecardPdfControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardItemControllerScorecardItemCreateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Packages/ScorecardItem/Controller/ScorecardItemCreateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardItemControllerScorecardItemOrderUpdateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Packages/ScorecardItem/Controller/ScorecardItemOrderUpdateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardItemControllerScorecardItemRemoveBulkControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Packages/ScorecardItem/Controller/ScorecardItemRemoveBulkControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardItemControllerScorecardItemUpdateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Packages/ScorecardItem/Controller/ScorecardItemUpdateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesScorecardControllerScorecardUpdateControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesScorecardControllerScorecardUpdateControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Scorecard/Controller/ScorecardUpdateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesSettingsControllerSettingsEnableControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesSettingsControllerSettingsEnableControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Settings/Controller/SettingsEnableControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesSettingsControllerSettingsGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesSettingsControllerSettingsGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Settings/Controller/SettingsGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesSettingsControllerSettingsResetToDefaultControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesSettingsControllerSettingsResetToDefaultControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Settings/Controller/SettingsResetToDefaultControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesSettingsControllerSettingsSaveControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Settings/Controller/SettingsSaveControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesSharePackagesRecipientsControllerAvailableRecipientsGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Share/Packages/Recipients/Controller/AvailableRecipientsGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesSharePackagesSendControllerShareSendControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesSharePackagesSendControllerShareSendControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Share/Packages/Send/Controller/ShareSendControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesStrategyPackagesInsightsAssetSearchControllerAssetSearchControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesStrategyPackagesInsightsAssetSearchControllerAssetSearchControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Strategy/Packages/Insights/AssetSearch/Controller/AssetSearchControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesStrategyPackagesInsightsControllerInsightDashboardControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesStrategyPackagesInsightsControllerInsightDashboardControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Strategy/Packages/Insights/Controller/InsightDashboardControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesSuccessControllerSuccessDisposalOrderAssetCreateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Success/Controller/SuccessDisposalOrderAssetCreateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesSuccessControllerSuccessDisposalOrderAssetFixLogAssignControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesDisposalPackagesSuccessControllerSuccessDisposalOrderAssetFixLogAssignControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Success/Controller/SuccessDisposalOrderAssetFixLogAssignControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesDisposalPackagesSuccessControllerSuccessDisposalOrderAssetOrphanCountControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Success/Controller/SuccessDisposalOrderAssetOrphanCountControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesSuccessControllerSuccessDisposalOrderAssetFixLogDeleteControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Success/Controller/SuccessDisposalOrderAssetFixLogDeleteControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesDisposalPackagesSuccessControllerSuccessDisposalOrderAssetFixLogListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Success/Controller/SuccessDisposalOrderAssetFixLogListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesSuccessControllerSuccessDisposalOrderAssetRemoveControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Success/Controller/SuccessDisposalOrderAssetRemoveControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesSuccessControllerSuccessDisposalOrderDisposeControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Success/Controller/SuccessDisposalOrderDisposeControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesSuccessControllerSuccessDisposalOrderGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesDisposalPackagesSuccessControllerSuccessDisposalOrderGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Success/Controller/SuccessDisposalOrderGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesSuccessControllerSuccessDisposalOrderListControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesDisposalPackagesSuccessControllerSuccessDisposalOrderListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Success/Controller/SuccessDisposalOrderListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesSuccessControllerSuccessDisposalOrderPickupWindowClearControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Success/Controller/SuccessDisposalOrderPickupWindowClearControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesDisposalPackagesSuccessControllerSuccessDisposalSettingsGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Success/Controller/SuccessDisposalSettingsGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesDisposalPackagesSuccessControllerSuccessDisposalSettingsSaveControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/Disposal/Packages/Success/Controller/SuccessDisposalSettingsSaveControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesCorePackagesTicketControllerTicketCreateFieldAvailabilitiesGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesCorePackagesTicketControllerTicketCreateFieldAvailabilitiesGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/Core/Packages/Ticket/Controller/TicketCreateFieldAvailabilitiesGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesTreeImpactControllerTreeImpactOrderInfoGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesTreeImpactControllerTreeImpactOrderInfoGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/TreeImpact/Controller/TreeImpactOrderInfoGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesTreeImpactControllerTreeImpactSummaryGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesTreeImpactControllerTreeImpactSummaryGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/TreeImpact/Controller/TreeImpactSummaryGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesUserPackagesAccessPermissionUserAccessPermissionGetByHubUserIdControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesUserPackagesAccessPermissionUserAccessPermissionGetByHubUserIdControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/User/Packages/AccessPermission/UserAccessPermissionGetByHubUserIdControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesUserPackagesActiveUserControllerActiveUserListGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/User/Packages/ActiveUser/Controller/ActiveUserListGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesUserPackagesUserCreateControllerUserCreateControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/User/Packages/UserCreate/Controller/UserCreateControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesUserPackagesUserDeleteControllerUserDeleteControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/User/Packages/UserDelete/Controller/UserDeleteControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesUserPackagesUserGrantBulkControllerUserGrantControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/User/Packages/UserGrantBulk/Controller/UserGrantControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesUserPackagesUserListControllerUserListV2ControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/User/Packages/UserList/Controller/UserListV2ControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesUserPackagesOrganizationStarControllerUserOrganizationListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/User/Packages/OrganizationStar/Controller/UserOrganizationListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesUserPackagesOrganizationStarControllerUserOrganizationStarAddControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesUserPackagesOrganizationStarControllerUserOrganizationStarAddControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/User/Packages/OrganizationStar/Controller/UserOrganizationStarAddControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesUserPackagesOrganizationStarControllerUserOrganizationStarGetControllerNestedRequest, Response as AssetManagementClientAssetManagementPackagesUserPackagesOrganizationStarControllerUserOrganizationStarGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/User/Packages/OrganizationStar/Controller/UserOrganizationStarGetControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesUserPackagesOrganizationStarControllerUserOrganizationStarListControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/User/Packages/OrganizationStar/Controller/UserOrganizationStarListControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesUserPackagesOrganizationStarControllerUserOrganizationStarRemoveControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/User/Packages/OrganizationStar/Controller/UserOrganizationStarRemoveControllerNested.gen"
import { Response as AssetManagementClientAssetManagementPackagesUserPackagesPermissionAvailabilitiesControllerUserPermissionAvailabilitiesGetControllerNestedResponse } from "@AssetManagementClient/AssetManagement/Packages/User/Packages/PermissionAvailabilities/Controller/UserPermissionAvailabilitiesGetControllerNested.gen"
import { Request as AssetManagementClientAssetManagementPackagesUserPackagesPermissionUpdateControllerUserPermissionUpdateV2ControllerNestedRequest } from "@AssetManagementClient/AssetManagement/Packages/User/Packages/PermissionUpdate/Controller/UserPermissionUpdateV2ControllerNested.gen"

//#endregion Imports


//#region Operations

export function assessmentAvailabilitiesGet(request: AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest): Promise<AssetManagementClientAssetManagementPackagesAssessmentControllerAssessmentAvailabilitiesGetControllerNestedResponse>
{
  return callApi<AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest, AssetManagementClientAssetManagementPackagesAssessmentControllerAssessmentAvailabilitiesGetControllerNestedResponse>("POST", "/Assessment/Availabilities/Get", request);
}

export function assessmentCriterionCreate(request: AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentCriterionControllerAssessmentCriterionCreateControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentCriterionControllerAssessmentCriterionCreateControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Assessment/Criterion/Create", request);
}

export function assessmentEvaluationCreate(request: AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationCreateControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationCreateControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationCreateControllerNestedRequest, AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationCreateControllerNestedResponse>("POST", "/Assessment/Evaluation/Create", request);
}

export function assessmentEvaluationCriterionSelect(request: AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationCriterionSelectControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationCriterionSelectControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Assessment/Evaluation/Criterion/Select", request);
}

export function assessmentEvaluationDelete(request: AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationDeleteControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationDeleteControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Assessment/Evaluation/Delete", request);
}

export function assessmentEvaluationDetailsUpdate(request: AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationDetailsUpdateControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationDetailsUpdateControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Assessment/Evaluation/Details/Update", request);
}

export function assessmentEvaluationGet(request: AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentTemplateListControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentTemplateListControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentTemplateListControllerNestedRequest, AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentTemplateListControllerNestedResponse>("POST", "/Assessment/Evaluation/Get", request);
}

export function assessmentEvaluationListGet(request: AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationListGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationListGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationListGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationListGetControllerNestedResponse>("POST", "/Assessment/Evaluation/List/Get", request);
}

export function assessmentEvaluationMarkComplete(request: AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationMarkCompleteControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationMarkCompleteControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Assessment/Evaluation/Mark/Complete", request);
}

export function assessmentEvaluationMarkIncomplete(request: AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationMarkIncompleteControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentEvaluationControllerAssessmentEvaluationMarkIncompleteControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Assessment/Evaluation/Mark/Incomplete", request);
}

export function assessmentTemplateCreate(request: AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateCreateControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateCreateControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateCreateControllerNestedRequest, AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateCreateControllerNestedResponse>("POST", "/Assessment/Template/Create", request);
}

export function assessmentTemplateDelete(request: AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateDeleteControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateDeleteControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Assessment/Template/Delete", request);
}

export function assessmentTemplateGet(request: AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateGetControllerNestedResponse>("POST", "/Assessment/Template/Get", request);
}

export function assessmentTemplateList(request: AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest): Promise<AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateListControllerNestedResponse>
{
  return callApi<AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest, AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateListControllerNestedResponse>("POST", "/Assessment/Template/List", request);
}

export function assessmentTemplateUpdate(request: AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateUpdateControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAssessmentPackagesAssessmentTemplateControllerAssessmentTemplateUpdateControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Assessment/Template/Update", request);
}

export function assetConsole(request: AssetManagementClientAssetManagementPackagesAssetConsoleControllerAssetConsoleControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesAssetConsoleControllerAssetConsoleControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAssetConsoleControllerAssetConsoleControllerNestedRequest, AssetManagementClientAssetManagementPackagesAssetConsoleControllerAssetConsoleControllerNestedResponse>("POST", "/Asset/Console", request);
}

export function assetConsoleIds(request: AssetManagementClientAssetManagementPackagesAssetConsoleControllerAssetConsoleIdsControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesAssetConsoleControllerAssetConsoleIdsControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAssetConsoleControllerAssetConsoleIdsControllerNestedRequest, AssetManagementClientAssetManagementPackagesAssetConsoleControllerAssetConsoleIdsControllerNestedResponse>("POST", "/Asset/Console/Ids", request);
}

export function assetConsoleSpreadsheet(request: AssetManagementClientAssetManagementPackagesAssetConsoleControllerAssetConsoleSpreadsheetControllerNestedRequest): Promise<void>
{
  return callStreamApi<AssetManagementClientAssetManagementPackagesAssetConsoleControllerAssetConsoleSpreadsheetControllerNestedRequest>("POST", "/Asset/Console/Spreadsheet", request);
}

export function assetHardwareDetailsGet(request: AssetManagementClientAssetManagementPackagesAssetHardwarePackagesDetailsControllerHardwareDetailsGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesAssetHardwarePackagesDetailsControllerHardwareDetailsGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAssetHardwarePackagesDetailsControllerHardwareDetailsGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesAssetHardwarePackagesDetailsControllerHardwareDetailsGetControllerNestedResponse>("POST", "/Asset/Hardware/Details/Get", request);
}

export function assetHardwareStatsDeviceTypeScopedGet(request: AssetManagementClientAssetManagementPackagesAssetHardwarePackagesStatsControllerScopedHardwareTypeStatsGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesAssetHardwarePackagesStatsControllerScopedHardwareTypeStatsGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAssetHardwarePackagesStatsControllerScopedHardwareTypeStatsGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesAssetHardwarePackagesStatsControllerScopedHardwareTypeStatsGetControllerNestedResponse>("POST", "/Asset/Hardware/Stats/DeviceType/Scoped/Get", request);
}

export function assetHardwareWidgetsCount(request: AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest): Promise<AssetManagementClientDashboardSdkModelSimpleCountComponentNestedSimpleCountDto_SimplePrerequisiteComponentNestedSimplePrerequisiteDto_WidgetResponse>
{
  return callApi<AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest, AssetManagementClientDashboardSdkModelSimpleCountComponentNestedSimpleCountDto_SimplePrerequisiteComponentNestedSimplePrerequisiteDto_WidgetResponse>("POST", "/Asset/Hardware/Widgets/Count", request);
}

export function assetHardwareWidgetsValue(request: AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest): Promise<AssetManagementClientDashboardSdkModelSimpleCountComponentNestedSimpleCountDto_SimplePrerequisiteComponentNestedSimplePrerequisiteDto_WidgetResponse>
{
  return callApi<AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest, AssetManagementClientDashboardSdkModelSimpleCountComponentNestedSimpleCountDto_SimplePrerequisiteComponentNestedSimplePrerequisiteDto_WidgetResponse>("POST", "/Asset/Hardware/Widgets/Value", request);
}

export function assetSoftwareSearchFiltersAvailable(request: AssetManagementClientAssetManagementPackagesAssetSoftwarePackagesQueryFilterControllerSoftwareQueryFilterGetAvailableControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesAssetSoftwarePackagesQueryFilterControllerSoftwareQueryFilterGetAvailableControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAssetSoftwarePackagesQueryFilterControllerSoftwareQueryFilterGetAvailableControllerNestedRequest, AssetManagementClientAssetManagementPackagesAssetSoftwarePackagesQueryFilterControllerSoftwareQueryFilterGetAvailableControllerNestedResponse>("POST", "/Asset/Software/Search/Filters/Available", request);
}

export function assetSoftwareSearchRow(request: AssetManagementClientAssetManagementPackagesAssetSoftwarePackagesQueryFilterControllerSoftwareSearchRowControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesAssetSoftwarePackagesQueryFilterControllerSoftwareSearchRowControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAssetSoftwarePackagesQueryFilterControllerSoftwareSearchRowControllerNestedRequest, AssetManagementClientAssetManagementPackagesAssetSoftwarePackagesQueryFilterControllerSoftwareSearchRowControllerNestedResponse>("POST", "/Asset/Software/Search/Row", request);
}

export function assetSoftwareSearchSpreadsheet(request: AssetManagementClientAssetManagementPackagesAssetSoftwarePackagesQueryFilterControllerSoftwareSearchSpreadsheetControllerNestedRequest): Promise<void>
{
  return callStreamApi<AssetManagementClientAssetManagementPackagesAssetSoftwarePackagesQueryFilterControllerSoftwareSearchSpreadsheetControllerNestedRequest>("POST", "/Asset/Software/Search/Spreadsheet", request);
}

export function autoEmailReportCreate(request: AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportCreateControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportCreateControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportCreateControllerNestedRequest, AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportCreateControllerNestedResponse>("POST", "/Auto/Email/Report/Create", request);
}

export function autoEmailReportDelete(request: AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportDeleteControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportDeleteControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Auto/Email/Report/Delete", request);
}

export function autoEmailReportEmailTemplateGet(request: AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportEmailTemplateGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportEmailTemplateGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportEmailTemplateGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportEmailTemplateGetControllerNestedResponse>("POST", "/Auto/Email/Report/Email/Template/Get", request);
}

export function autoEmailReportEmailTemplateUpdate(request: AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportEmailTemplateUpdateControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportEmailTemplateUpdateControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Auto/Email/Report/Email/Template/Update", request);
}

export function autoEmailReportList(request: AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportListControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportListControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportListControllerNestedRequest, AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportListControllerNestedResponse>("POST", "/Auto/Email/Report/List", request);
}

export function autoEmailReportPdfPreview(request: AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportPdfPreviewControllerNestedRequest): Promise<void>
{
  return callStreamApi<AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportPdfPreviewControllerNestedRequest>("POST", "/Auto/Email/Report/Pdf/Preview", request);
}

export function autoEmailReportPreviewSend(request: AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportPreviewSendControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportPreviewSendControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Auto/Email/Report/Preview/Send", request);
}

export function autoEmailReportRecipientAdd(request: AssetManagementClientAssetManagementPackagesAutoEmailReportPackagesRecipientControllerAutoEmailReportRecipientAddControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAutoEmailReportPackagesRecipientControllerAutoEmailReportRecipientAddControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Auto/Email/Report/Recipient/Add", request);
}

export function autoEmailReportRecipientRemove(request: AssetManagementClientAssetManagementPackagesAutoEmailReportPackagesRecipientControllerAutoEmailReportRecipientRemoveControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAutoEmailReportPackagesRecipientControllerAutoEmailReportRecipientRemoveControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Auto/Email/Report/Recipient/Remove", request);
}

export function autoEmailReportScheduleUpdate(request: AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportScheduleUpdateControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportScheduleUpdateControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Auto/Email/Report/Schedule/Update", request);
}

export function autoEmailReportTemplateAvailabilityListGet(request: AssetManagementClientCommonControllerControllerEmptyRequest): Promise<AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportTemplateAvailabilityListGetControllerNestedResponse>
{
  return callApi<AssetManagementClientCommonControllerControllerEmptyRequest, AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportTemplateAvailabilityListGetControllerNestedResponse>("POST", "/Auto/Email/Report/Template/Availability/List/Get", request);
}

export function autoEmailReportViewUpdate(request: AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportViewUpdateControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAutoEmailReportControllerAutoEmailReportViewUpdateControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Auto/Email/Report/View/Update", request);
}

export function budgetForecastAvailabilitiesGet(request: AssetManagementClientAssetManagementPackagesBudgetForecastControllerBudgetForecastAvailabilitiesGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesBudgetForecastControllerBudgetForecastAvailabilitiesGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesBudgetForecastControllerBudgetForecastAvailabilitiesGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesBudgetForecastControllerBudgetForecastAvailabilitiesGetControllerNestedResponse>("POST", "/BudgetForecast/Availabilities/Get", request);
}

export function budgetForecastDetailPdf(request: AssetManagementClientAssetManagementPackagesBudgetForecastControllerBudgetForecastDetailPdfControllerNestedRequest): Promise<void>
{
  return callStreamApi<AssetManagementClientAssetManagementPackagesBudgetForecastControllerBudgetForecastDetailPdfControllerNestedRequest>("POST", "/BudgetForecast/Detail/Pdf", request);
}

export function budgetForecastGet(request: AssetManagementClientAssetManagementPackagesBudgetForecastControllerBudgetForecastGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesBudgetForecastControllerBudgetForecastGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesBudgetForecastControllerBudgetForecastGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesBudgetForecastControllerBudgetForecastGetControllerNestedResponse>("POST", "/BudgetForecast/Get", request);
}

export function budgetForecastPdf(request: AssetManagementClientAssetManagementPackagesBudgetForecastControllerBudgetForecastPdfControllerNestedRequest): Promise<void>
{
  return callStreamApi<AssetManagementClientAssetManagementPackagesBudgetForecastControllerBudgetForecastPdfControllerNestedRequest>("POST", "/BudgetForecast/Pdf", request);
}

export function contractAgreementList(request: AssetManagementClientAssetManagementPackagesContractControllerContractAgreementListControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesContractControllerContractAgreementListControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesContractControllerContractAgreementListControllerNestedRequest, AssetManagementClientAssetManagementPackagesContractControllerContractAgreementListControllerNestedResponse>("POST", "/Contract/Agreement/List", request);
}

export function contractAvailabilitiesGet(request: AssetManagementClientAssetManagementPackagesContractControllerContractAvailabilitiesGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesContractControllerContractAvailabilitiesGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesContractControllerContractAvailabilitiesGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesContractControllerContractAvailabilitiesGetControllerNestedResponse>("POST", "/Contract/Availabilities/Get", request);
}

export function contractCreate(request: AssetManagementClientAssetManagementPackagesContractControllerContractCreateControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesContractControllerContractCreateControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesContractControllerContractCreateControllerNestedRequest, AssetManagementClientAssetManagementPackagesContractControllerContractCreateControllerNestedResponse>("POST", "/Contract/Create", request);
}

export function contractDelete(request: AssetManagementClientAssetManagementPackagesContractControllerContractDeleteControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesContractControllerContractDeleteControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Contract/Delete", request);
}

export function contractGet(request: AssetManagementClientAssetManagementPackagesContractControllerContractGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesContractControllerContractGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesContractControllerContractGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesContractControllerContractGetControllerNestedResponse>("POST", "/Contract/Get", request);
}

export function contractList(request: AssetManagementClientAssetManagementPackagesContractControllerContractListControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesContractControllerContractListControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesContractControllerContractListControllerNestedRequest, AssetManagementClientAssetManagementPackagesContractControllerContractListControllerNestedResponse>("POST", "/Contract/List", request);
}

export function contractListCsv(request: AssetManagementClientAssetManagementPackagesContractControllerContractListCsvControllerNestedRequest): Promise<void>
{
  return callStreamApi<AssetManagementClientAssetManagementPackagesContractControllerContractListCsvControllerNestedRequest>("POST", "/Contract/List/Csv", request);
}

export function contractListPdf(request: AssetManagementClientAssetManagementPackagesContractControllerContractListPdfControllerNestedRequest): Promise<void>
{
  return callStreamApi<AssetManagementClientAssetManagementPackagesContractControllerContractListPdfControllerNestedRequest>("POST", "/Contract/List/Pdf", request);
}

export function contractListSpreadsheet(request: AssetManagementClientAssetManagementPackagesContractControllerContractListSpreadsheetControllerNestedRequest): Promise<void>
{
  return callStreamApi<AssetManagementClientAssetManagementPackagesContractControllerContractListSpreadsheetControllerNestedRequest>("POST", "/Contract/List/Spreadsheet", request);
}

export function contractSync(request: AssetManagementClientAssetManagementPackagesContractControllerContractSyncControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesContractControllerContractSyncControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Contract/Sync", request);
}

export function contractSyncGet(request: AssetManagementClientAssetManagementPackagesContractPackagesSyncControllerContractSyncGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesContractPackagesSyncControllerContractSyncGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesContractPackagesSyncControllerContractSyncGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesContractPackagesSyncControllerContractSyncGetControllerNestedResponse>("POST", "/Contract/Sync/Get", request);
}

export function contractSyncSchedule(request: AssetManagementClientBeastClientCommonControllerControllerEmptyRequest): Promise<AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientBeastClientCommonControllerControllerEmptyRequest, AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>("POST", "/Contract/Sync/Schedule", request);
}

export function contractSyncToggle(request: AssetManagementClientAssetManagementPackagesContractPackagesSyncControllerContractSyncToggleControllerNestedRequest): Promise<AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesContractPackagesSyncControllerContractSyncToggleControllerNestedRequest, AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>("POST", "/Contract/Sync/Toggle", request);
}

export function contractTemplateAvailabilitiesGet(request: AssetManagementClientCommonControllerControllerEmptyRequest): Promise<AssetManagementClientAssetManagementPackagesContractPackagesTemplateControllerContractTemplateAvailabilitiesGetControllerNestedResponse>
{
  return callApi<AssetManagementClientCommonControllerControllerEmptyRequest, AssetManagementClientAssetManagementPackagesContractPackagesTemplateControllerContractTemplateAvailabilitiesGetControllerNestedResponse>("POST", "/Contract/Template/Availabilities/Get", request);
}

export function contractTemplateCreate(request: AssetManagementClientAssetManagementPackagesContractPackagesTemplateControllerContractTemplateCreateControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesContractPackagesTemplateControllerContractTemplateCreateControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Contract/Template/Create", request);
}

export function contractTemplateGet(request: AssetManagementClientAssetManagementPackagesContractPackagesTemplateControllerContractTemplateGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesContractPackagesTemplateControllerContractTemplateGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesContractPackagesTemplateControllerContractTemplateGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesContractPackagesTemplateControllerContractTemplateGetControllerNestedResponse>("POST", "/Contract/Template/Get", request);
}

export function contractUpdate(request: AssetManagementClientAssetManagementPackagesContractControllerContractUpdateControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesContractControllerContractUpdateControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Contract/Update", request);
}

export function deviceReportCustomerPdf(request: AssetManagementClientAssetManagementPackagesAssetHardwarePackagesDeviceControllerHardwareLifecycleReportPdfControllerNestedRequest): Promise<void>
{
  return callStreamApi<AssetManagementClientAssetManagementPackagesAssetHardwarePackagesDeviceControllerHardwareLifecycleReportPdfControllerNestedRequest>("POST", "/Device/Report/Customer/Pdf", request);
}

export function deviceReportCustomerSettingsGet(request: AssetManagementClientAssetManagementPackagesAssetHardwarePackagesDeviceControllerHardwareLifecycleReportSettingsGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesAssetHardwarePackagesDeviceControllerHardwareLifecycleReportSettingsGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAssetHardwarePackagesDeviceControllerHardwareLifecycleReportSettingsGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesAssetHardwarePackagesDeviceControllerHardwareLifecycleReportSettingsGetControllerNestedResponse>("POST", "/Device/Report/Customer/Settings/Get", request);
}

export function disposalAddressValidate(request: AssetManagementClientAssetManagementPackagesDisposalDisposalAddressValidateControllerNestedRequest): Promise<AssetManagementClientPrimitivesResultsDisposalAddressValidateControllerNestedResponse_ApiError_Result>
{
  return callApi<AssetManagementClientAssetManagementPackagesDisposalDisposalAddressValidateControllerNestedRequest, AssetManagementClientPrimitivesResultsDisposalAddressValidateControllerNestedResponse_ApiError_Result>("POST", "/Disposal/Address/Validate", request);
}

export function disposalAssetTypeAvailabilitiesGet(request: AssetManagementClientBeastClientCommonControllerControllerEmptyRequest): Promise<AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalAssetTypeControllerDisposalAssetTypeAvailabilitiesGetControllerNestedResponse>
{
  return callApi<AssetManagementClientBeastClientCommonControllerControllerEmptyRequest, AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalAssetTypeControllerDisposalAssetTypeAvailabilitiesGetControllerNestedResponse>("POST", "/Disposal/Asset/Type/Availabilities/Get", request);
}

export function disposalCartUpsert(request: AssetManagementClientAssetManagementPackagesDisposalPackagesCartDisposalCartUpsertControllerNestedRequest): Promise<AssetManagementClientPrimitivesResultsOk>
{
  return callApi<AssetManagementClientAssetManagementPackagesDisposalPackagesCartDisposalCartUpsertControllerNestedRequest, AssetManagementClientPrimitivesResultsOk>("POST", "/Disposal/Cart/Upsert", request);
}

export function disposalCheckoutBulk(request: AssetManagementClientAssetManagementPackagesDisposalPackagesCheckoutPackagesDisposalCheckoutBulkControllerDisposalCheckoutBulkControllerNestedRequest): Promise<AssetManagementClientPrimitivesResultsDisposalCheckoutBulkControllerNestedResponse_ApiError_Result>
{
  return callApi<AssetManagementClientAssetManagementPackagesDisposalPackagesCheckoutPackagesDisposalCheckoutBulkControllerDisposalCheckoutBulkControllerNestedRequest, AssetManagementClientPrimitivesResultsDisposalCheckoutBulkControllerNestedResponse_ApiError_Result>("POST", "/Disposal/Checkout/Bulk", request);
}

export function disposalCheckoutDataGet(request: AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest): Promise<AssetManagementClientAssetManagementPackagesDisposalPackagesCheckoutControllerDisposalCheckoutDataGetControllerNestedResponse>
{
  return callApi<AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest, AssetManagementClientAssetManagementPackagesDisposalPackagesCheckoutControllerDisposalCheckoutDataGetControllerNestedResponse>("POST", "/Disposal/Checkout/Data/Get", request);
}

export function disposalCheckoutSelf(request: AssetManagementClientAssetManagementPackagesDisposalPackagesCheckoutPackagesDisposalCheckoutSelfControllerDisposalCheckoutSelfControllerNestedRequest): Promise<AssetManagementClientPrimitivesResultsDisposalCheckoutSelfControllerNestedResponse_ApiError_Result>
{
  return callApi<AssetManagementClientAssetManagementPackagesDisposalPackagesCheckoutPackagesDisposalCheckoutSelfControllerDisposalCheckoutSelfControllerNestedRequest, AssetManagementClientPrimitivesResultsDisposalCheckoutSelfControllerNestedResponse_ApiError_Result>("POST", "/Disposal/Checkout/Self", request);
}

export function disposalCouponValidate(request: AssetManagementClientAssetManagementPackagesDisposalPackagesCouponDisposalCouponCodeValidateControllerNestedRequest): Promise<AssetManagementClientPrimitivesResultsDisposalCouponCodeValidateControllerNestedResponse_ErrorPayload_Result>
{
  return callApi<AssetManagementClientAssetManagementPackagesDisposalPackagesCouponDisposalCouponCodeValidateControllerNestedRequest, AssetManagementClientPrimitivesResultsDisposalCouponCodeValidateControllerNestedResponse_ErrorPayload_Result>("POST", "/Disposal/Coupon/Validate", request);
}

export function disposalOrderAssetOrganizationAssign(request: AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderAssetOrganizationAssignControllerNestedRequest): Promise<AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderAssetOrganizationAssignControllerNestedRequest, AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>("POST", "/Disposal/Order/Asset/Organization/Assign", request);
}

export function disposalOrderAssetOrganizationUnassignedList(request: AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderAssetOrganizationUnassignedListControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderAssetOrganizationUnassignedListControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderAssetOrganizationUnassignedListControllerNestedRequest, AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderAssetOrganizationUnassignedListControllerNestedResponse>("POST", "/Disposal/Order/Asset/Organization/Unassigned/List", request);
}

export function disposalOrderInfo(request: AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderInfoGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderInfoGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderInfoGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderInfoGetControllerNestedResponse>("POST", "/Disposal/Order/Info", request);
}

export function disposalOrderList(request: AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest): Promise<AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderListControllerNestedResponse>
{
  return callApi<AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest, AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderListControllerNestedResponse>("POST", "/Disposal/Order/List", request);
}

export function disposalOrderPackagePurchase(request: AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPackagePurchaseControllerNestedRequest): Promise<AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPackagePurchaseControllerNestedRequest, AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>("POST", "/Disposal/Order/Package/Purchase", request);
}

/**
 * Get the progress of a currently running DisposalOrderPackagesLabelWorker
 */
export function disposalOrderPackagesLabelingProgressGet(request: AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPackagesLabelingProgressControllerNestedRequest): Promise<AssetManagementClientPrimitivesResultsProgressResponse_ProgressError_Result>
{
  return callApi<AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPackagesLabelingProgressControllerNestedRequest, AssetManagementClientPrimitivesResultsProgressResponse_ProgressError_Result>("POST", "/Disposal/Order/PackagesLabelingProgress/Get", request);
}

export function disposalOrderPdfs(request: AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPdfsControllerNestedRequest): Promise<void>
{
  return callStreamApi<AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPdfsControllerNestedRequest>("POST", "/Disposal/Order/Pdfs", request);
}

export function disposalOrderPickupBulkAvailabilitySchedule(request: AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPickupBulkAvailabilityScheduleControllerNestedRequest): Promise<AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPickupBulkAvailabilityScheduleControllerNestedRequest, AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>("POST", "/Disposal/Order/Pickup/Bulk/Availability/Schedule", request);
}

export function disposalOrderPickupCancel(request: AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPickupCancelControllerNestedRequest): Promise<AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPickupCancelControllerNestedRequest, AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>("POST", "/Disposal/Order/Pickup/Cancel", request);
}

export function disposalOrderPickupImageList(request: AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPickupImageListControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPickupImageListControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPickupImageListControllerNestedRequest, AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPickupImageListControllerNestedResponse>("POST", "/Disposal/Order/Pickup/Image/List", request);
}

export function disposalOrderPickupImageUpload(request: AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPickupImageUploadControllerNestedRequest): Promise<AssetManagementClientPrimitivesResultsDisposalOrderPickupImageUploadControllerNestedResponse_DisposalOrderPickupImageUploadError_Result>
{
  return callApi<AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPickupImageUploadControllerNestedRequest, AssetManagementClientPrimitivesResultsDisposalOrderPickupImageUploadControllerNestedResponse_DisposalOrderPickupImageUploadError_Result>("POST", "/Disposal/Order/Pickup/Image/Upload", request);
}

export function disposalOrderPickupSchedule(request: AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPickupScheduleControllerNestedRequest): Promise<AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderPickupScheduleControllerNestedRequest, AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>("POST", "/Disposal/Order/Pickup/Schedule", request);
}

export function disposalOrderShippingLabelGet(request: AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderShippingLabelGetControllerNestedRequest): Promise<void>
{
  return callStreamApi<AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderShippingLabelGetControllerNestedRequest>("POST", "/Disposal/Order/Shipping/Label/Get", request);
}

export function disposalOrderSpreadsheet(request: AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderInternalSpreadsheetControllerNestedRequest): Promise<void>
{
  return callStreamApi<AssetManagementClientAssetManagementPackagesDisposalPackagesDisposalOrderControllerDisposalOrderInternalSpreadsheetControllerNestedRequest>("POST", "/Disposal/Order/Spreadsheet", request);
}

export function dmiScoreScope(request: AssetManagementClientAssetManagementPackagesDmiControllerScopeDmiScoreControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesDmiControllerScopeDmiScoreControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesDmiControllerScopeDmiScoreControllerNestedRequest, AssetManagementClientAssetManagementPackagesDmiControllerScopeDmiScoreControllerNestedResponse>("POST", "/Dmi/Score/Scope", request);
}

export function dmiTrendGet(request: AssetManagementClientAssetManagementPackagesDmiControllerDmiTrendGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesDmiControllerDmiTrendGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesDmiControllerDmiTrendGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesDmiControllerDmiTrendGetControllerNestedResponse>("POST", "/Dmi/Trend/Get", request);
}

export function dmiWidgetScoreGet(request: AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest): Promise<AssetManagementClientDashboardSdkModelDmiScoreComponentNestedDmiScoreDto_SimplePrerequisiteComponentNestedSimplePrerequisiteDto_WidgetResponse>
{
  return callApi<AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest, AssetManagementClientDashboardSdkModelDmiScoreComponentNestedDmiScoreDto_SimplePrerequisiteComponentNestedSimplePrerequisiteDto_WidgetResponse>("POST", "/Dmi/Widget/Score/Get", request);
}

export function featureAccessInfoGet(request: AssetManagementClientBeastClientCommonControllerControllerEmptyRequest): Promise<AssetManagementClientAssetManagementPackagesFeatureControllerFeatureAccessInfoGetControllerNestedResponse>
{
  return callApi<AssetManagementClientBeastClientCommonControllerControllerEmptyRequest, AssetManagementClientAssetManagementPackagesFeatureControllerFeatureAccessInfoGetControllerNestedResponse>("POST", "/Feature/Access/Info/Get", request);
}

export function featureAccessOrganizationUnlock(request: AssetManagementClientAssetManagementPackagesFeatureControllerFeatureAccessOrganizationUnlockControllerNestedRequest): Promise<AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesFeatureControllerFeatureAccessOrganizationUnlockControllerNestedRequest, AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>("POST", "/Feature/Access/Organization/Unlock", request);
}

export function featureAccessOrganizationUnlockClientFocusScorecardRoadmap(request: AssetManagementClientAssetManagementPackagesFeatureControllerFeatureAccessOrganizationUnlockClientFocusScorecardRoadmapControllerNestedRequest): Promise<AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesFeatureControllerFeatureAccessOrganizationUnlockClientFocusScorecardRoadmapControllerNestedRequest, AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>("POST", "/Feature/Access/Organization/Unlock/ClientFocusScorecardRoadmap", request);
}

export function hardwareConsoleIdLookup(request: AssetManagementClientAssetManagementPackagesAssetHardwarePackagesConsoleHardwareConsoleIdLookupControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesAssetHardwarePackagesConsoleHardwareConsoleIdLookupControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesAssetHardwarePackagesConsoleHardwareConsoleIdLookupControllerNestedRequest, AssetManagementClientAssetManagementPackagesAssetHardwarePackagesConsoleHardwareConsoleIdLookupControllerNestedResponse>("POST", "/Hardware/Console/Id/Lookup", request);
}

export function hardwareConsoleOrganizationPdf(request: AssetManagementClientAssetManagementPackagesAssetHardwarePackagesDeviceControllerHardwareConsoleOrganizationPdfControllerNestedRequest): Promise<void>
{
  return callStreamApi<AssetManagementClientAssetManagementPackagesAssetHardwarePackagesDeviceControllerHardwareConsoleOrganizationPdfControllerNestedRequest>("POST", "/Hardware/Console/Organization/Pdf", request);
}

export function hardwareReplacementReportAssetTypeSettingsAvailabilitiesGet(request: AssetManagementClientAssetManagementPackagesHardwareReplacementPackagesSettingsGetControllerHardwareReplacementReportAssetTypeSettingsAvailabilitiesGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesHardwareReplacementPackagesSettingsGetControllerHardwareReplacementReportAssetTypeSettingsAvailabilitiesGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesHardwareReplacementPackagesSettingsGetControllerHardwareReplacementReportAssetTypeSettingsAvailabilitiesGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesHardwareReplacementPackagesSettingsGetControllerHardwareReplacementReportAssetTypeSettingsAvailabilitiesGetControllerNestedResponse>("POST", "/HardwareReplacement/ReportAssetType/Settings/Availabilities/Get", request);
}

export function hardwareReplacementSettingsGet(request: AssetManagementClientAssetManagementPackagesHardwareReplacementPackagesSettingsGetControllerHardwareReplacementSettingsGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesHardwareReplacementPackagesSettingsGetControllerHardwareReplacementSettingsGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesHardwareReplacementPackagesSettingsGetControllerHardwareReplacementSettingsGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesHardwareReplacementPackagesSettingsGetControllerHardwareReplacementSettingsGetControllerNestedResponse>("POST", "/HardwareReplacement/Settings/Get", request);
}

export function hardwareSettingsAvailabilitiesGet(request: AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest): Promise<AssetManagementClientAssetManagementPackagesHardwareSettingsControllerHardwareSettingsAvailabilitiesGetControllerNestedResponse>
{
  return callApi<AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest, AssetManagementClientAssetManagementPackagesHardwareSettingsControllerHardwareSettingsAvailabilitiesGetControllerNestedResponse>("POST", "/HardwareSettings/Availabilities/Get", request);
}

export function initiativeAssetAddBulk(request: AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeAssetAddBulkControllerNestedRequest): Promise<AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeAssetAddBulkControllerNestedRequest, AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>("POST", "/Initiative/Asset/Add/Bulk", request);
}

export function initiativeAssetMoveBulk(request: AssetManagementClientAssetManagementPackagesInitiativePackagesInitiativeAssetControllerInitiativeAssetMoveBulkControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesInitiativePackagesInitiativeAssetControllerInitiativeAssetMoveBulkControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesInitiativePackagesInitiativeAssetControllerInitiativeAssetMoveBulkControllerNestedRequest, AssetManagementClientAssetManagementPackagesInitiativePackagesInitiativeAssetControllerInitiativeAssetMoveBulkControllerNestedResponse>("POST", "/Initiative/Asset/Move/Bulk", request);
}

export function initiativeAvailabilitiesGet(request: AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeAvailabilitiesGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeAvailabilitiesGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeAvailabilitiesGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeAvailabilitiesGetControllerNestedResponse>("POST", "/Initiative/Availabilities/Get", request);
}

export function initiativeCreate(request: AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeCreateControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeCreateControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeCreateControllerNestedRequest, AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeCreateControllerNestedResponse>("POST", "/Initiative/Create", request);
}

export function initiativeDelete(request: AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeDeleteControllerNestedRequest): Promise<AssetManagementClientPrimitivesResultsOk>
{
  return callApi<AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeDeleteControllerNestedRequest, AssetManagementClientPrimitivesResultsOk>("POST", "/Initiative/Delete", request);
}

export function initiativeDraftCreate(request: AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeDraftCreateControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeDraftCreateControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeDraftCreateControllerNestedRequest, AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeDraftCreateControllerNestedResponse>("POST", "/Initiative/Draft/Create", request);
}

export function initiativeEdit(request: AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeEditControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeEditControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeEditControllerNestedRequest, AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeEditControllerNestedResponse>("POST", "/Initiative/Edit", request);
}

export function initiativeEngagementActionUnlink(request: AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeEngagementActionUnlinkControllerNestedRequest): Promise<AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeEngagementActionUnlinkControllerNestedRequest, AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>("POST", "/Initiative/Engagement/Action/Unlink", request);
}

export function initiativeEngagementActionLinkBulk(request: AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeEngagementActionLinkBulkControllerNestedRequest): Promise<AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeEngagementActionLinkBulkControllerNestedRequest, AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>("POST", "/Initiative/EngagementAction/Link/Bulk", request);
}

export function initiativeGet(request: AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeGetControllerNestedResponse>("POST", "/Initiative/Get", request);
}

export function initiativeList(request: AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeListControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeListControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeListControllerNestedRequest, AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeListControllerNestedResponse>("POST", "/Initiative/List", request);
}

export function initiativeOverviewDashboardListGet(request: AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeOverviewDashboardListGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeOverviewDashboardListGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeOverviewDashboardListGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeOverviewDashboardListGetControllerNestedResponse>("POST", "/Initiative/Overview/Dashboard/List/Get", request);
}

export function initiativePdf(request: AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativePdfControllerNestedRequest): Promise<void>
{
  return callStreamApi<AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativePdfControllerNestedRequest>("POST", "/Initiative/Pdf", request);
}

export function initiativeScheduleUpdate(request: AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeScheduleUpdateControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeScheduleUpdateControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeScheduleUpdateControllerNestedRequest, AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeScheduleUpdateControllerNestedResponse>("POST", "/Initiative/Schedule/Update", request);
}

export function initiativeStatusUpdate(request: AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeStatusUpdateControllerNestedRequest): Promise<AssetManagementClientPrimitivesResultsOk>
{
  return callApi<AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeStatusUpdateControllerNestedRequest, AssetManagementClientPrimitivesResultsOk>("POST", "/Initiative/Status/Update", request);
}

export function initiativeTemplateAvailability(request: AssetManagementClientAssetManagementPackagesInitiativePackagesInitiativeTemplateControllerInitiativeTemplateAvailabilityControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesInitiativePackagesInitiativeTemplateControllerInitiativeTemplateAvailabilityControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesInitiativePackagesInitiativeTemplateControllerInitiativeTemplateAvailabilityControllerNestedRequest, AssetManagementClientAssetManagementPackagesInitiativePackagesInitiativeTemplateControllerInitiativeTemplateAvailabilityControllerNestedResponse>("POST", "/Initiative/Template/Availability", request);
}

export function initiativeTemplateCreate(request: AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeTemplateCreateControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeTemplateCreateControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeTemplateCreateControllerNestedRequest, AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeTemplateCreateControllerNestedResponse>("POST", "/Initiative/Template/Create", request);
}

export function initiativeTemplateDelete(request: AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeTemplateDeleteControllerNestedRequest): Promise<AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeTemplateDeleteControllerNestedRequest, AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>("POST", "/Initiative/Template/Delete", request);
}

export function initiativeTemplateUpdate(request: AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeTemplateUpdateControllerNestedRequest): Promise<AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesInitiativeControllerInitiativeTemplateUpdateControllerNestedRequest, AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>("POST", "/Initiative/Template/Update", request);
}

export function insightsCreate(request: AssetManagementClientAssetManagementPackagesInsightsControllerInsightCreateControllerNestedRequest): Promise<AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesInsightsControllerInsightCreateControllerNestedRequest, AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>("POST", "/Insights/Create", request);
}

export function insightsCreateForm(request: AssetManagementClientCommonControllerControllerEmptyRequest): Promise<AssetManagementClientAssetManagementPackagesInsightsControllerInsightCreateFormMetadataControllerNestedResponse>
{
  return callApi<AssetManagementClientCommonControllerControllerEmptyRequest, AssetManagementClientAssetManagementPackagesInsightsControllerInsightCreateFormMetadataControllerNestedResponse>("POST", "/Insights/Create/Form", request);
}

export function insightsCustomList(request: AssetManagementClientAssetManagementPackagesInsightsControllerInsightCustomListControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesInsightsControllerInsightCustomListControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesInsightsControllerInsightCustomListControllerNestedRequest, AssetManagementClientAssetManagementPackagesInsightsControllerInsightCustomListControllerNestedResponse>("POST", "/Insights/Custom/List", request);
}

export function insightsDelete(request: AssetManagementClientAssetManagementPackagesInsightsControllerInsightDeleteControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesInsightsControllerInsightDeleteControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Insights/Delete", request);
}

export function insightsListGet(request: AssetManagementClientAssetManagementPackagesInsightsControllerInsightListGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesInsightsControllerInsightListGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesInsightsControllerInsightListGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesInsightsControllerInsightListGetControllerNestedResponse>("POST", "/Insights/List/Get", request);
}

export function insightsWidgetCount(insightUniqueId: string, request: object): Promise<AssetManagementClientDashboardSdkModelSimpleCountComponentNestedSimpleCountDto_SimplePrerequisiteComponentNestedSimplePrerequisiteDto_WidgetResponse>
{
  return callApi<object, AssetManagementClientDashboardSdkModelSimpleCountComponentNestedSimpleCountDto_SimplePrerequisiteComponentNestedSimplePrerequisiteDto_WidgetResponse>("POST", `/Insights/Widget/${insightUniqueId}/Count`, request);
}

export function insightsWidgetGraph(insightUniqueId: string, request: object): Promise<AssetManagementClientDashboardSdkModelAreaChartComponentNestedAreaChartDto_SimplePrerequisiteComponentNestedSimplePrerequisiteDto_WidgetResponse>
{
  return callApi<object, AssetManagementClientDashboardSdkModelAreaChartComponentNestedAreaChartDto_SimplePrerequisiteComponentNestedSimplePrerequisiteDto_WidgetResponse>("POST", `/Insights/Widget/${insightUniqueId}/Graph`, request);
}

export function insightsWidgetTopOffenders(insightUniqueId: string, request: object): Promise<AssetManagementClientDashboardSdkModelSimpleTableComponentNestedSimpleTableDto_SimplePrerequisiteComponentNestedSimplePrerequisiteDto_WidgetResponse>
{
  return callApi<object, AssetManagementClientDashboardSdkModelSimpleTableComponentNestedSimpleTableDto_SimplePrerequisiteComponentNestedSimplePrerequisiteDto_WidgetResponse>("POST", `/Insights/Widget/${insightUniqueId}/TopOffenders`, request);
}

export function integrationDelete(request: AssetManagementClientAssetManagementPackagesIntegrationPackagesDeleteControllerIntegrationDeleteNestedRequest): Promise<AssetManagementClientPrimitivesResultsEmptyResponse_IntegrationDeleteError_Result>
{
  return callApi<AssetManagementClientAssetManagementPackagesIntegrationPackagesDeleteControllerIntegrationDeleteNestedRequest, AssetManagementClientPrimitivesResultsEmptyResponse_IntegrationDeleteError_Result>("POST", "/Integration/Delete", request);
}

export function integrationSetupGet(request: AssetManagementClientAssetManagementPackagesIntegrationIntegrationPageControllerIntegrationSetupGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesIntegrationIntegrationPageControllerIntegrationSetupGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesIntegrationIntegrationPageControllerIntegrationSetupGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesIntegrationIntegrationPageControllerIntegrationSetupGetControllerNestedResponse>("POST", "/Integration/Setup/Get", request);
}

export function integrationSetupUpsert(request: AssetManagementClientAssetManagementPackagesIntegrationIntegrationPageControllerIntegrationSetupUpsertControllerNestedRequest): Promise<AssetManagementClientPrimitivesResultsIntegrationSetupUpsertControllerNestedResponse_IntegrationSetupError_Result>
{
  return callApi<AssetManagementClientAssetManagementPackagesIntegrationIntegrationPageControllerIntegrationSetupUpsertControllerNestedRequest, AssetManagementClientPrimitivesResultsIntegrationSetupUpsertControllerNestedResponse_IntegrationSetupError_Result>("POST", "/Integration/Setup/Upsert", request);
}

export function integrationSyncStatus(request: AssetManagementClientAssetManagementPackagesIntegrationPackagesSetupControllerIntegrationSyncNotificationSubscriptionStatusControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesIntegrationPackagesSetupControllerIntegrationSyncNotificationSubscriptionStatusControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesIntegrationPackagesSetupControllerIntegrationSyncNotificationSubscriptionStatusControllerNestedRequest, AssetManagementClientAssetManagementPackagesIntegrationPackagesSetupControllerIntegrationSyncNotificationSubscriptionStatusControllerNestedResponse>("POST", "/Integration/Sync/Status", request);
}

export function integrationSyncNotificationStatus(request: AssetManagementClientAssetManagementPackagesIntegrationPackagesNotificationControllerIntegrationSyncNotificationSubscriptionStatusControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesIntegrationPackagesNotificationControllerIntegrationSyncNotificationSubscriptionStatusControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesIntegrationPackagesNotificationControllerIntegrationSyncNotificationSubscriptionStatusControllerNestedRequest, AssetManagementClientAssetManagementPackagesIntegrationPackagesNotificationControllerIntegrationSyncNotificationSubscriptionStatusControllerNestedResponse>("POST", "/Integration/SyncNotification/Status", request);
}

export function integrationSyncNotificationUpdate(request: AssetManagementClientAssetManagementPackagesIntegrationPackagesNotificationControllerIntegrationSyncNotificationSubscriptionUpdateControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesIntegrationPackagesNotificationControllerIntegrationSyncNotificationSubscriptionUpdateControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Integration/SyncNotification/Update", request);
}

export function integrationProducts(request: AssetManagementClientAssetManagementPackagesIntegrationIntegrationPageControllerIntegrationProductGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesIntegrationIntegrationPageControllerIntegrationProductGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesIntegrationIntegrationPageControllerIntegrationProductGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesIntegrationIntegrationPageControllerIntegrationProductGetControllerNestedResponse>("POST", "/IntegrationProducts", request);
}

export function integrationVendors(request: AssetManagementClientAssetManagementPackagesIntegrationIntegrationPageControllerIntegrationPageControllerNestedRequest): Promise<AssetManagementClientBeastClientBeastIntegrationControllerIntegrationPageIntegrationVendorListNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesIntegrationIntegrationPageControllerIntegrationPageControllerNestedRequest, AssetManagementClientBeastClientBeastIntegrationControllerIntegrationPageIntegrationVendorListNestedResponse>("POST", "/IntegrationVendors", request);
}

/**
 * Get the list of app recognized manufacturer
 */
export function manufacturerBasicInfoList(request: AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest): Promise<AssetManagementClientAssetManagementPackagesManufacturerManufacturerListControllerNestedResponse>
{
  return callApi<AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest, AssetManagementClientAssetManagementPackagesManufacturerManufacturerListControllerNestedResponse>("POST", "/Manufacturer/BasicInfo/List", request);
}

export function organizationAccountTeamMemberAdd(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationAccountTeamMemberOrganizationAccountTeamMemberAddControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationAccountTeamMemberOrganizationAccountTeamMemberAddControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Organization/AccountTeam/Member/Add", request);
}

export function organizationAccountTeamMemberEdit(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationAccountTeamMemberOrganizationAccountTeamMemberEditControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationAccountTeamMemberOrganizationAccountTeamMemberEditControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Organization/AccountTeam/Member/Edit", request);
}

export function organizationAccountTeamMemberListGet(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationAccountTeamMemberOrganizationAccountTeamMemberListControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationAccountTeamMemberOrganizationAccountTeamMemberListControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationAccountTeamMemberOrganizationAccountTeamMemberListControllerNestedRequest, AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationAccountTeamMemberOrganizationAccountTeamMemberListControllerNestedResponse>("POST", "/Organization/AccountTeam/Member/List/Get", request);
}

export function organizationAccountTeamMemberRemove(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationAccountTeamMemberOrganizationAccountTeamMemberRemoveControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationAccountTeamMemberOrganizationAccountTeamMemberRemoveControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Organization/AccountTeam/Member/Remove", request);
}

export function organizationAccountTeamMemberRoleAdd(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationAccountTeamMemberPackagesOrganizationAccountTeamMemberRoleControllerOrganizationAccountTeamMemberRoleAddControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationAccountTeamMemberPackagesOrganizationAccountTeamMemberRoleControllerOrganizationAccountTeamMemberRoleAddControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Organization/AccountTeam/Member/Role/Add", request);
}

export function organizationAccountTeamMemberRoleDelete(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationAccountTeamMemberPackagesOrganizationAccountTeamMemberRoleControllerOrganizationAccountTeamMemberRoleDeleteControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationAccountTeamMemberPackagesOrganizationAccountTeamMemberRoleControllerOrganizationAccountTeamMemberRoleDeleteControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Organization/AccountTeam/Member/Role/Delete", request);
}

/**
 * Get the organization's basic info for an organization
 */
export function organizationBasicInfoGet(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesBasicInfoOrganizationBasicInfoGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesOrganizationPackagesBasicInfoOrganizationBasicInfoGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesBasicInfoOrganizationBasicInfoGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesOrganizationPackagesBasicInfoOrganizationBasicInfoGetControllerNestedResponse>("POST", "/Organization/BasicInfo/Get", request);
}

/**
 * Get the list of active organizations basic info for an account
 */
export function organizationBasicInfoList(request: AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest): Promise<AssetManagementClientAssetManagementPackagesOrganizationPackagesBasicInfoOrganizationBasicInfoListControllerNestedResponse>
{
  return callApi<AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest, AssetManagementClientAssetManagementPackagesOrganizationPackagesBasicInfoOrganizationBasicInfoListControllerNestedResponse>("POST", "/Organization/BasicInfo/List", request);
}

export function organizationClientsWidgetsCount(request: AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest): Promise<AssetManagementClientDashboardSdkModelSimpleCountComponentNestedSimpleCountDto_SimplePrerequisiteComponentNestedSimplePrerequisiteDto_WidgetResponse>
{
  return callApi<AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest, AssetManagementClientDashboardSdkModelSimpleCountComponentNestedSimpleCountDto_SimplePrerequisiteComponentNestedSimplePrerequisiteDto_WidgetResponse>("POST", "/Organization/Clients/Widgets/Count", request);
}

export function organizationContactListGet(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationContactOrganizationContactListGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationContactOrganizationContactListGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationContactOrganizationContactListGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationContactOrganizationContactListGetControllerNestedResponse>("POST", "/Organization/Contact/List/Get", request);
}

export function organizationEngagementActionCreate(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionCreateControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionCreateControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionCreateControllerNestedRequest, AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionCreateControllerNestedResponse>("POST", "/Organization/Engagement/Action/Create", request);
}

export function organizationEngagementActionDelete(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionDeleteControllerNestedRequest): Promise<AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionDeleteControllerNestedRequest, AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>("POST", "/Organization/Engagement/Action/Delete", request);
}

export function organizationEngagementActionInitiativeList(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionInitiativeLinkListControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionInitiativeLinkListControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionInitiativeLinkListControllerNestedRequest, AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionInitiativeLinkListControllerNestedResponse>("POST", "/Organization/Engagement/Action/Initiative/List", request);
}

export function organizationEngagementActionListDashboardGet(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionListDashboardGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionListDashboardGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionListDashboardGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionListDashboardGetControllerNestedResponse>("POST", "/Organization/Engagement/Action/List/Dashboard/Get", request);
}

export function organizationEngagementActionListGet(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionListGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionListGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionListGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionListGetControllerNestedResponse>("POST", "/Organization/Engagement/Action/List/Get", request);
}

export function organizationEngagementActionMarkCompleted(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionMarkCompletedControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionMarkCompletedControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionMarkCompletedControllerNestedRequest, AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionMarkCompletedControllerNestedResponse>("POST", "/Organization/Engagement/Action/Mark/Completed", request);
}

export function organizationEngagementActionMarkIncomplete(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionMarkIncompleteControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionMarkIncompleteControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionMarkIncompleteControllerNestedRequest, AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionMarkIncompleteControllerNestedResponse>("POST", "/Organization/Engagement/Action/Mark/Incomplete", request);
}

export function organizationEngagementActionPin(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionPinControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionPinControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionPinControllerNestedRequest, AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionPinControllerNestedResponse>("POST", "/Organization/Engagement/Action/Pin", request);
}

export function organizationEngagementActionTicketCreate(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionTicketCreateControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionTicketCreateControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Organization/Engagement/Action/Ticket/Create", request);
}

export function organizationEngagementActionTicketUnlink(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionTicketUnlinkControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionTicketUnlinkControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Organization/Engagement/Action/Ticket/Unlink", request);
}

export function organizationEngagementActionUnpin(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionUnpinControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionUnpinControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionUnpinControllerNestedRequest, AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionUnpinControllerNestedResponse>("POST", "/Organization/Engagement/Action/Unpin", request);
}

export function organizationEngagementActionUpdate(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionUpdateControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionUpdateControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionUpdateControllerNestedRequest, AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementActionControllerEngagementActionUpdateControllerNestedResponse>("POST", "/Organization/Engagement/Action/Update", request);
}

export function organizationEngagementNoteArchive(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteArchiveControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteArchiveControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteArchiveControllerNestedRequest, AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteArchiveControllerNestedResponse>("POST", "/Organization/Engagement/Note/Archive", request);
}

export function organizationEngagementNoteCreate(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteCreateControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteCreateControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteCreateControllerNestedRequest, AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteCreateControllerNestedResponse>("POST", "/Organization/Engagement/Note/Create", request);
}

export function organizationEngagementNoteDashboardListGet(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteDashboardListGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteDashboardListGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteDashboardListGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteDashboardListGetControllerNestedResponse>("POST", "/Organization/Engagement/Note/Dashboard/List/Get", request);
}

export function organizationEngagementNoteListGet(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteListGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteListGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteListGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteListGetControllerNestedResponse>("POST", "/Organization/Engagement/Note/List/Get", request);
}

export function organizationEngagementNotePin(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNotePinControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNotePinControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNotePinControllerNestedRequest, AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNotePinControllerNestedResponse>("POST", "/Organization/Engagement/Note/Pin", request);
}

export function organizationEngagementNoteUnarchive(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteUnarchiveControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteUnarchiveControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteUnarchiveControllerNestedRequest, AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteUnarchiveControllerNestedResponse>("POST", "/Organization/Engagement/Note/Unarchive", request);
}

export function organizationEngagementNoteUnpin(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteUnpinControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteUnpinControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteUnpinControllerNestedRequest, AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementNoteUnpinControllerNestedResponse>("POST", "/Organization/Engagement/Note/Unpin", request);
}

export function organizationEngagementNoteUpdate(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementActionUpdateControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementActionUpdateControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementActionUpdateControllerNestedRequest, AssetManagementClientAssetManagementPackagesOrganizationPackagesEngagementPackagesEngagementNoteControllerEngagementActionUpdateControllerNestedResponse>("POST", "/Organization/Engagement/Note/Update", request);
}

export function organizationKeyContactAdd(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationKeyContactOrganizationKeyContactAddControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationKeyContactOrganizationKeyContactAddControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Organization/KeyContact/Add", request);
}

export function organizationKeyContactListGet(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationKeyContactOrganizationKeyContactListGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationKeyContactOrganizationKeyContactListGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationKeyContactOrganizationKeyContactListGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationKeyContactOrganizationKeyContactListGetControllerNestedResponse>("POST", "/Organization/KeyContact/List/Get", request);
}

export function organizationKeyContactRemove(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationKeyContactOrganizationKeyContactRemoveControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationKeyContactOrganizationKeyContactRemoveControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Organization/KeyContact/Remove", request);
}

/**
 * Create a link for one or many organizations.
 * Returns an error if the organizations getting linked do not exists or if a link already exists.
 */
export function organizationLink(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesLinkControllerOrganizationLinkControllerNestedRequest): Promise<AssetManagementClientPrimitivesResultsOrganizationLinkControllerNestedResponse_OrganizationLinkError_Result>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesLinkControllerOrganizationLinkControllerNestedRequest, AssetManagementClientPrimitivesResultsOrganizationLinkControllerNestedResponse_OrganizationLinkError_Result>("POST", "/Organization/Link", request);
}

/**
 * Get the list of Links related to an Organization
 */
export function organizationLinkList(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesLinkPackagesListControllerOrganizationLinkListControllerNestedRequest): Promise<AssetManagementClientPrimitivesResultsOrganizationLinkListControllerNestedResponse_OrganizationLinkError_Result>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesLinkPackagesListControllerOrganizationLinkListControllerNestedRequest, AssetManagementClientPrimitivesResultsOrganizationLinkListControllerNestedResponse_OrganizationLinkError_Result>("POST", "/Organization/Link/List", request);
}

/**
 * Get the progress of an currently running link.
 */
export function organizationLinkProgressGet(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesLinkPackagesProgressControllerOrganizationLinkProgressGetControllerNestedRequest): Promise<AssetManagementClientPrimitivesResultsProgressResponse_ProgressError_Result>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesLinkPackagesProgressControllerOrganizationLinkProgressGetControllerNestedRequest, AssetManagementClientPrimitivesResultsProgressResponse_ProgressError_Result>("POST", "/Organization/Link/Progress/Get", request);
}

/**
 * Get a list of Links by searching a organization name
 */
export function organizationLinkSearch(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesLinkPackagesSearchControllerOrganizationLinkAvailabilitySearchControllerNestedRequest): Promise<AssetManagementClientPrimitivesResultsOrganizationLinkAvailabilitySearchControllerNestedResponse_OrganizationLinkError_Result>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesLinkPackagesSearchControllerOrganizationLinkAvailabilitySearchControllerNestedRequest, AssetManagementClientPrimitivesResultsOrganizationLinkAvailabilitySearchControllerNestedResponse_OrganizationLinkError_Result>("POST", "/Organization/Link/Search", request);
}

/**
 * Create a link for one or many organizations.
 * Returns an error if the organizations getting linked do not exists or if a link already exists.
 */
export function organizationRename(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesRenameControllerOrganizationRenameControllerNestedRequest): Promise<AssetManagementClientPrimitivesResultsOrganizationRenameControllerNestedResponse_OrganizationRenameError_Result>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesRenameControllerOrganizationRenameControllerNestedRequest, AssetManagementClientPrimitivesResultsOrganizationRenameControllerNestedResponse_OrganizationRenameError_Result>("POST", "/Organization/Rename", request);
}

/**
 * Delete a previously create link and restores the organization.
 */
export function organizationUnlink(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesUnlinkControllerOrganizationUnlinkControllerNestedRequest): Promise<AssetManagementClientPrimitivesResultsOrganizationUnlinkControllerNestedResponse_OrganizationUnlinkError_Result>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesUnlinkControllerOrganizationUnlinkControllerNestedRequest, AssetManagementClientPrimitivesResultsOrganizationUnlinkControllerNestedResponse_OrganizationUnlinkError_Result>("POST", "/Organization/Unlink", request);
}

/**
 * Provides a high-level summary to partners about the state of their feature unlocked organizations if feature access is limited
 */
export function organizationsSummaryFeatureUnlockedSearch(request: AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest): Promise<AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationsSummaryOrganizationsSummaryFeatureUnlockedSearchControllerNestedResponse>
{
  return callApi<AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest, AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationsSummaryOrganizationsSummaryFeatureUnlockedSearchControllerNestedResponse>("POST", "/Organizations/Summary/Feature/Unlocked/Search", request);
}

/**
 * Provides a high-level summary to partners about the state of their organizations
 */
export function organizationsSummaryGet(request: AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest): Promise<AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationsSummaryOrganizationsSummaryGetControllerNestedResponse>
{
  return callApi<AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest, AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationsSummaryOrganizationsSummaryGetControllerNestedResponse>("POST", "/Organizations/Summary/Get", request);
}

/**
 * Provides a high-level summary to partners about the state of their organizations
 */
export function organizationsSummarySearch(request: AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationsSummaryOrganizationsSummarySearchControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationsSummaryOrganizationsSummarySearchControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationsSummaryOrganizationsSummarySearchControllerNestedRequest, AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationsSummaryOrganizationsSummarySearchControllerNestedResponse>("POST", "/Organizations/Summary/Search", request);
}

export function organizationsSummarySearchAvailabilities(request: AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest): Promise<AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationsSummaryPackagesQueryOrganizationsSummarySearchAvailabilitiesNestedResponse>
{
  return callApi<AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest, AssetManagementClientAssetManagementPackagesOrganizationPackagesOrganizationsSummaryPackagesQueryOrganizationsSummarySearchAvailabilitiesNestedResponse>("POST", "/Organizations/Summary/Search/Availabilities", request);
}

export function regionalSettingsAvailabilitiesGet(request: AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest): Promise<AssetManagementClientAssetManagementPackagesRegionalSettingsControllerRegionalSettingsAvailabilitiesGetControllerNestedResponse>
{
  return callApi<AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest, AssetManagementClientAssetManagementPackagesRegionalSettingsControllerRegionalSettingsAvailabilitiesGetControllerNestedResponse>("POST", "/RegionalSettings/Availabilities/Get", request);
}

export function regionalSettingsInfoGet(request: AssetManagementClientAssetManagementPackagesRegionalSettingsControllerRegionalSettingsInfoGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesRegionalSettingsControllerRegionalSettingsInfoGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesRegionalSettingsControllerRegionalSettingsInfoGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesRegionalSettingsControllerRegionalSettingsInfoGetControllerNestedResponse>("POST", "/RegionalSettings/Info/Get", request);
}

export function reportAssetTypeAvailabilities(request: AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest): Promise<AssetManagementClientAssetManagementPackagesAssetReportAssetTypeControllerReportAssetTypeAvailabilitiesNestedResponse>
{
  return callApi<AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest, AssetManagementClientAssetManagementPackagesAssetReportAssetTypeControllerReportAssetTypeAvailabilitiesNestedResponse>("POST", "/ReportAssetType/Availabilities", request);
}

export function roadmapAccountGet(request: AssetManagementClientCommonControllerControllerEmptyRequest): Promise<AssetManagementClientAssetManagementPackagesRoadmapControllerRoadmapAccountGetControllerNestedResponse>
{
  return callApi<AssetManagementClientCommonControllerControllerEmptyRequest, AssetManagementClientAssetManagementPackagesRoadmapControllerRoadmapAccountGetControllerNestedResponse>("POST", "/Roadmap/Account/Get", request);
}

export function roadmapGet(request: AssetManagementClientAssetManagementPackagesRoadmapControllerRoadmapGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesRoadmapControllerRoadmapGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesRoadmapControllerRoadmapGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesRoadmapControllerRoadmapGetControllerNestedResponse>("POST", "/Roadmap/Get", request);
}

export function roadmapPdfNew(request: AssetManagementClientAssetManagementPackagesRoadmapControllerRoadmapPdfNewControllerNestedRequest): Promise<void>
{
  return callStreamApi<AssetManagementClientAssetManagementPackagesRoadmapControllerRoadmapPdfNewControllerNestedRequest>("POST", "/Roadmap/Pdf/New", request);
}

export function scorecardCategoryCreate(request: AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardCategoryControllerScorecardCategoryCreateControllerNestedRequest): Promise<AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardCategoryControllerScorecardCategoryCreateControllerNestedRequest, AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>("POST", "/Scorecard/Category/Create", request);
}

export function scorecardCategoryDelete(request: AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardCategoryControllerScorecardCategoryDeleteWithoutItemsControllerNestedRequest): Promise<AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardCategoryControllerScorecardCategoryDeleteWithoutItemsControllerNestedRequest, AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>("POST", "/Scorecard/Category/Delete", request);
}

export function scorecardCategoryItemsDelete(request: AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardCategoryControllerScorecardCategoryDeleteWithItemsControllerNestedRequest): Promise<AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardCategoryControllerScorecardCategoryDeleteWithItemsControllerNestedRequest, AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>("POST", "/Scorecard/Category/Items/Delete", request);
}

export function scorecardCategoryOrderUpdate(request: AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardCategoryControllerScorecardCategoryOrderUpdateControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardCategoryControllerScorecardCategoryOrderUpdateControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/Scorecard/Category/Order/Update", request);
}

export function scorecardCategoryUpdate(request: AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardCategoryControllerScorecardCategoryUpdateControllerNestedRequest): Promise<AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardCategoryControllerScorecardCategoryUpdateControllerNestedRequest, AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>("POST", "/Scorecard/Category/Update", request);
}

export function scorecardCreate(request: AssetManagementClientAssetManagementPackagesScorecardControllerScorecardCreateControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesScorecardControllerScorecardCreateControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesScorecardControllerScorecardCreateControllerNestedRequest, AssetManagementClientAssetManagementPackagesScorecardControllerScorecardCreateControllerNestedResponse>("POST", "/Scorecard/Create", request);
}

export function scorecardDelete(request: AssetManagementClientAssetManagementPackagesScorecardControllerScorecardDeleteControllerNestedRequest): Promise<AssetManagementClientPrimitivesResultsOk>
{
  return callApi<AssetManagementClientAssetManagementPackagesScorecardControllerScorecardDeleteControllerNestedRequest, AssetManagementClientPrimitivesResultsOk>("POST", "/Scorecard/Delete", request);
}

export function scorecardGet(request: AssetManagementClientAssetManagementPackagesScorecardControllerScorecardLatestGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesScorecardControllerScorecardLatestGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesScorecardControllerScorecardLatestGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesScorecardControllerScorecardLatestGetControllerNestedResponse>("POST", "/Scorecard/Get", request);
}

export function scorecardGetId(request: AssetManagementClientAssetManagementPackagesScorecardControllerScorecardGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesScorecardControllerScorecardGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesScorecardControllerScorecardGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesScorecardControllerScorecardGetControllerNestedResponse>("POST", "/Scorecard/Get/Id", request);
}

export function scorecardItemAutomationRefresh(request: AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardItemControllerScorecardItemAutomationRefreshControllerNestedRequest): Promise<AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardItemControllerScorecardItemAutomationRefreshControllerNestedRequest, AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>("POST", "/Scorecard/Item/Automation/Refresh", request);
}

export function scorecardList(request: AssetManagementClientAssetManagementPackagesScorecardControllerScorecardListControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesScorecardControllerScorecardListControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesScorecardControllerScorecardListControllerNestedRequest, AssetManagementClientAssetManagementPackagesScorecardControllerScorecardListControllerNestedResponse>("POST", "/Scorecard/List", request);
}

export function scorecardPdf(request: AssetManagementClientAssetManagementPackagesScorecardControllerScorecardPdfControllerNestedRequest): Promise<void>
{
  return callStreamApi<AssetManagementClientAssetManagementPackagesScorecardControllerScorecardPdfControllerNestedRequest>("POST", "/Scorecard/Pdf", request);
}

export function scorecardScorecardItemCreate(request: AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardItemControllerScorecardItemCreateControllerNestedRequest): Promise<AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardItemControllerScorecardItemCreateControllerNestedRequest, AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>("POST", "/Scorecard/ScorecardItem/Create", request);
}

export function scorecardScorecardItemOrderUpdate(request: AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardItemControllerScorecardItemOrderUpdateControllerNestedRequest): Promise<AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardItemControllerScorecardItemOrderUpdateControllerNestedRequest, AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>("POST", "/Scorecard/ScorecardItem/Order/Update", request);
}

export function scorecardScorecardItemRemoveBulk(request: AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardItemControllerScorecardItemRemoveBulkControllerNestedRequest): Promise<AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardItemControllerScorecardItemRemoveBulkControllerNestedRequest, AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>("POST", "/Scorecard/ScorecardItem/Remove/Bulk", request);
}

export function scorecardScorecardItemUpdate(request: AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardItemControllerScorecardItemUpdateControllerNestedRequest): Promise<AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesScorecardPackagesScorecardItemControllerScorecardItemUpdateControllerNestedRequest, AssetManagementClientBeastClientCommonControllerControllerEmptyResponse>("POST", "/Scorecard/ScorecardItem/Update", request);
}

export function scorecardUpdate(request: AssetManagementClientAssetManagementPackagesScorecardControllerScorecardUpdateControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesScorecardControllerScorecardUpdateControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesScorecardControllerScorecardUpdateControllerNestedRequest, AssetManagementClientAssetManagementPackagesScorecardControllerScorecardUpdateControllerNestedResponse>("POST", "/Scorecard/Update", request);
}

export function settingsEnable(request: AssetManagementClientAssetManagementPackagesSettingsControllerSettingsEnableControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesSettingsControllerSettingsEnableControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesSettingsControllerSettingsEnableControllerNestedRequest, AssetManagementClientAssetManagementPackagesSettingsControllerSettingsEnableControllerNestedResponse>("POST", "/Settings/Enable", request);
}

export function settingsGet(request: AssetManagementClientAssetManagementPackagesSettingsControllerSettingsGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesSettingsControllerSettingsGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesSettingsControllerSettingsGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesSettingsControllerSettingsGetControllerNestedResponse>("POST", "/Settings/Get", request);
}

export function settingsResetToDefault(request: AssetManagementClientAssetManagementPackagesSettingsControllerSettingsResetToDefaultControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesSettingsControllerSettingsResetToDefaultControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesSettingsControllerSettingsResetToDefaultControllerNestedRequest, AssetManagementClientAssetManagementPackagesSettingsControllerSettingsResetToDefaultControllerNestedResponse>("POST", "/Settings/ResetToDefault", request);
}

export function settingsSave(request: AssetManagementClientAssetManagementPackagesSettingsControllerSettingsSaveControllerNestedRequest): Promise<AssetManagementClientPrimitivesResultsOk>
{
  return callApi<AssetManagementClientAssetManagementPackagesSettingsControllerSettingsSaveControllerNestedRequest, AssetManagementClientPrimitivesResultsOk>("POST", "/Settings/Save", request);
}

export function shareAvailableRecipientsGet(request: AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest): Promise<AssetManagementClientAssetManagementPackagesSharePackagesRecipientsControllerAvailableRecipientsGetControllerNestedResponse>
{
  return callApi<AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest, AssetManagementClientAssetManagementPackagesSharePackagesRecipientsControllerAvailableRecipientsGetControllerNestedResponse>("POST", "/Share/AvailableRecipients/Get", request);
}

export function shareSend(request: AssetManagementClientAssetManagementPackagesSharePackagesSendControllerShareSendControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesSharePackagesSendControllerShareSendControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesSharePackagesSendControllerShareSendControllerNestedRequest, AssetManagementClientAssetManagementPackagesSharePackagesSendControllerShareSendControllerNestedResponse>("POST", "/Share/Send", request);
}

export function strategyAssetSearch(request: AssetManagementClientAssetManagementPackagesStrategyPackagesInsightsAssetSearchControllerAssetSearchControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesStrategyPackagesInsightsAssetSearchControllerAssetSearchControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesStrategyPackagesInsightsAssetSearchControllerAssetSearchControllerNestedRequest, AssetManagementClientAssetManagementPackagesStrategyPackagesInsightsAssetSearchControllerAssetSearchControllerNestedResponse>("POST", "/Strategy/Asset/Search", request);
}

export function strategyDashboardGet(request: AssetManagementClientAssetManagementPackagesStrategyPackagesInsightsControllerInsightDashboardControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesStrategyPackagesInsightsControllerInsightDashboardControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesStrategyPackagesInsightsControllerInsightDashboardControllerNestedRequest, AssetManagementClientAssetManagementPackagesStrategyPackagesInsightsControllerInsightDashboardControllerNestedResponse>("POST", "/Strategy/Dashboard/Get", request);
}

export function strategyWarrantyWidgetsRenewalOpportunity(request: AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest): Promise<AssetManagementClientDashboardSdkModelSimpleTableComponentNestedSimpleTableDto_SimplePrerequisiteComponentNestedSimplePrerequisiteDto_WidgetResponse>
{
  return callApi<AssetManagementClientApiServerPackagesAspNetCoreControllerRequestEmptyRequest, AssetManagementClientDashboardSdkModelSimpleTableComponentNestedSimpleTableDto_SimplePrerequisiteComponentNestedSimplePrerequisiteDto_WidgetResponse>("POST", "/Strategy/Warranty/Widgets/RenewalOpportunity", request);
}

export function ticketCreateFieldAvailabilitiesGet(request: AssetManagementClientAssetManagementPackagesCorePackagesTicketControllerTicketCreateFieldAvailabilitiesGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesCorePackagesTicketControllerTicketCreateFieldAvailabilitiesGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesCorePackagesTicketControllerTicketCreateFieldAvailabilitiesGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesCorePackagesTicketControllerTicketCreateFieldAvailabilitiesGetControllerNestedResponse>("POST", "/Ticket/Create/Field/Availabilities/Get", request);
}

export function treeImpactOrderInfoGet(request: AssetManagementClientAssetManagementPackagesTreeImpactControllerTreeImpactOrderInfoGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesTreeImpactControllerTreeImpactOrderInfoGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesTreeImpactControllerTreeImpactOrderInfoGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesTreeImpactControllerTreeImpactOrderInfoGetControllerNestedResponse>("POST", "/TreeImpact/OrderInfo/Get", request);
}

export function treeImpactSummaryGet(request: AssetManagementClientAssetManagementPackagesTreeImpactControllerTreeImpactSummaryGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesTreeImpactControllerTreeImpactSummaryGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesTreeImpactControllerTreeImpactSummaryGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesTreeImpactControllerTreeImpactSummaryGetControllerNestedResponse>("POST", "/TreeImpact/Summary/Get", request);
}

export function userActiveListGet(request: AssetManagementClientCommonControllerControllerEmptyRequest): Promise<AssetManagementClientAssetManagementPackagesUserPackagesActiveUserControllerActiveUserListGetControllerNestedResponse>
{
  return callApi<AssetManagementClientCommonControllerControllerEmptyRequest, AssetManagementClientAssetManagementPackagesUserPackagesActiveUserControllerActiveUserListGetControllerNestedResponse>("POST", "/User/Active/List/Get", request);
}

export function userOrganizationList(request: AssetManagementClientCommonControllerControllerEmptyRequest): Promise<AssetManagementClientAssetManagementPackagesUserPackagesOrganizationStarControllerUserOrganizationListControllerNestedResponse>
{
  return callApi<AssetManagementClientCommonControllerControllerEmptyRequest, AssetManagementClientAssetManagementPackagesUserPackagesOrganizationStarControllerUserOrganizationListControllerNestedResponse>("POST", "/User/Organization/List", request);
}

export function userOrganizationStarAdd(request: AssetManagementClientAssetManagementPackagesUserPackagesOrganizationStarControllerUserOrganizationStarAddControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesUserPackagesOrganizationStarControllerUserOrganizationStarAddControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesUserPackagesOrganizationStarControllerUserOrganizationStarAddControllerNestedRequest, AssetManagementClientAssetManagementPackagesUserPackagesOrganizationStarControllerUserOrganizationStarAddControllerNestedResponse>("POST", "/User/OrganizationStar/Add", request);
}

export function userOrganizationStarGet(request: AssetManagementClientAssetManagementPackagesUserPackagesOrganizationStarControllerUserOrganizationStarGetControllerNestedRequest): Promise<AssetManagementClientAssetManagementPackagesUserPackagesOrganizationStarControllerUserOrganizationStarGetControllerNestedResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesUserPackagesOrganizationStarControllerUserOrganizationStarGetControllerNestedRequest, AssetManagementClientAssetManagementPackagesUserPackagesOrganizationStarControllerUserOrganizationStarGetControllerNestedResponse>("POST", "/User/OrganizationStar/Get", request);
}

export function userOrganizationStarList(request: AssetManagementClientCommonControllerControllerEmptyRequest): Promise<AssetManagementClientAssetManagementPackagesUserPackagesOrganizationStarControllerUserOrganizationStarListControllerNestedResponse>
{
  return callApi<AssetManagementClientCommonControllerControllerEmptyRequest, AssetManagementClientAssetManagementPackagesUserPackagesOrganizationStarControllerUserOrganizationStarListControllerNestedResponse>("POST", "/User/OrganizationStar/List", request);
}

export function userOrganizationStarRemove(request: AssetManagementClientAssetManagementPackagesUserPackagesOrganizationStarControllerUserOrganizationStarRemoveControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesUserPackagesOrganizationStarControllerUserOrganizationStarRemoveControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/User/OrganizationStar/Remove", request);
}

export function userPermissionUpdateV2(request: AssetManagementClientAssetManagementPackagesUserPackagesPermissionUpdateControllerUserPermissionUpdateV2ControllerNestedRequest): Promise<AssetManagementClientCommonControllerControllerEmptyResponse>
{
  return callApi<AssetManagementClientAssetManagementPackagesUserPackagesPermissionUpdateControllerUserPermissionUpdateV2ControllerNestedRequest, AssetManagementClientCommonControllerControllerEmptyResponse>("POST", "/User/Permission/Update/V2", request);
}


//#endregion Operations


