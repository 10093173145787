// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.
import { UserEmail as AssetManagementClientBeastClientBeastIdentityModelEmailUserEmail } from "@AssetManagementClient/BeastClient/Beast/Identity/Model/Email.gen"
import { UserPassword as AssetManagementClientBeastClientBeastIdentityModelPasswordUserPassword } from "@AssetManagementClient/BeastClient/Beast/Identity/Model/Password.gen"
import { Enum as AssetManagementClientBeastClientBeastIdentityModelUserPermissionUserPermissionFactoryNestedEnum } from "@AssetManagementClient/BeastClient/Beast/Identity/Model/UserPermission/UserPermissionFactoryNested.gen"
import { Enum as AssetManagementClientBeastClientBeastNotificationModelDefinitionNotificationFactoryNestedEnum } from "@AssetManagementClient/BeastClient/Beast/Notification/Model/Definition/NotificationFactoryNested.gen"
import { NotificationSetting as AssetManagementClientBeastClientBeastNotificationModelSettingsNotificationSetting } from "@AssetManagementClient/BeastClient/Beast/Notification/Model/Settings.gen"


export interface ActiveUser
{
  userId: string;
  firstName: string | undefined;
  lastName: string | undefined;
  email: string;
  isBouncing: boolean;
  status: UserStatus;
}


export interface User
{
  /**
   * Data id, if data bound
   */
  id: number;
  /**
   * An obfuscated id that represent the user
   */
  obfuscatedId: string;
  /**
   * The identifier of the associated ScalePad Hub user
   */
  scalePadHubUserId: string | undefined;
  /**
   * The version of the user used to modify the user from the external system
   */
  resourceVersion: number;
  /**
   * The date when this entry has been created
   */
  dateCreation: string;
  /**
   * Useful log information
   */
  log: UserLog | undefined;
  /**
   * Description of user status (e.g., invited, confirmed, disabled, deleted)
   */
  status: UserStatus;
  /**
   * Helper read-only flag to determine whether or not a user should receive email
   */
  canReceiveEmail: boolean;
  /**
   * Id bound to this user
   */
  accountId: number;
  /**
   * User email information
   */
  emailDetails: AssetManagementClientBeastClientBeastIdentityModelEmailUserEmail;
  /**
   * The phone number that can be used to contact the user
   */
  phoneNumber: string | undefined;
  /**
   * Permissions user has been given.
   * Permission here refers to UserPermission and the LegacyPermission column on the Role table
   * Default User start with AccountAdmin permission.
   * In terms of domain, these permissions are specifically for the LifecycleManager product (which means LifecycleManager roles)
   */
  permissions: Set<AssetManagementClientBeastClientBeastIdentityModelUserPermissionUserPermissionFactoryNestedEnum>;
  /**
   * The unique keys of each of the ScalePad account permissions granted to a user
   * In this case, permission refers to IPermission and the Permission table
   */
  scalePadAccountPermissionKeys: string[];
  /**
   * The labels of each of the ScalePad account Roles granted to a user
   */
  scalePadAccountRoleLabels: string[];
  /**
   * Notification settings for the user.
   * 
   * Gotchas:
   * If a notification enum is not specified in the dictionary, it is seen as UNSET by the user (may fallback to a default).
   * If the notification enum is specified and the NotificationSetting is empty, it is seen as SET by the user to be empty.
   */
  notificationSettings: {[key in AssetManagementClientBeastClientBeastNotificationModelDefinitionNotificationFactoryNestedEnum]: AssetManagementClientBeastClientBeastNotificationModelSettingsNotificationSetting};
  /**
   * User first name
   */
  firstName: string | undefined;
  /**
   * The user email
   * User last name
   */
  lastName: string | undefined;
  /**
   * FirstNameLastName
   */
  fullName: string | undefined;
  password: AssetManagementClientBeastClientBeastIdentityModelPasswordUserPassword;
  /**
   * Date changelog page was last visited
   */
  changelogLastVisited: string | undefined;
  /**
   * The last DateTime the user dismissed an announcement
   */
  changelogLastAnnouncementDismissed: string | undefined;
  /**
   * The publication date of the last seen announcement
   */
  changelogLastAnnouncementDismissedPublicationDate: string | undefined;
  /**
   * The user's choice about receiving marketing emails
   */
  optInMarketing: boolean | undefined;
  /**
   * The date and time the user was obfuscated due to a Right to be Forgotten request.
   */
  rtbfDeletedAt: string | undefined;
  /**
   * The time the user was last updated
   */
  updatedAt: string;
  shouldResetPassword: boolean | undefined;
}


/**
 * Useful log information
 */
export interface UserLog
{
  /**
   * Date corresponding to user's last logged action
   */
  lastActive: string;
  /**
   * Foreign key to frontend log ID
   */
  logId: number | undefined;
  /**
   * How many page the user hit
   */
  hitCount: number;
}


export enum UserStatus
{
  Confirmed = "Confirmed",
  Invited = "Invited",
  SignedUp = "SignedUp",
  Disabled = "Disabled",
  Deleted = "Deleted",
  Canceled = "Canceled",
}


