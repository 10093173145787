import React from 'react';
import useWarrantyCartContext from '~/wm/packages/warranty/packages/warranty-cart/context/hooks/useWarrantyCartContext';
import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

const WarrantyCartContinuousPolicyFooter: React.FunctionComponent = () => {
  const { continuousRenewalPolicyVisible } = useWarrantyCartContext();
  return continuousRenewalPolicyVisible ? (
    <span
      css={css`
        margin-top: 1rem;
        color: ${colorToCode('dark-000')};
      `}
    >
      * Warranty coverage start date has been backdated to provide continuous coverage.
    </span>
  ) : null;
};

export default WarrantyCartContinuousPolicyFooter;
