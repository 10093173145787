import Label from '~/neo-ui/packages/text/packages/label/Label';
import { css } from '@emotion/react';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';
import useDownloadLifecycleReportCustomizedPdf from '~/neo-ui/packages/table/packages/console/download/hooks/useDownloadLifecycleReportCustomizedPdf';
import { FunctionComponent, useEffect, useState } from 'react';
import Window from '~/neo-ui/packages/window/Window';
import InputTitle from '~/neo-ui/packages/input/packages/input-title/InputTitle';

import { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import Button from '~/neo-ui/packages/button/Button';

export type CustomHardwareReportDownloadWindowProps = {
  isWindowOpen: boolean;
  onWindowClose: () => void;
  frontendScope: FrontendScope;
};

const CustomHardwareReportDownloadWindow: FunctionComponent<CustomHardwareReportDownloadWindowProps> = ({
  isWindowOpen,
  onWindowClose,
  frontendScope,
}) => {
  const defaultPdfName = 'Custom Hardware Report';
  const { downloadLifecycleReportCustomized } = useDownloadLifecycleReportCustomizedPdf(frontendScope);
  const [pdfName, setPdfName] = useState<string>(defaultPdfName);

  useEffect(() => {
    setPdfName(defaultPdfName);
  }, [isWindowOpen]);

  return (
    <Window
      title={''}
      isOpen={isWindowOpen}
      onDismiss={() => {
        onWindowClose();
      }}
      footerProps={{
        rightControls: [
          {
            expanded: (
              <Button
                theme={'primary'}
                iconLeft={'Download'}
                onClick={() => {
                  downloadLifecycleReportCustomized(pdfName);
                  onWindowClose();
                }}
              >
                Download
              </Button>
            ),
          },
          {
            expanded: (
              <Button
                iconLeft={'Cancel'}
                onClick={() => {
                  onWindowClose();
                }}
              >
                Cancel
              </Button>
            ),
          },
        ],
      }}
    >
      <div
        css={css`
          display: flex;
          gap: 0.5rem;
          flex-direction: column;
        `}
      >
        <Header
          size={4}
          weight={'bold'}
        >
          {'Download Customized Hardware Report'}
        </Header>
        <Label muted={true}>{'The report will be downloaded with columns and filters you’ve selected '}</Label>
      </div>
      <div
        css={css`
          margin-top: 1.5rem;
          display: flex;
          gap: 0.5rem;
          background-color: ${colorToCode('light-200')};
          border-radius: 0.5rem;
          padding: 0.5rem;
          flex-direction: column;
        `}
      >
        <Header
          size={5}
          weight={'bold'}
        >
          {'Report Name'}
        </Header>
        <InputTitle
          size={'sm'}
          value={pdfName}
          onChange={name => setPdfName(name)}
          canClearOnClick={true}
        />
      </div>
    </Window>
  );
};

export default CustomHardwareReportDownloadWindow;
