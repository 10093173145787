import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import { css } from '@emotion/react';
import InputCheckbox from '~/neo-ui/packages/input/packages/input-checkbox/InputCheckbox';
import React from 'react';
import Label from '~/neo-ui/packages/text/packages/label/Label';

export type ConsoleSelectProps = {
  options: SelectOption[];
  selectedColumns: Set<string>;
  onColumnChange: (columns: Set<string>) => void;
};

const ColumnSelect = ({ options, selectedColumns, onColumnChange }: ConsoleSelectProps) => {
  const onClick = React.useCallback(
    (value: string) => {
      const newSelectedColumns = new Set(selectedColumns);
      if (newSelectedColumns.has(value)) {
        newSelectedColumns.delete(value);
      } else {
        newSelectedColumns.add(value);
      }
      onColumnChange(newSelectedColumns);
    },
    [onColumnChange, selectedColumns],
  );

  return (
    <div
      css={css`
        padding: 0.625rem;
      `}
    >
      <Label
        css={css`
          margin-bottom: 1.3rem;
        `}
        size={'lg'}
        bold={true}
        color={'secondary-400'}
      >
        Choose columns to show
      </Label>
      <div
        css={css`
          display: grid;
          grid-template-columns: 50% 50%;
          width: clamp(10rem, 18rem, 90vw);
          grid-gap: 1.3rem;
          margin-bottom: 1rem;
          margin-left: 0.6rem;
        `}
      >
        {options.map((option, index) => (
          <div
            key={option.value}
            css={css`
              grid-row: ${(index % Math.ceil(options.length / 2)) + 1};
              grid-column: ${index < options.length / 2 ? 1 : 2};
            `}
          >
            <InputCheckbox
              checked={selectedColumns.has(option.value)}
              description={option.label}
              onClick={() => onClick(option.value)}
              checkboxColor={'secondary-400'}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ColumnSelect;
