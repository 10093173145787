// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.


export enum Enum
{
  ScalePad = "ScalePad",
  BackupRadar = "BackupRadar",
  FutureShop = "FutureShop",
  C360 = "C360",
  ControlMap = "ControlMap",
}


