// __      ___   ___ _  _ ___ _  _  ___ _
// \ \    / /_\ | _ \ \| |_ _| \| |/ __| |
//  \ \/\/ / _ \|   / .` || || .` | (_ |_|
//   \_/\_/_/ \_\_|_\_|\_|___|_|\_|\___(_)
// 
//  This file was auto generated
//  Any changes made here *will* be overwritten, so don't do it
//  You. Have. Been. Warned.

export const featureFlags = [
  'sp-account-phase-1',
  'contract-management',
  'schedule-ticket-sync',
  'move-integration-centre',
  'lm-client-focus',
  'ticket-field-value-gathering',
  'enceladus',
  'lm-roadmaps-v2',
  'improve-profit-itad',
  'lm-demo',
  'lm-budget-forecast',
  'new-warranty-cart-page',
  'integration-enable-all-mapping-fields',
  'warranty-page-rebranding',
  'lm-roadmaps-pdf-overview',
  'lm-initiative-tickets',
  'identity-get-bypass-charge-bee',
  'lm-action-item-link-initiative',
  'lm-last-checkin',
  'lm-users',
  'lm-disposal-bulk-image-test-fix',
  'disposal-carrier-ups',
  'lm-assessment',

] as const;

type FeatureFlag = typeof featureFlags[number];

export default FeatureFlag;

