import { css } from '@emotion/react';
import { ComponentPropsWithoutRef, ReactElement, forwardRef, HTMLAttributeAnchorTarget } from 'react';
import { SpinnerIcon } from '../icons';
import theme from '../theme';

interface ButtonProps extends ComponentPropsWithoutRef<'button'> {
  iconLeft?: ReactElement;
  iconRight?: ReactElement;
  variant?: 'primary' | 'secondary' | 'success';
  size?: 'small' | 'large';
  loading?: boolean;
  anchor?: {
    href: string;
    target?: HTMLAttributeAnchorTarget; // See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#target
  };
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    type = 'button',
    iconLeft,
    iconRight,
    children,
    variant = 'primary',
    size = 'large',
    disabled,
    loading = false,
    anchor,
    onClick,
    ...rest
  } = props;

  const openLink = () => {
    if (anchor !== undefined) {
      window.open(anchor.href, anchor.target ?? '_self');
    }
  };

  return (
    <button
      ref={ref}
      css={[
        {
          all: 'unset',
          borderRadius: 4,
          ['&:hover:not([disabled])']: {
            cursor: 'pointer',
          },
          ['& .button__content-wrapper']: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            visibility: loading ? 'hidden' : 'visible',
          },
          position: 'relative',
        },
        size === 'small' &&
          css(theme.typography.buttonSmall, {
            paddingInline: '1rem',
            paddingBlock: '0.5rem',
            ['& .button__content-wrapper__main']: {
              paddingInline: '0.5rem',
            },
          }),
        size === 'large' &&
          css(theme.typography.buttonLarge, {
            paddingInline: '1.25rem',
            paddingBlock: '0.5rem',
            ['& .button__content-wrapper__main']: {
              paddingInline: '0.75rem',
            },
          }),
        variant === 'primary' &&
          css(
            {
              backgroundColor: theme.palette.primary1,
              color: theme.palette.white,
              ['&:hover:not([disabled])']: {
                backgroundColor: theme.palette.primary0,
              },
              ['&:focus,&:focus-visible']: {
                outline: `4px solid ${theme.palette.primary1_15}`,
              },
            },
            disabled && {
              backgroundColor: theme.palette.grey3,
              color: theme.palette.grey2,
              border: `1px solid ${theme.palette.grey3}`,
            },
          ),
        variant === 'secondary' &&
          css(
            {
              backgroundColor: theme.palette.white,
              color: theme.palette.primary1,
              border: `1px solid ${theme.palette.primary1}`,
              ['&:hover:not([disabled])']: {
                backgroundColor: theme.palette.primary2,
              },
              ['&:focus,&:focus-visible']: {
                outline: `4px solid ${theme.palette.primary1_15}`,
              },
            },
            disabled && {
              backgroundColor: theme.palette.white,
              color: theme.palette.grey2,
              border: `1px solid ${theme.palette.grey3}`,
            },
          ),
        variant === 'success' &&
          css(
            {
              backgroundColor: theme.palette.success2,
              color: theme.palette.success1,
              border: `1px solid ${theme.palette.success1}`,
              ['&:hover:not([disabled])']: {
                backgroundColor: theme.palette.success2,
              },
              ['&:focus,&:focus-visible']: {
                outline: `4px solid ${theme.palette.success2}`,
              },
            },
            disabled && {
              backgroundColor: theme.palette.white,
              color: theme.palette.grey2,
              border: `1px solid ${theme.palette.grey3}`,
            },
          ),
      ]}
      type={type}
      disabled={disabled}
      onClick={!!anchor ? openLink : onClick}
      {...rest}
    >
      <div className="button__content-wrapper">
        {iconLeft}
        <span className="button__content-wrapper__main">{children}</span>
        {iconRight}
      </div>

      {loading && (
        <div
          css={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        >
          <SpinnerIcon />
        </div>
      )}
    </button>
  );
});

export default Button;
