import * as React from 'react';
import DisposalUpgradeBanner from '~/wm/packages/disposal/wizard/packages/disposal-banner/packages/disposal-upgrade-banner/DisposalUpgradeBanner';
import DisposalPermissionBanner from '~/wm/packages/disposal/wizard/packages/disposal-banner/packages/disposal-permission-banner/DisposalPermissionBanner';
import useDisposalUrlContext from '~/wm/packages/disposal/context/hooks/useDisposalUrlContext';
import DisposalLocationRestrictionBanner from '~/wm/packages/disposal/wizard/packages/disposal-banner/packages/disposal-location-restriction-banner/DisposalLocationRestrictionBanner';
import { BillingInfo } from '@SubscriptionClient/Subscription/Packages/Billing/Model.gen';
import useWizardPageContext from '~/neo-ui/packages/wizard/packages/wizard-page/context/hooks/useWizardPageContext';
import { css } from '@emotion/react';
import DisposalExpandableBanner from '~/wm/packages/disposal/wizard/packages/disposal-banner/packages/disposal-expandable-banner/DisposalExpandableBanner';

export type DisposalBannerSection = {
  showLegacyBanner: boolean;
  showPermissionBanner: boolean;
  showLocationRestrictionBanner: boolean;
  billingInfo: BillingInfo;
  dropOffLocationsUrl: string;
  hasDisposalBulkLocationAccess: boolean;
};

const DisposalBannerSection: React.FunctionComponent<DisposalBannerSection> = ({
  showLegacyBanner,
  showPermissionBanner,
  showLocationRestrictionBanner,
  billingInfo,
  dropOffLocationsUrl,
  hasDisposalBulkLocationAccess,
}) => {
  const { growUrl, featureRequestUrl } = useDisposalUrlContext();
  const { currentStepIndex } = useWizardPageContext();

  let restrictionBanner = null;

  // Show the banner if the partner cannot checkout for free
  if (showLegacyBanner) {
    restrictionBanner = <DisposalUpgradeBanner growUrl={growUrl} />;
  }

  // Show the banner if the partner does not have permission to checkout
  if (showPermissionBanner) {
    restrictionBanner = <DisposalPermissionBanner />;
  }

  // Show the banner if the partner is restricted from checking out
  if (showLocationRestrictionBanner) {
    restrictionBanner = (
      <DisposalLocationRestrictionBanner
        billingInfo={billingInfo}
        featureRequestUrl={featureRequestUrl}
      />
    );
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 0.625rem;
      `}
    >
      {restrictionBanner}

      {currentStepIndex === 0 && (
        <DisposalExpandableBanner
          dropOffLocationsUrl={dropOffLocationsUrl}
          hasDisposalBulkLocationAccess={hasDisposalBulkLocationAccess}
        />
      )}
    </div>
  );
};

export default DisposalBannerSection;
