import React from 'react';
import Form from '~/neo-ui/packages/form/Form';
import integrationCredentialFormValidationSchema from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/credentials/packages/credential-display-modes/packages/credential-form/packages/validation/integrationCredentialFormValidationSchema';
import InputTypeMap from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/credentials/packages/credential-display-modes/packages/credential-form/packages/credential-inputs/InputTypeMap';
import { Enum as InputTypeEnum } from '@AssetManagementClient/BeastClient/SyncGod/Shared/ApiHandler/Credential/InputTypeNested.gen';
import { Request } from '@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Controller/IntegrationSetupUpsertControllerNested.gen';
import { IntegrationSetupSubmitFunction } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/hooks/useIntegrationSetupAndDefinitions';
import ErrorPayload from '~/wm/packages/api/packages/api-error/model/ErrorPayload';
import Box from '~/neo-ui/packages/box/Box';
import { Styleable } from '~/neo-ui/model/capacity';
import { CredentialFieldDisplaySettingsDto } from '@AssetManagementClient/AssetManagement/Packages/Integration/IntegrationPage/Dto.gen';
import AnimationHeight from '~/neo-ui/packages/animation/packages/animation-height/AnimationHeight';

export type SanitizedCredentialFieldDisplaySettingsDto = CredentialFieldDisplaySettingsDto & { isInputRequired: boolean };
type CredentialFormData = {
  [key: string]: string | boolean | undefined;
};

export type IntegrationCredentialFormProps = {
  credentialFields: { [key in string]: SanitizedCredentialFieldDisplaySettingsDto };
  integrationId: string;
  integrationSetupId?: string;

  /**
   * Function to send the form data (Make the outward API request)
   * @param formData
   */
  submitCredentialForm: IntegrationSetupSubmitFunction;
  /**
   * Optional function to execute when cancel button is pressed
   */
  onCancel?: () => Promise<void>;
  /**
   * Optional, validation error handler
   */
  onValidationError?: (error: ErrorPayload<CredentialFormData>) => void;
  isExpanded?: boolean;
  children?: React.ReactNode;
} & Styleable;

const IntegrationCredentialForm = ({
  submitCredentialForm,
  credentialFields,
  integrationId,
  integrationSetupId,
  onCancel,
  onValidationError,
  isExpanded,
  children,
}: IntegrationCredentialFormProps) => {
  const defaultFormData = React.useMemo(() => {
    const inputToDefaultData = (credentialFieldDto: SanitizedCredentialFieldDisplaySettingsDto) => {
      switch (credentialFieldDto.inputType.type) {
        case InputTypeEnum.Text:
          return credentialFieldDto.value ?? credentialFieldDto.defaultValue ?? '';
        case InputTypeEnum.InteractiveOAuth:
          return credentialFieldDto.value ?? credentialFieldDto.defaultValue ?? 'unauthorized';
        case InputTypeEnum.Select:
          return credentialFieldDto.value ?? credentialFieldDto.defaultValue ?? '';
        case InputTypeEnum.TextSecure:
          return '';
        case InputTypeEnum.Toggle:
          return credentialFieldDto.value === 'true' ?? credentialFieldDto.defaultValue === 'true' ?? false;
        default:
          return '';
      }
    };

    const defaultFormData = Object.keys(credentialFields)
      .filter(x => !credentialFields[x].displayOnly)
      .reduce((acc: CredentialFormData, credentialFieldKey: string) => {
        const currentField = credentialFields[credentialFieldKey];

        return { ...acc, [credentialFieldKey]: inputToDefaultData(currentField) };
      }, {} as CredentialFormData);
    return defaultFormData;
  }, [credentialFields]);

  const onSubmit = React.useCallback(
    async (formData: CredentialFormData) => {
      const payload = {
        integrationId,
        credentialValues: formData,
        integrationSetupId,
      } as Request;

      await submitCredentialForm(payload);
    },
    [integrationId, integrationSetupId, submitCredentialForm],
  );

  return Object.keys(credentialFields).length > 0 ? (
    <Box
      borderRadius={'radius300'}
      padding={'padding200'}
    >
      <Form
        defaultFormData={defaultFormData}
        onSubmit={onSubmit}
        validationSchema={integrationCredentialFormValidationSchema(credentialFields)}
        submitMethod={'manual'}
        submitActionDisableSticky={true}
        submitActionTheme={'primary'}
        submitActionLabel={'Connect now'}
        submitActionIcon={'GoRight'}
        submitButtonSize={'md'}
        submitActionIconPlacement={'right'}
        hideSubmitStatusIndicator={true}
        onCancel={onCancel}
        dispatchError={false}
        onValidationError={onValidationError}
        hideSubmissionButton={!isExpanded}
      >
        {children}
        <AnimationHeight isExpanded={isExpanded ?? false}>
          <InputTypeMap inputs={credentialFields} />
        </AnimationHeight>
      </Form>
    </Box>
  ) : (
    <></>
  );
};
export default IntegrationCredentialForm;
