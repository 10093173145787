import { InsightStateDtoNested } from '@AssetManagementClient/AssetManagement/Packages/Strategy/Packages/Insights/Model.gen';
import { Enum } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Insights/Model/Dashboard/PresentationalInsightStateNested.gen';
import { css } from '@emotion/react';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import Icon from '~/neo-ui/packages/icon/Icon';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import { prerequisiteToneToDisplayDefinition } from '~/wm/packages/strategy/packages/strategy-dashboard/packages/insight-dashboard/prerequisiteDisplay';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type StrategyInsightDashboardPrerequisiteSmallProps = {
  insightTitle: string;
  insightDescription?: string;
  prerequisiteState: InsightStateDtoNested.Prerequisite;
} & Styleable;

const StrategyInsightDashboardPrerequisiteSmall: React.FunctionComponent<StrategyInsightDashboardPrerequisiteSmallProps> = ({
  insightTitle,
  insightDescription,
  prerequisiteState,
}) => {
  const prerequisiteReason = prerequisiteState.type === Enum.Prerequisite ? prerequisiteState.reason : undefined;
  const prerequisiteColor: Color | undefined = prerequisiteReason
    ? prerequisiteToneToDisplayDefinition(prerequisiteReason.prerequisiteTone)
    : undefined;
  const mainColor = prerequisiteColor ? prerequisiteColor : (prerequisiteState?.foregroundColor as Color);

  const [isHovered, setIsHovered] = React.useState(false);

  const hasDescription = insightDescription !== undefined;

  const insight = (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding: 0.625rem 0.9375rem 0.625rem 0.9375rem;
        background-color: ${isHovered ? colorToCode(prerequisiteState.backgroundColorHover as Color) : colorToCode('light-100')};
      `}
      onClick={() => {
        window.location.href = prerequisiteState.reason.actionUrl;
      }}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        {/* Action text */}
        {isHovered && (
          <Label
            color="secondary-400"
            css={css`
              white-space: nowrap;
              position: absolute;
              padding: 0.938rem;
              left: 0;
            `}
          >
            {prerequisiteState.reason.actionDescription}
          </Label>
        )}
        <div
          css={css`
            ${isHovered && 'visibility: hidden'}
          `}
        >
          {/* Insight title */}
          <div
            css={css`
              display: flex;
              align-items: center;
              margin-bottom: ${hasDescription ? '0.5rem' : 0};
            `}
          >
            <Header
              size={5}
              color={mainColor}
              css={css`
                line-height: 1rem;
                color: ${colorToCode(mainColor)};
                padding-bottom: 0;
              `}
            >
              {insightTitle}
            </Header>
          </div>
          {/* Description */}
          <div
            css={css`
              font-size: 0.625rem;
              line-height: 0.875rem;
              color: ${colorToCode(mainColor)};
            `}
          >
            {insightDescription}
          </div>
        </div>
      </div>
      {/* Arrow */}
      <div
        css={css`
          display: flex;
          align-items: center;
          margin-left: 1.56rem;
          visibility: ${isHovered ? 'visible' : 'hidden'};
        `}
      >
        <Icon
          icon={'GoRight'}
          color="secondary-400"
          css={css`
            margin-left: 0.313rem;
          `}
        />
      </div>
    </div>
  );

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={'shadow-sm'}
      css={css`
        position: relative;
        overflow: hidden;
        border-radius: 0.625rem;
        ${isHovered &&
        `transition: all 200ms ease;
            &:hover {
              cursor: pointer;
              box-shadow: 0 0.0625rem 0.625rem -0.3125rem rgba(0, 0, 0, 0.3),
                0 0.4375rem 1.5625rem -0.625rem rgba(0, 0, 0, 0.15);
            }`}
      `}
    >
      {insight}
    </div>
  );
};

export default StrategyInsightDashboardPrerequisiteSmall;
