import * as React from 'react';
import Preformatted from '~/neo-ui/packages/text/packages/preformatted/Preformatted';
import ModalInternal from '~/neo-ui/packages/modal/ModalInternal';
import ModalConfirmationHeader from '~/neo-ui/packages/modal/packages/modal-confirmation/packages/modal-confirmation-header/ModalConfirmationHeader';
import { css } from '@emotion/react';
import Button from '~/neo-ui/packages/button/Button';
import { FeatureKey } from '~/wm/packages/feature/context/FeatureAccessInfoProvider';

export type ExpansionUnlockModalProps = {
  featureKey: FeatureKey;
  organizationId: string;
  organizationName: string;
  numberOfUnlocks: number;
  maxNumberOfUnlocks: number;
  upgradeUrl: string;
  isOpen: boolean;
  onDismiss: () => void;
  isUnlocking: boolean;
  onOrganizationUnlock: (featureKey: FeatureKey, organizationId: string) => void;
};

const FeatureAccessUnlockModal: React.FunctionComponent<ExpansionUnlockModalProps> = ({
  featureKey,
  organizationId,
  organizationName,
  numberOfUnlocks,
  maxNumberOfUnlocks,
  upgradeUrl,
  isOpen,
  onDismiss,
  isUnlocking,
  onOrganizationUnlock,
}) => (
  <ModalInternal
    header={
      <ModalConfirmationHeader
        icon={'Unlocked'}
        title={`Unlock access for this ${organizationName}?`}
        theme={'warning'}
      />
    }
    footer={
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <Button
          iconLeft={'Cancel'}
          onClick={onDismiss}
          disabled={isUnlocking}
        >
          Cancel
        </Button>
        <div
          css={css`
            margin-left: auto;
            display: flex;
            gap: 0.625rem;
          `}
        >
          <Button
            theme={'positive'}
            iconLeft={'Upgrade'}
            anchor={{
              href: upgradeUrl,
              openInNewTab: false,
            }}
            disabled={isUnlocking}
          >
            Unlock for all clients…
          </Button>
          <Button
            theme={'warning'}
            onClick={() => {
              onOrganizationUnlock(featureKey, organizationId);
            }}
            disabled={isUnlocking}
            loading={isUnlocking}
          >
            Unlock for this client
          </Button>
        </div>
      </div>
    }
    isOpen={isOpen}
    onDismiss={onDismiss}
  >
    <Preformatted
      text={`With your current plan, you can unlock access for a client. You have ${maxNumberOfUnlocks - numberOfUnlocks} unlock${
        maxNumberOfUnlocks - numberOfUnlocks === 1 ? '' : 's'
      }.`}
    />
  </ModalInternal>
);

export default FeatureAccessUnlockModal;
