import { createContext } from 'react';
import { FeatureAccessAvailabilities } from '~/wm/packages/feature/packages/feature-access-banner/hooks/useFeatureAccessInfoGet';

export type FeatureAccessInfoContextData = {
  featureAccessAvailabilities: FeatureAccessAvailabilities | undefined;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const FeatureAccessInfoContext = createContext<FeatureAccessInfoContextData | undefined>(undefined);

export default FeatureAccessInfoContext;
