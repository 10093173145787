import { useContext } from 'react';
import FeatureAccessInfoContext from '~/wm/packages/feature/context/FeatureAccessInfoContext';

const useFeatureAccessInfoContext = () => {
  const featureAccessInfoContext = useContext(FeatureAccessInfoContext);
  if (!featureAccessInfoContext) {
    throw new Error('Tried to use FeatureAccessInfoContext outside of an FeatureAccessInfoProvider');
  }

  return featureAccessInfoContext;
};

export default useFeatureAccessInfoContext;
