import React from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { featureAccessInfoGet } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { FeatureAccessDto } from '@AssetManagementClient/BeastClient/Beast/Feature/Packages/Access/Models/Dto.gen';

export type FeatureAccessAvailabilities = { [featureKey: string]: FeatureAccessDto };

export type UseFeatureAccessInfo = {
  featureAccessAvailabilities: FeatureAccessAvailabilities | undefined;
};

const useFeatureAccessInfoGet = (): UseFeatureAccessInfo => {
  const [featureAccessAvailabilities, setFeatureAccessAvailabilities] = React.useState<FeatureAccessAvailabilities | undefined>();
  const { callApi } = useApi();

  React.useEffect(() => {
    (async () => {
      const response = await callApi(() => featureAccessInfoGet({}));
      if (!response) {
        return;
      }

      const featureAccessAvailabilitiesDict = response.featureAccessAvailabilities.reduce((acc, featureAccess) => {
        acc[featureAccess.featureKey] = featureAccess;
        return acc;
      }, {} as FeatureAccessAvailabilities);

      setFeatureAccessAvailabilities(featureAccessAvailabilitiesDict);
    })();
  }, [callApi]);

  return {
    featureAccessAvailabilities,
  };
};

export default useFeatureAccessInfoGet;
