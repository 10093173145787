import React from 'react';
import { Filter } from '~/neo-ui/packages/table/packages/console/types';
import { RenderFilterNested } from '@AssetManagementClient/BeastClient/Search/Model/Query/Field/Filter/Render.gen';
import Popover from '~/neo-ui/packages/popover/Popover';
import RangeInput from '~/neo-ui/packages/input/packages/input-range/RangeInput';
import { RangeValueHeaderFormatterType } from '~/neo-ui/packages/filter/packages/filter-range/RangeValueHeaderFormatter';
import { css } from '@emotion/react';
import Testable from '~/neo-ui/packages/testable/Testable';
import { pascalCaseToSpinalCase } from '~/extensions/packages/casing/pascalSpinalConversion';

export type RangeFilterProps = {
  filter: Filter;
  render: RenderFilterNested.RangeValueInput;
  parameterValue: Map<string, string[]> | undefined;
  setValue: (key: string, values: Map<string, string[]>) => void;
  headerFormatter?: RangeValueHeaderFormatterType;
};

const RangeFilter: React.FunctionComponent<RangeFilterProps> = ({ filter, render, parameterValue, setValue, headerFormatter }) => {
  let rangeMinimum: number | undefined;
  let rangeMaximum: number | undefined;

  // Assign values from the given filter parameters
  if (typeof parameterValue !== 'undefined' && parameterValue.size > 0) {
    // Extract and validate the min and max values
    const rangeMinimumRaw = parameterValue.get(render.minOperatorTag) ?? undefined;
    const rangeMaximumRaw = parameterValue.get(render.maxOperatorTag) ?? undefined;
    if (rangeMinimumRaw !== undefined && rangeMinimumRaw.length > 0) {
      rangeMinimum = +parseFloat(rangeMinimumRaw[0])?.toFixed(render.settings.precision);
    }
    if (rangeMaximumRaw !== undefined && rangeMaximumRaw.length > 0) {
      rangeMaximum = +parseFloat(rangeMaximumRaw[0])?.toFixed(render.settings.precision);
    }
  }

  const minLimit = render.settings.minLimit;
  const maxLimit = render.settings.maxLimit;

  const formattedRangeInput = (
    <RangeInput
      label={headerFormatter === undefined ? '' : filter.label}
      minValue={rangeMinimum}
      maxValue={rangeMaximum}
      minLimit={minLimit}
      maxLimit={maxLimit}
      unit={render.unitLabel}
      onChange={(changedMinValue, changedMaxValue) => {
        const changedValues = new Map<string, string[]>();
        if (typeof changedMinValue !== 'undefined') {
          changedValues.set(render.minOperatorTag, [changedMinValue.toString()]);
        }
        if (typeof changedMaxValue !== 'undefined') {
          changedValues.set(render.maxOperatorTag, [changedMaxValue.toString()]);
        }
        setValue(filter.key, changedValues);
      }}
      shouldFocus={headerFormatter !== undefined}
    />
  );

  if (headerFormatter === undefined) {
    return formattedRangeInput;
  } else {
    const headerCallback = headerFormatter(rangeMinimum, rangeMaximum, render.unitLabel, filter.label);
    return (
      <div>
        <Testable testId={`asset-console-filter-${pascalCaseToSpinalCase(filter.key)}-popover`}>
          <Popover
            header={headerCallback}
            disabled={!filter.isEnabled}
            css={css`
              width: clamp(375px, 60vw, 425px);
              padding: 0.625rem;
            `}
          >
            {formattedRangeInput}
          </Popover>
        </Testable>
      </div>
    );
  }
};

export default RangeFilter;
