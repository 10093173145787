import { PatchDeviceDateEnum } from '@AssetManagementClient/BeastClient/SyncGod/Shared/ApiHandler/Request/Payload/Action/PatchDevice.gen';
import { DatesToPatch } from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/hooks/useSyncPreferencesForm';

export const arrayOfDatesToPatchToObject = (datesToPatch: PatchDeviceDateEnum[]) =>
  Object.values(PatchDeviceDateEnum).reduce((acc, dateToPatch) => {
    acc[dateToPatch] = datesToPatch.includes(dateToPatch);
    return acc;
  }, {} as DatesToPatch);

export const objectOfDatesToPatchToArray = (datesToPatch: DatesToPatch) =>
  Object.entries(datesToPatch)
    .filter(([, value]) => value)
    .map(([key]) => key as PatchDeviceDateEnum);

// Creates a dictionary of key to transformed result for each dictionary entry, where the dictionary value is transformed by the converter function
export const applyToRecordValues = <V1, V2>(
  record: Record<string, V1>,
  converter: ((v: V1, k: string) => V2) | ((v: V1) => V2),
): Record<string, V2> => {
  return Object.entries<V1>(record).reduce((acc, [k, v]) => ({ ...acc, [k]: converter(v, k) }), {} as Record<string, V2>);
};

export const applyToInnerRecordValues = <V1, V2>(
  record: Record<string, Record<string, V1>>,
  converter: (v: V1, k: string) => V2,
): Record<string, Record<string, V2>> => {
  return applyToRecordValues(record, (innerRecord: Record<string, V1>) => applyToRecordValues(innerRecord, converter));
};

export type IntegrationSetupStage = 'updateIntegration' | 'newIntegration';

export const calculateStage = (integrationSetupId: string | undefined): IntegrationSetupStage =>
  integrationSetupId ? 'updateIntegration' : 'newIntegration';
