import React, { useMemo } from 'react';
import useWarrantyCartContext from '~/wm/packages/warranty/packages/warranty-cart/context/hooks/useWarrantyCartContext';
import { css } from '@emotion/react';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

const WarrantyCartCurrencyFooter: React.FunctionComponent = () => {
  const { getCurrency } = useWarrantyCartContext();
  const footerText = useMemo(() => {
    const currency = getCurrency();
    return currency === 'USD'
      ? 'Amounts shown are in USD'
      : `All amounts shown are converted to ${currency} based on today\'s exchange rate. Final costs will be in USD.`;
  }, [getCurrency]);

  return (
    <div
      css={css`
        margin-top: 1rem;
        color: ${colorToCode('dark-000')};
      `}
    >
      {footerText}
    </div>
  );
};

export default WarrantyCartCurrencyFooter;
