import { css } from '@emotion/react';

/**
 * See figma here
 * https://www.figma.com/file/vt27GwxWLJPNj7oY5nvLDM/SP-Account?type=design&node-id=2439-6140&mode=dev
 */
export default {
  palette: {
    white: 'hsla(0, 0%, 100%, 1)',
    black: 'hsla(235, 8%, 20%, 1)',
    grey1: 'hsla(0, 0%, 40%, 1)',
    grey2: 'hsla(0, 0%, 60%, 1)',
    grey3: 'hsla(0, 0%, 87%, 1)',
    grey4: 'hsla(0, 0%, 98%, 1)',
    primary0: 'hsla(206, 100%, 32%, 1)',
    primary1: 'hsla(206, 95%, 41%, 1)',
    primary2: 'hsla(206, 100%, 95%, 1)',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    primary1_15: 'hsla(206, 95%, 41%, 0.15)',
    success1: 'hsla(111, 64%, 40%, 1)',
    success2: 'hsla(111, 57%, 91%, 1)',
    error1: 'hsla(350, 68%, 50%, 1)',
    error2: 'hsla(350, 100%, 96%, 1)',
    warning1: 'hsla(44, 100%, 46%, 1)',
    warning2: 'hsla(44, 100%, 91%, 1)',
  },
  shadows: {
    1: css`
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    `,
    2: css`
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
    `,
  },
  typography: {
    base: css({ fontFamily: 'Roboto', lineHeight: '1.5', color: 'hsla(0, 0%, 0%, 1)', fontWeight: '400' }),

    get body() {
      return css(this.base, { fontSize: '1rem' });
    },

    get small() {
      return css(this.base, { fontSize: '0.875rem' });
    },

    get h1() {
      return css(this.base, { fontSize: '2.25rem', lineHeight: '1.33' });
    },

    get h2() {
      return css(this.base, { fontSize: '1.5rem', lineHeight: '1.33' });
    },

    get h3() {
      return css(this.base, { fontSize: '1.125rem', lineHeight: '1.33' });
    },

    get big() {
      return css(this.base, { fontSize: '4rem', lineHeight: '1.15', fontWeight: '300' });
    },

    get buttonLarge() {
      return css(this.base, { fontSize: '1.125rem', fontWeight: 700 });
    },

    get buttonSmall() {
      return css(this.base, { fontSize: '0.875rem', fontWeight: 700 });
    },

    get annotation() {
      return css({ fontFamily: '"Courier"', lineHeight: '1.25' });
    },
  },
};
