import Box from '~/neo-ui/packages/box/Box';
import IntegrationSetupPageSubSection from '~/wm/packages/integration/packages/scalepad-account/packages/integration-setup-page/packages/page-layout/IntegrationSetupPageSubSection';
import Icon from '~/neo-ui/packages/icon/Icon';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Color from '~/neo-ui/packages/color/Color.gen';

export type SyncStatusTileProps = {
  icon: IconType;
  title: string;
  description: string | undefined;
  iconColor: Color | undefined;
  boxRef: React.RefObject<HTMLDivElement>;
};
export const SyncStatusTile: React.FunctionComponent<SyncStatusTileProps> = ({ icon, title, description, iconColor, boxRef }) => {
  return (
    <Box
      borderRadius={'radius300'}
      padding={'padding200'}
      ref={boxRef}
    >
      <IntegrationSetupPageSubSection
        title={title}
        iconDisplay={
          <Icon
            icon={icon}
            sizePx={28}
            color={iconColor ?? 'primary-400'}
          />
        }
        description={description}
      />
    </Box>
  );
};
