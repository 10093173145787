import * as React from 'react';
import Window from '~/neo-ui/packages/window/Window';
import Button from '~/neo-ui/packages/button/Button';
import { ContractCreateFormData } from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-create-button/packages/contract-create-form/ContractCreateForm';
import { css } from '@emotion/react';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import { useFormContext } from '~/neo-ui/packages/form/hooks/useFormContext';
import useContractListContext from '~/wm/packages/strategy/packages/contract/packages/contract-list/context/hooks/useContractListContext';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';
import ContractTemplateAvailabilitySelect from '~/wm/packages/strategy/packages/contract/packages/contract-template/packages/contract-template-availability-select/ContractTemplateAvailabilitySelect';
import ContractUpsertBillingSection from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-upsert-billing-section/ContractUpsertBillingSection';
import ContractUpsertRenewSection from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-upsert-renew-section/ContractUpsertRenewSection';

import ContractUpsertBasicInfoSection from '~/wm/packages/strategy/packages/contract/packages/contract-list/packages/contract-upsert-basic-info-section/ContractUpsertBasicInfoSection';

type ContractCreateWindowProps = {
  organizationId: string;
  isOpen: boolean;
  onDismiss: () => void;
};

const vendorFieldKey: FieldKeyExpression<ContractCreateFormData> = values => values.basic.vendor;
const accountFieldKey: FieldKeyExpression<ContractCreateFormData> = values => values.basic.account;
const locationFieldKey: FieldKeyExpression<ContractCreateFormData> = values => values.basic.location;
const descriptionFieldKey: FieldKeyExpression<ContractCreateFormData> = values => values.basic.description;
const thirdPartyFieldKey: FieldKeyExpression<ContractCreateFormData> = values => values.basic.isThirdParty;
const costFieldKey: FieldKeyExpression<ContractCreateFormData> = values => values.billing.costVariables.costSubunits;
const perSeatCostTypeFieldKey: FieldKeyExpression<ContractCreateFormData> = values => values.billing.costVariables.perSeatCostSubunits;
const numberOfSeatsFieldKey: FieldKeyExpression<ContractCreateFormData> = values => values.billing.costVariables.numberOfSeats;
const costVariablesFieldKey: FieldKeyExpression<ContractCreateFormData> = values => values.billing.costVariables;
const nextDueFieldKey: FieldKeyExpression<ContractCreateFormData> = values => values.billing.nextDue;
const endDateFieldKey: FieldKeyExpression<ContractCreateFormData> = values => values.billing.endDate;
const autoRenewFieldKey: FieldKeyExpression<ContractCreateFormData> = values => values.billing.isAutoRenew;
const noticeDaysFieldKey: FieldKeyExpression<ContractCreateFormData> = values => values.alert.noticeDays;

const statusFieldKey: FieldKeyExpression<ContractCreateFormData> = values => values.basic.status;
const impactFieldKey: FieldKeyExpression<ContractCreateFormData> = values => values.basic.impact;
const costTypeFieldKey: FieldKeyExpression<ContractCreateFormData> = values => values.billing.costType;
const cycleFieldKey: FieldKeyExpression<ContractCreateFormData> = values => values.billing.cycle;

const assetsFieldKey: FieldKeyExpression<ContractCreateFormData> = values => values.asset.assets;

const budgetPastEndDateFieldKey: FieldKeyExpression<ContractCreateFormData> = values => values.billing.budgetPastEndDate;

const ContractCreateWindow: React.FunctionComponent<ContractCreateWindowProps> = ({ isOpen, organizationId, onDismiss }) => {
  const { trackEvent: trackEventProcessor } = useEventTracking(TrackingServices.EventProcessor);

  const { contractAvailabilities, contractBillingCurrency } = useContractListContext();
  const { submitForm, isSubmitting } = useFormContext<ContractCreateFormData>();

  return (
    <Window
      titleIcon={'Add'}
      title={'New contract'}
      isOpen={isOpen}
      onDismiss={onDismiss}
      maxWidthRem={77.625}
      footerProps={{
        rightControls: [
          {
            expanded: (
              <Button
                theme={'primary'}
                iconRight={'Add'}
                loading={isSubmitting}
                onClick={() => {
                  submitForm();
                  trackEventProcessor('contract_create_save', {});
                }}
              >
                Create
              </Button>
            ),
          },
        ],
      }}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <ContractTemplateAvailabilitySelect />

        <hr
          css={css`
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
            width: 100%;
          `}
        />

        <ContractUpsertBasicInfoSection
          initialSelectedAssets={[]}
          organizationId={organizationId}
          contractAvailabilities={contractAvailabilities}
          isSyncedFromPsa={false}
          nameFieldKey={vendorFieldKey}
          accountFieldType={accountFieldKey}
          statusFieldKey={statusFieldKey}
          locationFieldKey={locationFieldKey}
          impactFieldKey={impactFieldKey}
          descriptionFieldKey={descriptionFieldKey}
          thirdPartyFieldKey={thirdPartyFieldKey}
          assetsFieldKey={assetsFieldKey}
        />

        <hr
          css={css`
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
            width: 100%;
          `}
        />
        <ContractUpsertBillingSection
          contractBillingCurrency={contractBillingCurrency}
          contractAvailabilities={contractAvailabilities}
          billingCycleFieldKey={cycleFieldKey}
          costTypeFieldKey={costTypeFieldKey}
          costVariablesFieldKey={costVariablesFieldKey}
          costFieldKey={costFieldKey}
          perSeatCostFieldKey={perSeatCostTypeFieldKey}
          numberOfSeatsFieldKey={numberOfSeatsFieldKey}
          isSyncedFromPsa={false}
        />

        <hr
          css={css`
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
            width: 100%;
          `}
        />

        <ContractUpsertRenewSection
          nextDueFieldKey={nextDueFieldKey}
          endDateFieldKey={endDateFieldKey}
          autoRenewFieldKey={autoRenewFieldKey}
          noticeDaysFieldKey={noticeDaysFieldKey}
          budgetPastEndDateFieldKey={budgetPastEndDateFieldKey}
        />
      </div>
    </Window>
  );
};

export default ContractCreateWindow;
