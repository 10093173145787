import * as React from 'react';
import { css } from '@emotion/react';
import FriendlyDateTimeLine from '~/neo-ui/packages/date/packages/friendly-date-time-line/FriendlyDateTimeLine';
import Icon from '~/neo-ui/packages/icon/Icon';
import { parseISO } from 'date-fns';
import { ActivitySearchResult } from '@ActivityClient/BeastClient/Activity/Search/Model/Query.gen';
import getActivityPriorityDefinition from '~/wm/packages/activity/definition/getActivityPriorityDefinition';
import { Styleable } from '~/neo-ui/model/capacity';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type ActivityLogEntryHeaderProps = {
  entry: ActivitySearchResult;
} & Styleable;

const ActivityLogEntryHeader: React.FunctionComponent<ActivityLogEntryHeaderProps> = ({ entry, className }) => {
  const priority = getActivityPriorityDefinition(entry.metaInfo.priority.priority);

  return (
    <div
      className={className}
      css={css`
        display: flex;
        justify-content: space-between;
        font-size: 0.75rem;
        align-items: center;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <Icon
          icon={priority.iconName}
          color={priority.color}
          sizePx={12}
        />
        <div
          css={css`
            margin-left: 0.3125rem;
          `}
        >
          <span
            css={css`
              color: ${colorToCode(priority.color)};
            `}
          >
            {entry.metaInfo.priority.label}
          </span>
          {entry.userInfo && (
            <span
              css={css`
                color: ${colorToCode('dark-900-32')};
              `}
            >
              <span
                css={css`
                  margin-left: 0.3125rem;
                  margin-right: 0.3125rem;
                `}
              >
                •
              </span>
              {entry.userInfo.name}
            </span>
          )}
        </div>
      </div>

      <span
        css={css`
          color: ${colorToCode('dark-900-32')};
          padding-right: 0.3125rem;
        `}
      >
        <FriendlyDateTimeLine date={parseISO(entry.postedAt)} />
      </span>
    </div>
  );
};

export default ActivityLogEntryHeader;
