import * as React from 'react';
import Window from '~/neo-ui/packages/window/Window';
import Button from '~/neo-ui/packages/button/Button';
import TableSelectable from '~/neo-ui/packages/table/packages/table-selectable/TableSelectable';
import usePeopleDashboardContext from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/context/hooks/usePeopleDashboardContext';
import { DataTableColumn } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import { ActiveUser } from '@SsoClient/BeastClient/Beast/Identity/Model/User.gen';
import useOrganizationAccountTeamMemberAdd from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/hooks/useOrganizationAccountTeamMemberAdd';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import InputTitle from '~/neo-ui/packages/input/packages/input-title/InputTitle';
import { css } from '@emotion/react';
import useActiveUsersContext from '~/wm/packages/account/packages/user/context/hooks/useActiveUsersContext';
import formatUserNameDisplay from '~/wm/packages/account/packages/user/packages/active-users-search/formatUserNameDisplay';

type AccountTeamMemberAddWindowProps = {
  isOpen: boolean;
  onDismiss: () => void;
};

const AccountTeamMemberAddWindow: React.FunctionComponent<AccountTeamMemberAddWindowProps> = ({ isOpen, onDismiss }) => {
  const { organizationId } = useOrganizationContext();
  const { users } = useActiveUsersContext();
  const { organizationAccountTeamMembers } = usePeopleDashboardContext();

  const { addOrganizationAccountTeamMember, isAddingOrganizationAccountTeamMember } = useOrganizationAccountTeamMemberAdd();

  const [selectedUserIds, setSelectedUserIds] = React.useState<Set<string>>(new Set());
  const [currentPage, setCurrentPage] = React.useState(0);

  const [searchTerm, setSearchTerm] = React.useState('');

  const filteredUsers = React.useMemo(() => {
    // Filter out already added users
    const filteredExistingUsers = users.filter(
      user => !organizationAccountTeamMembers.find(organizationAccountTeamMember => organizationAccountTeamMember.userId === user.userId),
    );

    if (searchTerm) {
      const searchTermLower = searchTerm.toLowerCase();
      return filteredExistingUsers.filter(
        user => formatUserNameDisplay(user).toLowerCase().includes(searchTermLower) || user.email.toLowerCase().includes(searchTermLower),
      );
    }

    return filteredExistingUsers;
  }, [organizationAccountTeamMembers, searchTerm, users]);

  const userColumns: DataTableColumn<ActiveUser>[] = React.useMemo(
    () => [
      {
        fieldKey: user => user.firstName,
        Header: 'Name',
        renderCell: user => formatUserNameDisplay(user),
      },
      {
        fieldKey: user => user.email,
        Header: 'Email',
        renderCell: user => user.email,
      },
    ],
    [],
  );

  const addAccountTeamMembers = () => {
    const userIds = Array.from(selectedUserIds);
    addOrganizationAccountTeamMember({
      organizationId,
      accountTeamMemberIds: userIds,
    });
    setSelectedUserIds(new Set());
    setSearchTerm('');
    onDismiss();
  };

  return (
    <Window
      maxWidthRem={39.5}
      titleIcon={'Add'}
      title={`Add Account Team Members`}
      isOpen={isOpen}
      onDismiss={onDismiss}
      footerProps={{
        leftControls: [
          ...(selectedUserIds.size > 0
            ? [
                {
                  expanded: (
                    <Button
                      iconLeft={'Cancel'}
                      onClick={() => {
                        setSelectedUserIds(new Set());
                      }}
                    >
                      {selectedUserIds.size} account team member
                      {selectedUserIds.size === 1 ? '' : 's'} selected
                    </Button>
                  ),
                },
              ]
            : []),
        ],
        rightControls: [
          {
            expanded: (
              <Button
                theme={'primary'}
                onClick={() => {
                  addAccountTeamMembers();
                }}
                loading={isAddingOrganizationAccountTeamMember}
                disabled={selectedUserIds.size < 1}
              >
                Done
              </Button>
            ),
          },
          {
            expanded: (
              <Button
                onClick={() => {
                  onDismiss();
                }}
                disabled={isAddingOrganizationAccountTeamMember}
              >
                Cancel
              </Button>
            ),
          },
        ],
      }}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
        `}
      >
        <InputTitle
          prependIcon={'Search'}
          value={searchTerm}
          onChange={term => {
            setCurrentPage(0);
            setSearchTerm(term);
          }}
          placeholder={'Search Name or Email'}
        />
        <TableSelectable
          data={filteredUsers}
          dataKey={'userId'}
          columns={userColumns}
          dataTableProps={{
            defaultPagination: { pageNumber: currentPage, perPageSize: 15 },
            onPageChange: pagination => setCurrentPage(pagination.pageNumber),
            enableClientPaginated: 'block',
          }}
          updateSelectedIds={setSelectedUserIds}
          selectedIds={selectedUserIds}
        />
      </div>
    </Window>
  );
};

export default AccountTeamMemberAddWindow;
