import { EmptyRequest } from '@ActivityClient/ApiServer/Packages/AspNetCore/Controller/Request.gen';
import { useState, useEffect } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import { SubscriptionPlanOfferDto } from '~/SubscriptionClient/Subscription/Packages/Offer/Dto/Model.gen';
import { subscriptionPricingOfferGetPlan } from '@SubscriptionClient/SubscriptionClientMsp.gen';

export const useSubscriptionPlanOffer = () => {
  const [subscriptionPlanOfferDto, setSubscriptionPlanOfferDto] = useState<SubscriptionPlanOfferDto | undefined>(undefined);

  const { callApi } = useApi();

  useEffect(() => {
    (async () => {
      const query: EmptyRequest = {};
      const response = await callApi(() => subscriptionPricingOfferGetPlan(query));
      if (!response) {
        return;
      }
      setSubscriptionPlanOfferDto(response.offerDto);
    })();
  }, [callApi]);

  return { subscriptionPlanOfferDto };
};
