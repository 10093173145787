import React from 'react';
import { css } from '@emotion/react';

export type WarrantyCartCostLoaderProps = {
  color: string;
};

const WarrantyCartCostLoader: React.FunctionComponent<WarrantyCartCostLoaderProps> = ({ color }) => {
  return (
    <div
      css={css`
        margin-bottom: 0.4rem;
        width: 1.25rem;
        aspect-ratio: 4;
        background: radial-gradient(circle closest-side, ${color} 90%, #0000) 0 / calc(100% / 3) 100% space;
        clip-path: inset(0 100% 0 0);
        animation: l1 1s steps(4) infinite;
        @keyframes l1 {
          to {
            clip-path: inset(0 -34% 0 0);
          }
        }
      `}
    />
  );
};

export default WarrantyCartCostLoader;
