import PopoverWithPopup from '~/neo-ui/packages/popover/PopoverWithPopup';
import Label from '~/neo-ui/packages/text/packages/label/Label';
import DownloadMenuSection from '~/neo-ui/packages/table/packages/console/download/DownloadMenuSection';
import Divider from '~/neo-ui/packages/divider/Divider';
import Icon from '~/neo-ui/packages/icon/Icon';
import SelectOptionGroup from '~/neo-ui/packages/select/model/SelectOptionGroup';
import { css } from '@emotion/react';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import useDownloadLifecycleReportPdf from '~/neo-ui/packages/table/packages/console/download/hooks/useDownloadLifecycleReportPdf';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';
import useLifecycleReportSettings from '~/neo-ui/packages/table/packages/console/download/hooks/useLifecycleReportSettings';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';
import { Fragment, FunctionComponent, useState } from 'react';

import CustomHardwareReportDownloadWindow from '~/neo-ui/packages/table/packages/console/download/packages/custom-hardware-report-download-window/CustomHardwareReportDownloadWindow';

export type LifecycleReportButtonProps = {
  organizationId: string;
  frontendScope: FrontendScope;
  canDownload?: boolean;
  lifecycleReportDownloadLocation: string;
  hasHardwareLifecycleReportAccess: boolean;
};

export type DownloadAction = 'download-lifecycle-report-customized-pdf' | 'download-lifecycle-report-pdf';

const LifecycleReportButton: FunctionComponent<LifecycleReportButtonProps> = ({
  organizationId,
  frontendScope,
  canDownload,
  lifecycleReportDownloadLocation,
  hasHardwareLifecycleReportAccess,
}) => {
  const { trackEvent: trackEventProcessor } = useEventTracking(TrackingServices.EventProcessor);

  const { lifecycleReportSettings } = useLifecycleReportSettings(organizationId);
  const { downloadLifecycleReport } = useDownloadLifecycleReportPdf();

  const [isPdfDownloadModalOpen, setPdfDownloadModalOpen] = useState<boolean>(false);

  if (typeof lifecycleReportSettings === 'undefined') {
    return null;
  }

  const optionSelectedCallback = (option: SelectOption<DownloadAction>, callback: () => void) => {
    switch (option.value) {
      case 'download-lifecycle-report-customized-pdf':
        setPdfDownloadModalOpen(true);
        break;
      case 'download-lifecycle-report-pdf':
        downloadLifecycleReport(
          organizationId,
          {
            showCpu: lifecycleReportSettings.showCpuColumn,
            showRam: lifecycleReportSettings.showRamColumn,
            showStorage: lifecycleReportSettings.showStorageColumn,
            showLastCheckInDate: lifecycleReportSettings.showLastCheckInDateColumn,
          },
          false,
        );

        trackEventProcessor('report_hw_downloaded', {
          obfuscatedClientId: organizationId,
          downloadSource: lifecycleReportDownloadLocation,
        });
        break;
    }
    callback();
  };

  const downloadCustomizedLifecycleReportOptions: SelectOptionGroup<DownloadAction>[] = [
    {
      options: [
        {
          value: 'download-lifecycle-report-customized-pdf',
          label: 'Download Report',
          labelColor: 'primary-500',
          prependIcon: 'DownloadDocument',
          iconSizePx: 22,
          disabled: false,
        },
      ],
    },
  ];

  const downloadLifecycleReportOptions: SelectOptionGroup<DownloadAction>[] = [
    {
      options: [
        {
          value: 'download-lifecycle-report-pdf',
          label: 'PDF File',
          labelColor: 'negative-700',
          prependIcon: 'PDF',
          iconSizePx: 22,
          disabled: false,
        },
      ],
    },
  ];

  return (
    <Fragment>
      <PopoverWithPopup
        header={
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 5px;
            `}
          >
            <span>Hardware Report</span>
            <Icon
              icon={'Download'}
              color={'dark-900'}
            />
          </div>
        }
        popoverAlign={'left-end'}
        css={css`
          width: clamp(12.5rem, 29.375rem, 90vw);
        `}
      >
        {(closeModal: () => void) => (
          <div
            css={css`
              padding: 0.625rem 0.75rem;
            `}
          >
            {canDownload && (
              <Fragment>
                <Label
                  className={'download-header'}
                  size={'xlg'}
                  color={'secondary-400'}
                  bold={true}
                  css={css`
                    padding-bottom: 0.3125rem;
                    display: flex;
                    align-items: flex-start;
                  `}
                >
                  Download
                </Label>
                <DownloadMenuSection
                  heading={'Customized Hardware Report'}
                  description={"With the columns and filters you've selected."}
                  assetDownloadOptions={downloadCustomizedLifecycleReportOptions}
                  onOptionSelected={option => {
                    optionSelectedCallback(option, closeModal);
                  }}
                />
                <Divider
                  css={css`
                    margin: 1rem 0;
                  `}
                />
              </Fragment>
            )}
            {hasHardwareLifecycleReportAccess && (
              <DownloadMenuSection
                heading={canDownload ? 'Hardware Lifecycle Report' : 'Example Hardware Lifecycle Report'}
                description={'With default columns and without filters.'}
                assetDownloadOptions={downloadLifecycleReportOptions}
                onOptionSelected={option => {
                  optionSelectedCallback(option, closeModal);
                }}
              />
            )}
          </div>
        )}
      </PopoverWithPopup>
      <CustomHardwareReportDownloadWindow
        isWindowOpen={isPdfDownloadModalOpen}
        onWindowClose={() => setPdfDownloadModalOpen(false)}
        frontendScope={frontendScope}
      />
    </Fragment>
  );
};

export default LifecycleReportButton;
