import { roadmapGet } from '@AssetManagementClient/AssetManagementClientMsp.gen';
import { BrandingMspDto } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Branding/Dto/Model.gen';
import { RoadmapInitiativeDto } from '@AssetManagementClient/BeastClient/Beast/Initiative/Dto/Model.gen';
import { produce } from 'immer';
import * as React from 'react';
import { FunctionComponent, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import useApi from '~/wm/packages/api/hook/useApi';
import InitiativeMutationContext from '~/wm/packages/strategy/packages/initiative/context/InitiativeMutationContext';
import RoadmapInitiativesContext from './RoadmapInitiativesContext';
import FiscalQuarter from '~/extensions/packages/date/packages/fiscal-quarter/FiscalQuarter';
import getPreviousFiscalQuarter from '~/extensions/packages/date/packages/fiscal-quarter/getPreviousFiscalQuarter';
import getNextFiscalQuarter from '~/extensions/packages/date/packages/fiscal-quarter/getNextFiscalQuarter';
import buildRoadmapInitiativesByFiscalQuarter from '~/wm/packages/strategy/packages/roadmap-page/builder/buildRoadmapInitiativesByFiscalQuarter';
import useRegionalSettingsInfoContext from '~/wm/packages/settings/packages/regional-settings/context/hooks/useRegionalSettingsInfoContext';
import usePolling from '~/extensions/packages/polling/hooks/usePolling';
import { useLocation, useNavigate } from 'react-router-dom';

export type RoadmapInitiativesProviderProps = {
  organizationId: string;
  organizationName: string;
  isPresenting: boolean;

  /**
   * Optional initiative id to focus page on a certain initiative
   * highlights and scrolls initiative into view
   */
  focusInitiativeId?: string;
};

const RoadmapInitiativesProvider: FunctionComponent<PropsWithChildren<RoadmapInitiativesProviderProps>> = ({
  organizationId,
  organizationName,
  isPresenting,
  focusInitiativeId,
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentFiscalQuarter } = useRegionalSettingsInfoContext();
  const isDefaultToShowUnscheduled = true;

  // Set fiscal quarter availabilities
  const fiscalQuarterAvailabilities = useMemo(() => {
    let quarters: FiscalQuarter[] = [currentFiscalQuarter];

    // One year of quarters before the current quarter
    for (let i = 0; i < 4; i++) {
      quarters.push(getPreviousFiscalQuarter(quarters[quarters.length - 1]));
    }

    quarters = quarters.reverse();

    // Five years of quarters after the current quarter
    for (let i = 0; i < 20; i++) {
      quarters.push(getNextFiscalQuarter(quarters[quarters.length - 1]));
    }

    return quarters;
  }, [currentFiscalQuarter]);

  const lastAvailableFiscalQuarter = fiscalQuarterAvailabilities[fiscalQuarterAvailabilities.length - 1];
  const getDisplayFiscalQuarters = useCallback(
    (firstFiscalQuarter: FiscalQuarter) =>
      [2, 3, 4].reduce(
        (acc, _) => {
          acc.push(getNextFiscalQuarter(acc[acc.length - 1]));
          return acc.filter(
            fiscalQuarter =>
              fiscalQuarter.year < lastAvailableFiscalQuarter.year ||
              (fiscalQuarter.year === lastAvailableFiscalQuarter.year && fiscalQuarter.quarter <= lastAvailableFiscalQuarter.quarter),
          );
        },
        [firstFiscalQuarter],
      ),
    [lastAvailableFiscalQuarter.quarter, lastAvailableFiscalQuarter.year],
  );

  const [draftInitiativeId, setDraftInitiativeId] = useState<string>();
  const [initiallyFocusedInitiativeId, setInitiallyFocusedInitiativeId] = useState(focusInitiativeId);
  const [defaultFiscalQuarter, setDefaultFiscalQuarter] = useState(currentFiscalQuarter);
  const [highlightedInitiativeIds, setHighlightedInitiativeIds] = useState(focusInitiativeId ? [focusInitiativeId] : []);
  const [branding, setBranding] = useState<BrandingMspDto>();
  const [initiativesByFiscalQuarter, setInitiativesByFiscalQuarter] = useState(new Map<string, RoadmapInitiativeDto[]>());
  const [displayFiscalQuarters, setDisplayFiscalQuarters] = useState<FiscalQuarter[]>(getDisplayFiscalQuarters(currentFiscalQuarter));
  const [roadmapsFiscalQuarterPageSize, setRoadmapsFiscalQuarterPageSize] = useState(isDefaultToShowUnscheduled ? 3 : 4);
  const [shouldPoll, setShouldPoll] = useState(false);

  const { callApi } = useApi();

  const reload = useCallback(
    async (isRequestActive?: () => boolean) => {
      const response = await callApi(() => roadmapGet({ organizationId }));
      if (!response) {
        return;
      }

      // if a function is provided to determine whether to propagate
      // a state update (to prevent race conditions), then it will
      // ensure not to update state. otherwise, each call updates state.
      if (!isRequestActive || isRequestActive()) {
        setBranding(response.branding);

        setInitiativesByFiscalQuarter(buildRoadmapInitiativesByFiscalQuarter(response.roadmapInitiatives));
        setShouldPoll(response.hasPendingTicketCreation);

        if (typeof initiallyFocusedInitiativeId !== 'undefined') {
          const focusInitiative = response.roadmapInitiatives.find(initiative => initiative.initiativeId === initiallyFocusedInitiativeId);

          if (typeof focusInitiative !== 'undefined' && typeof focusInitiative.budgetQuarter !== 'undefined') {
            const fiscalQuarter = {
              year: focusInitiative.budgetQuarter.year,
              quarter: focusInitiative.budgetQuarter.quarter,
            } as FiscalQuarter;

            if (fiscalQuarter) {
              setDisplayFiscalQuarters(getDisplayFiscalQuarters(fiscalQuarter));
              setDefaultFiscalQuarter(fiscalQuarter);
            }
          }

          navigate(location.pathname, { replace: true });
          setInitiallyFocusedInitiativeId(undefined);
        }
      }
    },
    [callApi, getDisplayFiscalQuarters, initiallyFocusedInitiativeId, location.pathname, navigate, organizationId],
  );

  // Load initiatives
  useEffect(() => {
    reload();
  }, [reload]);

  // Poll for updates
  usePolling(() => reload(), 3000, shouldPoll);

  const onInitiativeSave = useCallback(
    async (initiativeId: string) => {
      await reload();
      setHighlightedInitiativeIds(initiativeIds =>
        produce(initiativeIds, (draft: string[]) => {
          draft.push(initiativeId);
        }),
      );
    },
    [reload],
  );

  const onDisplayFiscalQuartersChange = React.useCallback(
    async (firstFiscalQuarter: FiscalQuarter) => {
      setDisplayFiscalQuarters(getDisplayFiscalQuarters(firstFiscalQuarter));
    },
    [getDisplayFiscalQuarters],
  );

  return (
    <InitiativeMutationContext.Provider
      value={{
        triggerInitiativeReload: isRequestActive => reload(isRequestActive),
      }}
    >
      <RoadmapInitiativesContext.Provider
        value={{
          defaultFiscalQuarter,
          focusInitiativeId,
          highlightedInitiativeIds,
          setHighlightedInitiativeIds,
          onInitiativeSave,
          branding,
          fiscalQuarterAvailabilities,
          displayFiscalQuarters,
          onDisplayFiscalQuartersChange,
          organizationId,
          organizationName,
          isPresenting,
          initiativesByFiscalQuarter,
          setInitiativesByFiscalQuarter,
          roadmapsFiscalQuarterPageSize,
          setRoadmapsFiscalQuarterPageSize,
          isDefaultToShowUnscheduled: true,
          draftInitiativeId,
          setDraftInitiativeId,
        }}
      >
        {children}
      </RoadmapInitiativesContext.Provider>
    </InitiativeMutationContext.Provider>
  );
};

export default RoadmapInitiativesProvider;
