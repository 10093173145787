import { InsightDashboardDto } from '@AssetManagementClient/AssetManagement/Packages/Strategy/Packages/Insights/Model.gen';
import { DetailLevel } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Category/Model/InsightCategoryNested.gen';
import { Enum } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Insights/Model/Dashboard/PresentationalInsightStateNested.gen';
import { css } from '@emotion/react';
import { FunctionComponent, ReactElement, useEffect } from 'react';
import { Orientation } from '~/legacy-ui/packages/list/List';
import { Styleable } from '~/neo-ui/model/capacity';
import getInsightConsoleUrl from '~/wm/packages/strategy/packages/insight-console/getInsightConsoleUrl';
import StrategyInsightContainer from '~/wm/packages/strategy/packages/strategy-dashboard/packages/insight-container/StrategyInsightContainer';
import StrategyInsightDashboardItem from '~/wm/packages/strategy/packages/strategy-dashboard/packages/insight-dashboard/insight-dashboard-item/large-tile/StrategyInsightDashboardItem';
import StrategyInsightDashboardItemSmall from '~/wm/packages/strategy/packages/strategy-dashboard/packages/insight-dashboard/insight-dashboard-item/small-tile/StrategyInsightDashboardItemSmall';
import getAssetConsoleUrl from '~/wm/packages/strategy/packages/insight-console/getAssetConsoleUrl';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';
import Box from '~/neo-ui/packages/box/Box';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import InsightCreateButton from '~/wm/packages/strategy/packages/insight/packages/insight-create-button/InsightCreateButton';
import { useStrategyDashboard } from '~/wm/packages/strategy/packages/strategy-dashboard/hooks/useStrategyDashboard';

export type StrategyInsightDashboardContainerProps = {
  insightUrl: string;
  scope: FrontendScope;
  presentationInsightDashboard: InsightDashboardDto | undefined;
  renderCompleteHandler: () => void;
  canFullyAccessInsights: boolean;
  hardwareConsoleUrl: string;
  peopleConsoleUrl: string;
  hasAccessToNewConsole: boolean;
  enabledLmClientFocus: boolean;
} & Styleable;

const StrategyInsightDashboardContainer: FunctionComponent<StrategyInsightDashboardContainerProps> = ({
  insightUrl,
  scope,
  presentationInsightDashboard,
  className,
  renderCompleteHandler,
  canFullyAccessInsights,
  hardwareConsoleUrl,
  peopleConsoleUrl,
  hasAccessToNewConsole,
  enabledLmClientFocus,
}) => {
  const { reload } = useStrategyDashboard(scope);
  // This is to notify the parent component that this component has been loaded so that other components can be shown to avoid popping of components.
  useEffect(() => {
    if (typeof presentationInsightDashboard !== 'undefined') {
      renderCompleteHandler();
    }
  });

  if (presentationInsightDashboard === undefined) {
    return <div />;
  }

  const renderedInsightContainers: ReactElement[] = [];

  presentationInsightDashboard.insightCategories.map(category => {
    let insightContainerElements: ReactElement[] = [];
    let orientation = Orientation.Vertical;
    switch (category.detail) {
      case DetailLevel.Expanded:
      case DetailLevel.Large:
        insightContainerElements = category.insights.map(i => (
          <StrategyInsightDashboardItem
            insightTitle={i.title}
            insightDescription={i.description}
            insightCount={i.value}
            key={i.insightId}
            insightUrl={
              hasAccessToNewConsole
                ? getAssetConsoleUrl(hardwareConsoleUrl, peopleConsoleUrl, scope, i)
                : getInsightConsoleUrl(scope, i.insightId, insightUrl)
            }
            insightState={i.state}
            insightRiskLevel={i.riskLevel}
            insightTrend={i.trendValue}
            insightForecast={i.forecastValue}
            canFullyAccessInsights={canFullyAccessInsights}
            frontendScope={scope}
          />
        ));
        orientation = Orientation.Horizontal;
        break;
      case DetailLevel.Medium:
      case DetailLevel.Small:
        insightContainerElements = category.insights.map(i => {
          const prerequisiteReason = i.state.type === Enum.Prerequisite ? i.state.reason : undefined;
          return (
            <StrategyInsightDashboardItemSmall
              insightTitle={i.title}
              insightDescription={category.detail === DetailLevel.Small ? undefined : prerequisiteReason?.title ?? i.description}
              insightCount={i.value}
              key={i.insightId}
              insightUrl={
                hasAccessToNewConsole
                  ? getAssetConsoleUrl(hardwareConsoleUrl, peopleConsoleUrl, scope, i)
                  : getInsightConsoleUrl(scope, i.insightId, insightUrl)
              }
              insightState={i.state}
              insightTrend={i.trendValue}
              insightForecast={i.forecastValue}
              canFullyAccessInsights={canFullyAccessInsights}
              frontendScope={scope}
            />
          );
        });
    }
    renderedInsightContainers.push(
      <StrategyInsightContainer
        title={category.label}
        logoSource={category.logo}
        orientation={orientation}
        elements={insightContainerElements}
        key={category.enum}
      />,
    );
  });

  return enabledLmClientFocus ? (
    <Box
      borderRadius={'radius400'}
      padding={'padding200'}
      css={css`
        padding: 1.5rem 1.25rem;
      `}
    >
      <div
        css={css`
          display: flex;
          gap: 1rem;
          align-items: center;
          margin-bottom: 1rem;
        `}
      >
        <Header
          size={3}
          weight={'medium'}
          css={css`
            flex-grow: 1;
          `}
        >
          Insights
        </Header>
        <InsightCreateButton
          disabled={false}
          refreshAction={reload}
          isButtonLink={true}
        />
      </div>
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          gap: 0.626rem;
        `}
        className={className}
      >
        {renderedInsightContainers}
      </div>
    </Box>
  ) : (
    <div
      css={css`
        display: flex;
        flex-wrap: wrap;
        gap: 0.625rem;
      `}
      className={className}
    >
      {renderedInsightContainers}
    </div>
  );
};

export default StrategyInsightDashboardContainer;
