import Window from '~/neo-ui/packages/window/Window';
import Button from '~/neo-ui/packages/button/Button';
import TableSelectable from '~/neo-ui/packages/table/packages/table-selectable/TableSelectable';
import usePeopleDashboardContext from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/context/hooks/usePeopleDashboardContext';
import { DataTableColumn } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import useOrganizationContext from '~/wm/packages/organization/context/hooks/useOrganizationContext';
import InputTitle from '~/neo-ui/packages/input/packages/input-title/InputTitle';
import { css } from '@emotion/react';
import useOrganizationKeyContactAdd from '~/wm/packages/strategy/packages/strategy-dashboard/packages/people-dashboard/hooks/useOrganizationKeyContactAdd';
import { OrganizationContactDto } from '@AssetManagementClient/BeastClient/Beast/Organization/Packages/Contact/Dto/Model.gen';
import { useMemo, useState } from 'react';

type OrganizationKeyContactAddWindowProps = {
  isOpen: boolean;
  onDismiss: () => void;
};

const OrganizationKeyContactAddWindow = ({ isOpen, onDismiss }: OrganizationKeyContactAddWindowProps) => {
  const { organizationId } = useOrganizationContext();
  const { organizationContacts, organizationKeyContacts } = usePeopleDashboardContext();

  const { addOrganizationKeyContact, isAddingOrganizationKeyContact } = useOrganizationKeyContactAdd();

  const [selectedContactIds, setSelectedContactIds] = useState<Set<string>>(new Set());
  const [currentPage, setCurrentPage] = useState(0);

  const [searchTerm, setSearchTerm] = useState('');

  const filteredOrganizationContacts = useMemo(() => {
    // Filter out already added contacts
    const filteredExistingContacts = organizationContacts.filter(
      contact => !organizationKeyContacts.find(keyContact => keyContact.organizationContactId === contact.organizationContactId),
    );

    if (searchTerm) {
      const searchTermLower = searchTerm.toLowerCase();
      return filteredExistingContacts.filter(
        user =>
          user.fullName.toLowerCase().includes(searchTermLower) ||
          user.title?.toLowerCase().includes(searchTermLower) ||
          user.email.toLowerCase().includes(searchTermLower),
      );
    }

    return filteredExistingContacts;
  }, [organizationContacts, searchTerm, organizationKeyContacts]);

  const contactColumns: DataTableColumn<OrganizationContactDto>[] = useMemo(
    () => [
      {
        fieldKey: contact => contact.fullName,
        Header: 'Name',
        renderCell: contact => contact.fullName,
      },
      {
        fieldKey: contact => contact.title,
        Header: 'Role',
        renderCell: contact => contact.title,
      },
      {
        fieldKey: contact => contact.email,
        Header: 'Email',
        renderCell: contact => contact.email,
      },
    ],
    [],
  );

  const addKeyContacts = () => {
    const userIds = Array.from(selectedContactIds);
    addOrganizationKeyContact({
      organizationId,
      organizationContactIds: userIds,
    });
    setSelectedContactIds(new Set());
    setSearchTerm('');
    onDismiss();
  };

  return (
    <Window
      maxWidthRem={39.5}
      titleIcon={'Add'}
      title={`Add Key Contacts`}
      isOpen={isOpen}
      onDismiss={onDismiss}
      footerProps={{
        leftControls: [
          ...(selectedContactIds.size > 0
            ? [
                {
                  expanded: (
                    <Button
                      iconLeft={'Cancel'}
                      onClick={() => {
                        setSelectedContactIds(new Set());
                      }}
                    >
                      {selectedContactIds.size} key contact
                      {selectedContactIds.size === 1 ? '' : 's'} selected
                    </Button>
                  ),
                },
              ]
            : []),
        ],
        rightControls: [
          {
            expanded: (
              <Button
                theme={'primary'}
                onClick={() => {
                  addKeyContacts();
                }}
                loading={isAddingOrganizationKeyContact}
              >
                Done
              </Button>
            ),
          },
          {
            expanded: (
              <Button
                onClick={() => {
                  onDismiss();
                }}
                disabled={isAddingOrganizationKeyContact}
              >
                Cancel
              </Button>
            ),
          },
        ],
      }}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
        `}
      >
        <InputTitle
          prependIcon={'Search'}
          value={searchTerm}
          onChange={term => {
            setCurrentPage(0);
            setSearchTerm(term);
          }}
          placeholder={'Search Name, Role, or Email'}
        />
        <TableSelectable
          data={filteredOrganizationContacts}
          dataKey={'organizationContactId'}
          columns={contactColumns}
          dataTableProps={{
            defaultPagination: { pageNumber: currentPage, perPageSize: 15 },
            onPageChange: pagination => setCurrentPage(pagination.pageNumber),
            enableClientPaginated: 'block',
          }}
          updateSelectedIds={setSelectedContactIds}
          selectedIds={selectedContactIds}
        />
      </div>
    </Window>
  );
};

export default OrganizationKeyContactAddWindow;
