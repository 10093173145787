import React, { useCallback } from 'react';
import useWarrantyCartContext from '~/wm/packages/warranty/packages/warranty-cart/context/hooks/useWarrantyCartContext';
import { Enum as WarrantyType } from '@WarrantyClient/BeastClient/Goods/WarrantyType/WarrantyTypeFactoryNested.gen';
import { css } from '@emotion/react';

const WarrantyCartSelectText: React.FunctionComponent = () => {
  const { warrantyType } = useWarrantyCartContext();
  const getText = useCallback(() => {
    switch (warrantyType) {
      case WarrantyType.InfrastructureProtection:
        return 'Select Infrastructure Protection';
      case WarrantyType.WorkstationAssurance:
        return 'Select Workstation Assurance';
      default:
        return 'Select warranties';
    }
  }, [warrantyType]);

  return (
    <div
      css={css`
        margin-top: 1.5rem;
      `}
    >
      <h2>{getText()}</h2>
    </div>
  );
};

export default WarrantyCartSelectText;
