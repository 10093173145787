import { ActivitySearchResult } from '@ActivityClient/BeastClient/Activity/Search/Model/Query.gen';
import { css } from '@emotion/react';
import { parseISO } from 'date-fns';
import * as React from 'react';
import { formatDate, formatDateFriendly, TimezoneFormat } from '~/extensions/packages/date/formatDate';
import Card from '~/legacy-ui/packages/card/Card';
import Button from '~/neo-ui/packages/button/Button';
import ButtonModifier from '~/neo-ui/packages/button/packages/button-modifier/ButtonModifier';
import Icon from '~/neo-ui/packages/icon/Icon';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import AnchorLocation from '~/neo-ui/packages/anchor/types/AnchorLocation';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type ActivitySummaryItemProps = {
  entry: ActivitySearchResult;
  onDismiss: () => void;
};

const ActivitySummaryItem: React.FunctionComponent<ActivitySummaryItemProps> = ({ entry, onDismiss }) => {
  const ctaLink: AnchorLocation = {
    href: entry.contentInfo.callToAction.url,
  };

  return (
    <Card
      shadow="sm"
      css={css`
        margin: 0 0 0.625rem 0;
        padding: 0.9375rem 0.625rem 0.625rem 0.625rem;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <ButtonModifier
          icon={'Cancel'}
          onClick={onDismiss}
        />

        <span
          css={css`
            color: ${colorToCode('dark-900-32')};
            padding-right: 0.3125rem;
            font-size: 0.75rem;
          `}
        >
          {formatDateFriendly(parseISO(entry.postedAt))}
          <span
            css={css`
              margin-left: 0.3125rem;
              margin-right: 0.3125rem;
            `}
          >
            •
          </span>
          {formatDate(parseISO(entry.postedAt), {
            format: 'hh:mm a',
            timezone: TimezoneFormat.Local,
          })}
        </span>
      </div>

      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          padding-left: 0.3125rem;
          margin-top: 0.625rem;

          @media (max-width: 650px) {
            flex-wrap: wrap;
            .ctaButton {
              margin-top: 0.625rem;
            }
          }
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-right: 0.625rem;
          `}
        >
          <div
            css={css`
              width: 1.25rem;
              height: 1.25rem;
              margin-right: 0.9375rem;
            `}
          >
            <Icon
              icon={entry.metaInfo.icon as IconType}
              color="dark-900"
              sizePx={20}
            />
          </div>

          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <span
              css={css`
                font-size: 0.875rem;
                font-weight: bold;
                line-height: 1;
                margin-bottom: 0.3125rem;
              `}
            >
              {entry.contentInfo.title}
            </span>
            <span
              css={css`
                font-size: 0.875rem;
                line-height: 1.3;
                color: ${colorToCode('dark-300')};
              `}
            >
              {entry.contentInfo.details}
            </span>
          </div>
        </div>

        <Button
          iconRight="GoRight"
          size="sm"
          anchor={ctaLink}
          className="ctaButton"
        >
          {entry.contentInfo.callToAction.label}
        </Button>
      </div>
    </Card>
  );
};

export default ActivitySummaryItem;
