import { createContext } from 'react';
import { Enum as UserPermissionEnum } from '@SsoClient/BeastClient/Beast/Identity/Model/UserPermission/UserPermissionFactoryNested.gen';
import { Enum as AccountAccessEnum } from '@SsoClient/BeastClient/Beast/Access/Model/Account/AccountAccessFactoryNested.gen';
import { AccountAccessFlags } from '~/wm/hooks/useAccount';

export type UserHasPermission = (permissionToCheck: UserPermissionEnum) => boolean;
export type UserContextData = {
  id: string;
  hubUserId: string;
  fullName: string;
  getUserAccess: (accessToCheck: AccountAccessEnum) => boolean;
  permissions: UserPermissionEnum[];
  getUserPermission: (permissionToCheck: UserPermissionEnum) => boolean;
  /**
   * Check if the user has a specific permission or is an AccountAdmin
   */
  hasPermission: UserHasPermission;
  status: string;
  email: string;
  canReceiveEmail: boolean;
};

export type UserAccess = { [key in AccountAccessFlags]: boolean };

/**
 * Duplicated in backend A90DF74F-D5BE-48C2-A16D-823469028FA8
 */
export type UserPermissions =
  | 'AccountAdmin'
  | 'ManageAppSettings'
  | 'ManageBilling'
  | 'PlaceOrders'
  | 'ManageIntegration'
  | 'ImpersonateUser'
  | 'ManageFeatures'
  | 'DevOps'
  | 'ManageProcurement'
  | 'ManageSubscription'
  | 'Finance'
  | 'GenerateSatisfactionCoupon'
  | 'GenerateIrIncentiveCoupon'
  | 'Success'
  | 'ManageSales'
  | 'PerformanceExecutive'
  | 'PerformanceDashboard'
  | 'MarketingOperations'
  | 'GenerateDisposalIncentiveCoupon'
  | 'ManagePricing'
  | 'ManageOrder'
  | 'ManageOption'
  | 'HandleSupport'
  | 'ManageDisposalOrder';

export type UserStatus = 'Confirmed' | 'Invited' | 'SignedUp' | 'Disabled' | 'Deleted' | 'Canceled';

// eslint-disable-next-line @typescript-eslint/naming-convention
const UserContext = createContext<UserContextData | undefined>(undefined);

export default UserContext;
