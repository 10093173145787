import * as React from 'react';
import { css } from '@emotion/react';
import { ActivitySearchResult } from '@ActivityClient/BeastClient/Activity/Search/Model/Query.gen';
import {
  Filter as TableFilter,
  FilterState,
  Metadata,
  PaginationState,
  TableState,
} from '~/neo-ui/packages/table/packages/filter-table/FilterTable';
import PaginationControl from '~/neo-ui/packages/table/packages/data-table/packages/pagination-control/PaginationControl';
import { PaginationMetadata, SortingOrder, SortState } from '~/neo-ui/packages/table/packages/data-table/DataTable';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import List from '~/legacy-ui/packages/list/List';
import ActivityLogEntry from '~/wm/packages/activity/packages/activity-log/packages/activity-log-entry/ActivityLogEntry';
import EmptyStateInfo from '~/neo-ui/packages/empty-state-info/EmptyStateInfo';
import { Filter } from '@ActivityClient/BeastClient/Beast/Activity/Module/Search/Controller/ActivitySearchNested.gen';
import { QueryPayloadDto } from '@ActivityClient/BeastClient/Primitives/Query/Request/Dto.gen';
import buildFrontendApiQueryKey from '~/neo-ui/packages/table/packages/async-filter-table/builder/buildFrontendApiQueryKey';
import { FieldKeyExpression } from '~/neo-ui/packages/table/packages/field-key/resolveFieldKey';
import RenderFilterFormatter from '~/neo-ui/packages/table/packages/render-filter/RenderFilterFormatter';
import SelectOption from '~/neo-ui/packages/select/model/SelectOption';
import { produce } from 'immer';
import handleFilterChange from '~/neo-ui/packages/table/packages/filter-table/state/handleFilterChange';
import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

export type ActivityLogLayoutProps = {
  useActivityLogCallback: (query: QueryPayloadDto) => {
    activityEntries: ActivitySearchResult[] | undefined;
    paginationMetadata: PaginationMetadata | 'unknown';
    availableFilters: Filter[];
  };
  isFilterable: boolean;
};

const convertFilterToTableFilter = (filter: Filter): TableFilter => ({
  label: filter.label,
  value: filter.key.value,
  options: filter.availableValues.map(value => {
    const selectOption: SelectOption = {
      label: value.label,
      value: value.key,
    };
    return selectOption;
  }),
  order: filter.order,
  render: filter.render,
  defaultOptions: filter.defaultOptions,
});

const ActivityLogLayout: React.FunctionComponent<ActivityLogLayoutProps> = ({ useActivityLogCallback }) => {
  const initialPaginationState: PaginationState = React.useRef({
    pageNumber: 0,
    perPageSize: 50,
  }).current;

  const initialSortState: SortState<ActivitySearchResult> = React.useRef({
    key: (row => row.postedAt) as FieldKeyExpression<ActivitySearchResult>,
    order: 'descending' as SortingOrder,
  }).current;

  const [paginationState, setPaginationState] = React.useState<PaginationState>(initialPaginationState);

  const [metadataState, setMetadataState] = React.useState<Metadata>({
    filterSet: {
      filters: [],
    },
    sortingSet: {
      sortableColumnKeys: [],
    },
    supportsGlobalSearch: false,
  });

  const [queryState, setQueryState] = React.useState<QueryPayloadDto>(
    buildFrontendApiQueryKey({
      pagination: paginationState,
      sort: initialSortState,
    }),
  );

  const [availableFiltersState, setAvailableFiltersState] = React.useState<Filter[]>([]);

  const { activityEntries, paginationMetadata, availableFilters } = useActivityLogCallback(queryState);

  const [displayState, setDisplayState] = React.useState<TableState<ActivitySearchResult>>({
    sort: initialSortState,
  });

  React.useEffect(() => {
    if (availableFiltersState && availableFiltersState.length > 0) {
      return;
    }
    const tableFilters = availableFiltersState.map(convertFilterToTableFilter);
    const newFilters: FilterState = {
      selectedFilters: new Map<string, Set<string>>(),
      availableFilters: new Map(tableFilters.map(filter => [filter.value, filter])),
      searchQuery: undefined,
    };
    setAvailableFiltersState(availableFilters);
    setMetadataState(prevState =>
      produce(prevState, draft => {
        draft.filterSet.filters = tableFilters;
      }),
    );
    setDisplayState(prevState =>
      produce(prevState, draft => {
        draft.filters = newFilters;
      }),
    );
  }, [availableFilters, availableFiltersState, paginationState]);

  const onPageChange = React.useCallback((pagination: PaginationState) => {
    setPaginationState(pagination);
    setDisplayState(prevState =>
      produce(prevState, draft => {
        draft.pagination = pagination;
      }),
    );
  }, []);

  React.useEffect(() => {
    setQueryState(
      buildFrontendApiQueryKey({
        filters: displayState.filters,
        pagination: displayState.pagination,
        sort: displayState.sort,
      }),
    );
  }, [displayState]);

  const renderFilter = React.useCallback(
    (filter: Filter) => {
      const tableFilter = convertFilterToTableFilter(filter);
      const onFilterChange = (newSelected: string[]) => {
        setDisplayState(handleFilterChange(newSelected, tableFilter, metadataState, displayState));
        setPaginationState(initialPaginationState);
      };
      return RenderFilterFormatter(tableFilter, displayState, onFilterChange);
    },
    [initialPaginationState, metadataState, displayState],
  );

  if (
    (typeof activityEntries === 'undefined' || activityEntries.length === 0) &&
    (typeof displayState.filters?.selectedFilters === 'undefined' || displayState.filters.selectedFilters.size === 0)
  ) {
    return (
      <EmptyStateInfo
        imageSource={'/i/graphic/account/activity/secondary.png'}
        imageWidth={'7rem'}
        title={'No activities in your account yet'}
        description={'You’ll see alerts, notifications, and other activities that happen in your account here.'}
      />
    );
  }

  return (
    <div>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <Header
          css={css`
            margin-bottom: 0.625rem;
          `}
          size={2}
        >
          Activities
        </Header>

        <PaginationControl
          defaultPagination={initialPaginationState}
          paginationMetadata={paginationMetadata}
          onPageChange={onPageChange}
        />
      </div>
      <div
        css={css`
          display: flex;
          margin-bottom: 1.25rem;
          justify-content: space-between;
        `}
      >
        <span
          css={css`
            color: ${colorToCode('dark-900-32')};
          `}
        >
          Find alerts, notifications, and other activities in this account.
        </span>
        <span
          css={css`
            margin-top: -0.5rem;
          `}
        >
          {Array.from(availableFilters ?? [])
            .sort((a, b) => a.order - b.order)
            .map(renderFilter)}
        </span>
      </div>
      {activityEntries && (
        <List
          items={activityEntries}
          renderItem={(logEntry: ActivitySearchResult, index: number) => (
            <ActivityLogEntry
              css={css`
                margin-top: 0.625rem;
              `}
              key={index}
              entry={logEntry}
              disableCallToAction={true}
            />
          )}
          EmptyListPlaceholder="You'll see the account activities here."
        />
      )}
    </div>
  );
};

export default ActivityLogLayout;
