import { InsightStateDto } from '@AssetManagementClient/AssetManagement/Packages/Strategy/Packages/Insights/Model.gen';
import { css } from '@emotion/react';
import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import Icon from '~/neo-ui/packages/icon/Icon';
import Header from '~/neo-ui/packages/text/packages/header/Header';
import StrategyInsightDashboardFooter from '~/wm/packages/strategy/packages/strategy-dashboard/packages/insight-dashboard/insight-dashboard-item/StrategyInsightDashboardFooter';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { Enum as AssetManagementClientScopingModelScopeNestedEnum } from '@AssetManagementClient/Scoping/Model/ScopeNested.gen';
import { TrackingServices, useEventTracking } from '~/extensions/packages/tracking/hooks/useEventTracking';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';

export type StrategyInsightDashboardInsightSmallProps = {
  insightTitle: string;
  insightDescription?: string;
  insightCount: number;
  insightUrl: string;
  insightState: InsightStateDto;
  insightTrend?: number;
  insightForecast?: number;
  canFullyAccessInsights: boolean;
  frontendScope: FrontendScope;
} & Styleable;

const StrategyInsightDashboardInsightSmall: React.FunctionComponent<StrategyInsightDashboardInsightSmallProps> = ({
  insightTitle,
  insightDescription,
  insightCount,
  insightUrl,
  insightState,
  insightTrend,
  insightForecast,
  canFullyAccessInsights,
  frontendScope,
}) => {
  const mainColor = insightState?.foregroundColor as Color;

  const [isHovered, setIsHovered] = React.useState(false);
  const showFooter = canFullyAccessInsights;
  const showHoveredDescription = showFooter;

  const hasDescription = insightDescription !== undefined;

  const { trackEvent: trackEventProcessor } = useEventTracking(TrackingServices.EventProcessor);

  const insight = (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding: 0.625rem 0.9375rem ${showFooter ? 2.3 : 0.625}rem 0.9375rem;
        background-color: ${isHovered ? colorToCode(insightState.backgroundColorHover as Color) : colorToCode('light-000')};
      `}
    >
      <div>
        {/* Insight title */}
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-bottom: ${hasDescription ? '0.5rem' : 0};
          `}
        >
          <Header
            size={5}
            color={mainColor}
            css={css`
              line-height: 1rem;
              color: ${isHovered ? colorToCode('light-000') : colorToCode(mainColor)};
              padding-bottom: 0;
            `}
          >
            {insightTitle}
          </Header>
        </div>
        {/* Description */}
        <div
          css={css`
            font-size: 0.625rem;
            line-height: 0.875rem;
            color: ${!isHovered
              ? colorToCode('dark-900-32')
              : showHoveredDescription
              ? colorToCode(insightState.backgroundColorHover as Color)
              : colorToCode('light-000-64')};
          `}
        >
          {insightDescription}
        </div>
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        {/* Insight count */}
        <Header
          size={insightDescription ? 3 : 5}
          color={mainColor}
          css={css`
            font-weight: ${insightDescription ? 500 : 'bold'};
            color: ${isHovered ? colorToCode(insightState.backgroundColorHover as Color) : colorToCode(mainColor)};
            margin-left: 1.25rem;
          `}
        >
          {insightCount.toLocaleString()}
        </Header>
      </div>
      {isHovered && (
        <div
          css={css`
            padding: 0.313rem;
            position: absolute;
            top: 0.4rem;
            right: 0.625rem;
            font-size: 0.75rem;
          `}
        >
          <Icon
            icon={'GoRight'}
            color={'light-000'}
          />
        </div>
      )}
    </div>
  );

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        const hasOrganizationScope = frontendScope.type === AssetManagementClientScopingModelScopeNestedEnum.Organization;
        const orgId = hasOrganizationScope ? frontendScope.organizationId : null;

        trackEventProcessor('insight_clicked', {
          obfuscatedClientId: orgId,
          insightPageUrl: insightUrl,
          insightName: insightTitle,
          scope: frontendScope.type,
        });
        window.location.href = insightUrl;
      }}
      className={'shadow-sm'}
      css={css`
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        ${isHovered &&
        `transition: all 200ms ease;
            &:hover {
              cursor: pointer;
              box-shadow: 0 0.0625rem 0.625rem -0.3125rem rgba(0, 0, 0, 0.3),
                0 0.4375rem 1.5625rem -0.625rem rgba(0, 0, 0, 0.15);
            }`}
      `}
    >
      {insight}
      {showFooter && (
        <StrategyInsightDashboardFooter
          insightTrend={insightTrend}
          insightForecast={insightForecast}
          insightState={insightState}
          isHovered={isHovered}
          showTrendLabel={hasDescription}
          css={css`
            position: absolute;
            bottom: 0;
          `}
        />
      )}
    </div>
  );
};

export default StrategyInsightDashboardInsightSmall;
