import * as React from 'react';
import { Styleable } from '~/neo-ui/model/capacity';
import StrategyInsightDashboardPrerequisite from '~/wm/packages/strategy/packages/strategy-dashboard/packages/insight-dashboard/insight-dashboard-item/large-tile/prerequisite/StrategyInsightDashboardPrerequisite';
import { Enum } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Insights/Model/Dashboard/PresentationalInsightStateNested.gen';
import { InsightStateDto } from '@AssetManagementClient/AssetManagement/Packages/Strategy/Packages/Insights/Model.gen';
import StrategyInsightDashboardInitializing from './initializing/StrategyInsightDashboardInitializing';
import StrategyInsightDashboardInsight from '~/wm/packages/strategy/packages/strategy-dashboard/packages/insight-dashboard/insight-dashboard-item/large-tile/insight/StrategyInsightDashboardInsight';
import { Enum as AssetManagementClientBeastClientBeastAssetManagementPackagesStrategyPackagesRulesLogicRiskLevelFactoryNestedEnum } from '@AssetManagementClient/BeastClient/Beast/AssetManagement/Packages/Strategy/Packages/Rules/Logic/RiskLevelFactoryNested.gen';
import { FrontendScope } from '@AssetManagementClient/Scoping/Model.gen';

export type StrategyDashboardItemProps = {
  insightTitle: string;
  insightDescription: string;
  insightCount: number;
  insightUrl: string;
  insightState: InsightStateDto;
  insightRiskLevel: AssetManagementClientBeastClientBeastAssetManagementPackagesStrategyPackagesRulesLogicRiskLevelFactoryNestedEnum;
  insightTrend?: number;
  insightForecast?: number;
  canFullyAccessInsights: boolean;
  frontendScope: FrontendScope;
} & Styleable;

const StrategyInsightDashboardItem: React.FunctionComponent<StrategyDashboardItemProps> = ({
  insightTitle,
  insightDescription,
  insightCount,
  insightUrl,
  insightState,
  insightRiskLevel,
  insightTrend,
  insightForecast,
  canFullyAccessInsights,
  frontendScope,
}) => {
  if (insightState.type === Enum.Initializing) {
    return (
      <StrategyInsightDashboardInitializing
        insightTitle={insightTitle}
        initializingState={insightState}
      />
    );
  }
  return insightState.type !== Enum.Prerequisite ? (
    <StrategyInsightDashboardInsight
      insightTitle={insightTitle}
      insightDescription={insightDescription}
      insightCount={insightCount}
      insightUrl={insightUrl}
      insightState={insightState}
      insightRiskLevel={insightRiskLevel}
      insightTrend={insightTrend}
      insightForecast={insightForecast}
      canFullyAccessInsights={canFullyAccessInsights}
      frontendScope={frontendScope}
    />
  ) : (
    <StrategyInsightDashboardPrerequisite
      insightTitle={insightTitle}
      insightDescription={insightDescription}
      prerequisiteState={insightState}
    />
  );
};
export default StrategyInsightDashboardItem;
